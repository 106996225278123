.filter-bar {
    padding: 145px 0 15px;

    @include media-breakpoint-down(md) {
        padding-top: 81px;
    }
    @include media-breakpoint-down(sm) {
        padding-bottom: 0;
    }

    .search-box {
        position: relative;
        margin-bottom: 28px;

        &:before {
            background: url('../img/search-black.svg') no-repeat top left;
            @include media-breakpoint-down(lg) {
                content: none;
            }
        }
        label {
            @include media-breakpoint-down(lg) {
                left: 0;
            }
        }
        input[data-empty="false"] + label,
        input:focus + label {
            @include media-breakpoint-down(lg) {
                left: 0;
            }
        }
        form {
            input[type="text"] {
                @include media-breakpoint-down(lg) {
                    padding-right: 60px;
                    padding-left: 0;
                }
            }
            input[type='submit'] {
                position: absolute;
                height: 61px;
                background: none;
                outline: none;
                -webkit-appearance: none;
                appearance: none;
                width: 25px;
                top: 0;
                left: 0;
                padding: 0;
                font-size: 0;
                border: 0;
                @include media-breakpoint-down(lg) {
                    background-image: url(../img/mobile-search-icon.svg);
                    background-repeat: no-repeat;
                    background-position: center;
                    right: 0;
                    top: auto;
                    bottom: 0;
                    height: 52px;
                    left: auto;
                    width: 46px;
                    background-color: $cyber-grape;
                }
            }
        }
    }

    .cmn-select {
        margin-bottom: 17px;
    }

    @include media-breakpoint-down(md) {
        .breadcrumbs {
            margin-bottom: 30px;
        }
    }
    #professionalToggle {
        color: #79757F;
        text-align: left;
        margin-bottom: 16px;
        background: url('../img/secondary-plus-gray.svg') no-repeat 0 0;
        background-size: 18px;
        padding: 0 0 0 29px;
        position: relative;
        margin: -8px 0 16px 0;
        &.slider-open {
            background:none;
            &:before{
                background: url('../img/minus-gray.svg') no-repeat 0 0;
                background-size: 18px;
                position: absolute;
                left: -6px;
                top: 0;
                content: "";
                height: 24px;
                width: 24px;
                transform: rotate(90deg);
            }
        }
        @include media-breakpoint-up(xl) {
            display: none;
        }
    }
    .toggleProfessional {
        display: none;
        @include media-breakpoint-up(xl) {
            display: flex !important;
        }
    }
}

.professional-list {
    padding: 15px 0 87px 0;

    @include media-breakpoint-down(md) {
        padding-bottom: 100px;
        padding-top: 0;
    }
    &.news-insight-details {
        padding-top: 50px;
    }

    .alphabetical-filters {
        padding-bottom: 15px;

        // @include media-breakpoint-down(md) {
        //     padding-bottom: 60px;
        // }

        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: flex-start;
            justify-content: flex-start;
            -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
        }

        a {
            text-decoration: none;
            color: rgba(54, 50, 59, 0.5);
            padding: 0 1px;
            margin: 0 7px 5px 7px;
            display: inline-block;
            position: relative;

            @include media-breakpoint-down(sm) {
                padding: 0 2px;
                margin: 0 3.5% 4% 3.5%;
                font-size: 16px;
                line-height: 26px;
                min-width: 18px;
                text-align: center;
            }

            &:after {
                content: "";
                height: 3px;
                width: 0%;
                background-color: transparent;
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: auto;
                z-index: 1;
                margin: auto;
                @include transition(0.35s);
            }

            &.active,
            &:hover {
                color: $chocolate-web;

                &:after {
                    background-color: $chocolate-web;
                    width: 100%;
                    @include transition(0.35s);
                }

                &:focus,
                &:active {
                    color: $chocolate-web;
                }
            }

            &:focus,
            &:active {
                color: $chocolate-web;

                &:hover {
                    color: $chocolate-web;
                }
            }
        }
    }

    .quote {
        min-height: 302px;
        position: relative;
        padding: 40px;
        text-align: center;
        height: 100%;

        @media screen and (min-width:1920px) {
            min-height: 410px;
            padding: 70px;
        }

        @include media-breakpoint-only(lg) {
            padding: 20px;
        }

        @include media-breakpoint-down(md) {
            padding: 40px 26px !important;
        }

        .divImg {
            background-color: $white-color;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            z-index: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            -webkit-filter: grayscale(100%);
            /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
        }

        &:after {
            content: "";
            background-color: rgba(255, 255, 255, 0.92%);
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            z-index: 0;
            bottom: 0;
            right: 0;
            height: 100%;
        }

        .img-fluid {
            z-index: 2;
            position: relative;
            max-width: 24px;
            margin: 0px auto 20px;
            display: block;
        }

        h5 {
            z-index: 2;
            position: relative;
            margin-bottom: 16px;
        }

        .text-14 {
            z-index: 2;
            position: relative;
            color: $gray;
            font-family: $font-bold;
        }
    }

    [class*="col-"] {
        margin-bottom: 60px;

        @include media-breakpoint-down(md) {
            margin-bottom: 35px;
        }

        @include media-breakpoint-up(lg) {
            &:nth-child(4n + 1) {
                animation-delay: 0.2s;
                animation-duration: 1s
            }

            &:nth-child(4n + 2) {
                animation-delay: 0.4s;
                animation-duration: 1s
            }

            &:nth-child(4n + 3) {
                animation-delay: 0.6s;
                animation-duration: 1s
            }

            &:nth-child(4n + 4) {
                animation-delay: 0.8s;
                animation-duration: 1s
            }
        }
        @include media-breakpoint-only(md) {
            &:nth-child(3n + 1) {
                animation-delay: 0.2s;
                animation-duration: 1s
            }

            &:nth-child(3n + 2) {
                animation-delay: 0.4s;
                animation-duration: 1s
            }

            &:nth-child(3n + 3) {
                animation-delay: 0.6s;
                animation-duration: 1s
            }
        }
        @include media-breakpoint-down(sm) {
            &:nth-child(2n + 1) {
                animation-delay: 0.2s;
                animation-duration: 1s
            }

            &:nth-child(2n + 2) {
                animation-delay: 0.4s;
                animation-duration: 1s
            }
        }


        .team {
            position: relative;
            overflow: hidden;

            .team-img {
                height: 222px;
                overflow: hidden;
                display: -webkit-flex;
                display: flex;
                -webkit-align-content: center;
                align-content: center;
                -webkit-justify-content: center;
                justify-content: center;
                margin-bottom: 16px;
                position: relative;

                @media screen and (min-width:1920px) {
                    height: 304px;
                }

                @include media-breakpoint-down(lg) {
                    height: 173px;
                }

                @include media-breakpoint-down(sm) {
                    height: 197px;
                }

                @include media-breakpoint-down(xs) {
                    height: 140px;
                }

                .divImg {
                    background-color: $white-color;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    z-index: 0;
                    bottom: 0;
                    @include transition(0.3s);
                    right: 0;
                    height: 100%;
                    background-size: contain;
                    transform: scale(1) translateZ(0);

                    @include media-breakpoint-down(sm) {
                        background-size: contain;
                        background-position: bottom center;
                    }
                }

                img {
                    max-height: 222px;
                    display: block;

                    @media screen and (min-width:1920px) {
                        max-height: 304px;
                    }

                    @include media-breakpoint-down(lg) {
                        max-height: 173px;
                    }

                    @include media-breakpoint-down(sm) {
                        max-height: 197px;
                    }

                    @include media-breakpoint-down(xs) {
                        max-height: 140px;
                    }
                }
            }

            .h5 {
                @extend .h5;
                color: $rich-black;
                text-decoration: none;
                margin-bottom: 4px;
                display: block;
                border: 0;
                padding: 0;
            }

            span.text-14 {
                color: #404040;
            }

            .contact-info {
                margin-top: 16px;
                position: relative;
                z-index: 2;

                @include media-breakpoint-down(sm) {
                    margin-top: 8px;
                }

                a {
                    @extend .text-14;
                    display: block;
                    text-decoration: none;
                    margin-bottom: 6px;
                    position: relative;
                    padding: 0 0 0 28px;
                    z-index: 2;
                    color: $rich-black;
                    border: 0;
                    word-break: break-word;
                    &:hover {
                        color: $cyber-grape;
                    }

                    @include media-breakpoint-down(sm) {
                        padding-left: 0;
                        margin-bottom: 4px;
                    }

                    span {
                        position: absolute;
                        left: 0;
                        width: 17px;
                        text-align: center;
                        top: 0;
                        &:after {
                            content: none;
                        }

                        @include media-breakpoint-down(sm) {
                            display: none;
                        }

                        img ,
                        svg{
                            max-height: 16px;
                            max-width: 13.5px;
                        }
                        svg {
                            * {
                                stroke: $rocket-metallic;
                            }
                        }
                    }

                    &:hover {
                        color: $cyber-grape;
                        svg {
                            * {
                                stroke: $cyber-grape;
                            }
                        }
                    }
                }
            }

            &:hover {
                .team-img {
                    img {
                        background-size: contain;
                        transform: scale(1.05) translateZ(0);
                        transform-origin: top center;

                        @include media-breakpoint-down(sm) {
                            background-size: contain;
                        }
                    }
                }

                .h5 {
                    color: $cyber-grape;
                }
                span.text-14 {
                    color: $cyber-grape;
                }
            }
            &.content-hover{
                .h5{
                    color: $rich-black;
                }
            }
        }
    }

    &.empty-list {
        padding: 15px 0 150px 0;

        @include media-breakpoint-down(md) {
            padding-bottom: 50px;
        }
        @include media-breakpoint-down(sm) {
            padding-top: 0;
        }

        .alphabetical-filters {
            padding-bottom: 15px;

            // @include media-breakpoint-down(md) {
            //     padding-bottom: 75px;
            // }
        }

        [class*="col-"] {
            margin: 0;

            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
            }
            @include media-breakpoint-up(lg) {
                &:nth-child(3n + 1) {
                    animation-delay: 0.2s;
                    animation-duration: 1s
                }
    
                &:nth-child(3n + 2) {
                    animation-delay: 0.4s;
                    animation-duration: 1s
                }
    
                &:nth-child(3n + 3) {
                    animation-delay: 0.6s;
                    animation-duration: 1s
                }
            }
            @include media-breakpoint-down(md) {
                    animation-delay: 0.2s;
                    animation-duration: 1s
            }
        }
        .filter-reset {
            margin-bottom: 60px;
        }
    }

    .loader-wrap {
        padding-top: 13px;

        @include media-breakpoint-down(sm) {
            padding: 0;
        }
    }
    &.diversity-contact {
        [class*="col-"] {
            .team {
                .contact-info {
                    a  {
                        span {
                            display: block;
                        }
                    }
                }
                
            }
        }
    }
    .filter-reset {
        text-align: center;
        @include media-breakpoint-down(sm) {
            text-align: left;
            padding-top: 0;
        }
    }
}

.professional-list {
    [class*="col-"] {
        .team {
            .team-img {
                background: url('../img/Willkie_atty_photos_bckgd_2_full.jpg') no-repeat 0 0;
                background-size: cover;
                .divImg {
                    background-color: transparent;
                }
            }
        }
    }
}
#alphaToggle {
    color: #79757F;
    text-align: left;
    margin-bottom: 16px;
    background: url(../img/secondary-plus-gray.svg) no-repeat 0 0;
    background-size: 18px;
    padding: 0 0 0 29px;
    margin-left: 15px;
    position: relative;
    &.slider-open {
        background:none;
        &:before{
            background: url(../img/minus-gray.svg) no-repeat 0 0;
            background-size: 18px;
            position: absolute;
            left: -6px;
            top: 0;
            content: "";
            height: 24px;
            width: 24px;
            transform: rotate(90deg);
        }
    }
    @include media-breakpoint-up(md) {
        display: none;
    }
}
.toggleforMobile {
    display: none;
    @include media-breakpoint-up(md) {
        display: flex !important;
        justify-content: space-between; 
    }
}

.filter-new-wrap {
    border-bottom: 1px solid #E0E0E0;
    margin-top: 50px;
    text-align: left;
    br {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
    @include media-breakpoint-down(sm) {
        margin-top: 0;
    }
    strong {
        font-size: 16px;
        margin-right: 10px;
        display: inline-block;
    }
    a {
        color: #86828C;
        font-size: 16px;
        text-decoration: none;
        position: relative;
        margin-right: 15px;
        &:after {
            content: "";
                height: 3px;
                width: 0%;
                background-color: transparent;
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: auto;
                z-index: 1;
                margin: auto;
                transition: all 0.35s ease;
        }
        &.active ,
        &:hover{
            &:after {
                background-color: #d6631e;
                width: 100%;
                transition: all 0.35s ease;
            }
        }
        &.clear-filter {
            text-decoration: underline;
            &:hover {
                color: $cyber-grape;
            }
            @include media-breakpoint-down(sm) {
                margin: 20px 0px 20px 0;
                display: inline-block;
            }
            &:after {
                content: none;
            }
        }
    }
}

.no-link-cards {
    &.professional-list {
        [class*="col-"] {
            .team {
                h5 {
                    color: $rich-black;
                    margin-bottom: 4px;
                    display: block;
                    padding: 0;
                }
                .team-img {
                    img {
                        background-size: contain;
                        transform: scale(1) translateZ(0);
                    }
                }
                span {
                    &.text-14 {
                        color: #404040;
                    }
                }
            }
        }
    }

}