.list-group.list-group-root {
  padding: 0;
  overflow: hidden;
}

.list-group.list-group-root .list-group {
  margin-bottom: 0;
}

.list-group.list-group-root:first-child {
  border-top: none;
}

.list-group.list-group-root .list-group-item {
  border-radius: 0;
  border-width: 1px 0 0 0;
}

.list-group.list-group-root > .list-group-item:first-child {
  border-top-width: 0;
}

.list-group.list-group-root > .list-group > .list-group-item {
  padding-left: 35px;
}

.list-group.list-group-root > .list-group > .list-group > .list-group-item {
  padding-left: 55px;
}

.list-group-item:hover, .list-group-item:focus {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}
