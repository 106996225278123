.career-banner{
    flex-wrap: wrap;
    padding-bottom: 0;
    padding-top: 90px;
    @include media-breakpoint-down(sm) {
        padding-top: 81px;
    }
    &.professional-slider {
        padding-top: 0;
        height: calc(100vh - 88px);
        @media screen and (min-device-width: 768px) and (max-device-width: 991.98px) and (orientation : landscape) {
            height: auto;
        }
        @include media-breakpoint-down(sm) {
            height: 100%;
        }
        &:before {
            content: none;
        }
        .page-title-position {
            position: absolute;
            left: 0;
            padding-top: 90px;
            right: 0;
            top: 0;
            @include media-breakpoint-down(sm) {
                padding-top: 81px;
            }
        }
        .row{
            @include media-breakpoint-down(md) {
                min-height: 0;
            }
        }
        .container {
            pointer-events: none;
        }
        .breadcrumbs {
            @include media-breakpoint-down(lg) {
                pointer-events: auto;
            }
        }
    }
    .row{
        min-height: calc(63vh - 100px);
    }
    .legal-professional-blocks {
        align-self: flex-end;
        .nav-blocks{
            padding: 29px 33px;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 220px;
            @include media-breakpoint-down(md) {
                min-height: 0;
                padding: 18px 15px;
            }
            h4{
                margin-bottom: 16px;
            }
            .block-data {
                br {
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                }
            }
        }
    }
    .legal-prof-slider {
        height: 100vh;
        position: relative;
        width: 100%;
        @include media-breakpoint-down(md) {
            min-height: 862px;
        }
        .legal-slides {
            position: relative;
            display: flex;
            height: calc(100vh - 88px);
            @include media-breakpoint-down(md) {
                min-height: 862px;
            }
            &:before {
                background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, #000000 100%);
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                left: 0;
                right: 0;
                bottom: 0;
                content: "";
                z-index: 1;
                opacity: 0.7;
            }
        }
        .slick-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: 40px;
            width: 40px;
            font-size: 0;
            border: 0;
            outline: 0;
            box-shadow: none;
            z-index: 9;
            &.slick-disabled {
                opacity: 0.5;
            }
            &.slick-next {
                right: 26px;
                background: url('../img/icon-carouselarrow-right-CarouselArrow.svg') no-repeat center center;
                @include media-breakpoint-down(lg) {
                    right: 0;
                }
                @include media-breakpoint-down(sm) {
                    margin: -37px auto 0 auto;
                }
            }
            &.slick-prev {
                left: 26px;
                transform: rotate(-180deg);
                margin-top: -20px;
                background: url('../img/icon-carouselarrow-right-CarouselArrow.svg') no-repeat center center;
                @include media-breakpoint-down(lg) {
                    left: 0;
                }
                @include media-breakpoint-down(sm) {
                    margin: -57px auto 0 auto;
                }
            }
        }
    }
}
//legal professional
.law-student-section {
    padding-bottom: 130px;
    @include media-breakpoint-down(md) {
        padding-bottom: 70px;
    }
    .tabbing-data {
        .filter-tabbing {
            .filter-data {
                // display: block;
            }
        }
    }
    .public-service {
        padding-top: 70px;
        padding-bottom: 70px;
        @include media-breakpoint-down(md) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        #publicSvg ,
        #publicSvg1{
            @include media-breakpoint-down(md) {
                width: 400px;
                margin: 0 auto 30px auto;
            }
            @include media-breakpoint-down(xs) {
                width: 100%;
            }
        }
    }
}
.law-student-section {
    .tabbing-data {
        .informative-data {
            &.professional-list {
                .quote {
                    padding-bottom: 50px;
                }
            }
            .diversity-stats {
                p {
                    color: $gray;
                }
                h4 {
                    margin: -4px 0 10px 0;
                }
                ul {
                    border-top: 1px solid rgba(54, 50, 59, 0.15);
                    li {
                        padding: 23px 0;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        border-bottom: 1px solid rgba(54, 50, 59, 0.15);
                        &:before {
                            content: none;
                        }
                        h3 {
                            margin: 0 12px 0 0;
                            color: #EA6307;
                            font-family: $font-medium;
                        }
                    }
                }
            }
        }
    }
}
.tabbing-data {
    .law-student {
        .informative-data {
            &.professional-list {
                .quote {
                    padding-bottom: 50px;
                }
            }
            .diversity-stats {
                p {
                    color: $gray;
                }
                h4 {
                    margin: -4px 0 10px 0;
                }
                ul {
                    border-top: 1px solid rgba(54, 50, 59, 0.15);
                    li {
                        padding: 23px 0;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        border-bottom: 1px solid rgba(54, 50, 59, 0.15);
                        &:before {
                            content: none;
                        }
                        h3 {
                            margin: 0 12px 0 0;
                            color: #EA6307;
                        }
                    }
                }
            }
        }
        .social-intro {
            .diversity-intro {
                h2 {
                    @include media-breakpoint-down(md) {
                        display: block;
                    }
                }
                #yearlyStats {
                    @include media-breakpoint-down(md) {
                        width: 500px;
                        margin: 15px auto 0 auto;
                    }
                    @include media-breakpoint-down(xs) {
                        width: 100%;
                    }
                }
            }
        }
    }
}
.social-share {
    padding-top: 70px;
    padding-bottom: 10px;
    .social-sharing {
        display: flex;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-down(sm) {
            display: block;
            text-align: center;
        }
        h3 {
            margin: 0 38px 0 0;
            font-family: $font-regular;
            @include media-breakpoint-down(sm) {
                margin: 0 0 30px 0;
            }
        }
        .icons {
            display: flex;
            @include media-breakpoint-down(sm) {
                justify-content: center;
            }
            a {
                height: 25px;
                width: 25px;
                border: 0;
                margin-right: 30px;
                @include media-breakpoint-down(sm) {
                    margin: 0 15px;
                }
                &.linkedin {
                    background: url(../img/linkedin-gray.svg) no-repeat center center;
                    background-size: 25px;
                }
                &.twitter {
                    background: url(../img/twitter-gray.svg) no-repeat center center;
                    background-size: 25px;
                }
                &.instagram {
                    background: url(../img/instagram-gray.svg) no-repeat center center;
                    background-size: 25px;
                }
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}
.law-student-discover {
    .country-ttile {
        border-top: 1px solid #DFDFDF;
        text-align: center;
        padding: 80px 0 0 0;
        @include media-breakpoint-down(md) {
            padding: 40px 0 0 0;
        }
        h3 {
            margin: 0 0 28px 0;
            padding: 0;
        }
    }
}
.compensation-benefits {
    &.tabbing-data {
        h3 {
            margin-bottom: 32px;
        }
        .filter-tab4,
        .filter-tab8 {
            margin-bottom: 30px;
            justify-content: flex-start;
            a {
                &:first-child {
                    padding-left: 0;
                }
            }
        }
        .filter-data-tab4 ,
        .filter-data-tab9{
            ul {
                margin: 0px 0px 35px;
                column-count: 1;
                padding: 0;
                li {
                    list-style: none;
                    margin: 0px 0px 19px;
                    padding: 0px 0px 0px 25px;
                    position: relative;
                    &:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 12px;
                        height: 6px;
                        width: 6px;
                        border-radius: 0;
                        background: $secondary-color;
                    }
                }
            }
        }
    }
}

.cmn-full-slider {
    padding: 0;
    position: relative;
    .legal-prof-slide {
        height: 100vh;
        position: relative;
        display: flex;
        align-items: flex-end;
        &:before {
            background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, #000000 100%);
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            left: 0;
            right: 0;
            bottom: 0;
            content: "";
            z-index: 1;
            opacity: 0.7;
        }
        .divImg {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            right: 0;
            transition: all 0.3s ease;
            top: 0;
            bottom: 0;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);
        outline: none;
        border: 0;
        box-shadow: none;
        font-size: 0;
        height: 40px;
        display: inline-block;
        padding: 0;
        width: 40px;
        &.slick-next {
            right: 26px;
            background: url(../img/icon-carouselarrow-right-CarouselArrow.svg) no-repeat center center;
        }
        &.slick-prev {
            left: 26px;
            background: url(../img/icon-carouselarrow-right-CarouselArrow.svg) no-repeat center center;
            transform: rotate(-180deg);
        }
        &.slick-disabled {
            opacity: 0.6;
            pointer-events: none;
        }
    }
    .hp-banner-arrow {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        margin: auto;
        top: auto;
        width: 23px;
        border: 0;
        z-index: 1;
    }
}
.discover-more-coutry {
    .comp-benefits-row,
    .informative-data {
        padding-top: 70px;
        padding-bottom: 40px;
        @include media-breakpoint-down(md) {
            padding-top: 40px;
            padding-bottom: 10px;
        }
    }
    .professional-list {
        padding-top: 70px;
        padding-bottom: 10px;
        @include media-breakpoint-down(md) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }
}
.law-student-discover {
    .tabbing-data {
        &.compensation-benefits {
            padding-top: 70px;
            @include media-breakpoint-down(md) {
                padding-top: 40px;
            }
        }
    }
}
.law-student-section {
    #tabs {
        li {
            a {
                @media(max-width: 460px) {
                    padding-left: 1px;
                    padding-right: 1px;
                }
            }
        }
    }
}
.center-layout-section {
    overflow: hidden;
    justify-content: center;
    > div {
        padding: 0 15px;
    }
    .col-left {
        width: 37%;
        text-align: right;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        h2 {
            padding: 12px 0;
        }
    }
    .col-strip {
        padding: 0;
        position: relative;
        width: 100px;
    }
    .col-right {
        width: 49%;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
        p {
            margin-bottom: 24px;
        }
        h6 {
            margin: 0;
        }
    }
    &.summer-title-padding {
        h2 {
            padding-top: 103px;
            padding-bottom: 103px;
            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }
    }
}