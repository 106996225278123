
.page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    flex-wrap: wrap;
    @include media-breakpoint-down(md) {
        margin-bottom: 0 !important;
    }
}
main {
    background: #ffffff;
}
footer {
    margin: auto 0 0 0;
    background: $light-black;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    z-index: 0;
    bottom: 0;
    float: left;
    width: 100%;
    .loc-title {
        h2 {
            color: #ffffff;
            margin-bottom: 24px;
            margin: 37px 0 12px 0;
            padding-left: 36px;
            background: url('../img/white-plus.png') no-repeat left center;
            cursor: pointer;
            background-size: 22px;
            font-size: 35px;
            line-height: 50px;
            font-family: $font-regular;
            letter-spacing: -0.13px;
            &.clicked {
                background: url('../img/white-minus.png') no-repeat left center;
                background-size: 22px;
            }
            @include media-breakpoint-down(md) {
                position: relative;
            }
            span {
                position: absolute;
                height: 7px;
                width: 11px;
                background: url(../img/toggle-arrow.svg) no-repeat 0 0;
                right: 0;
                top: 16px;
                -ms-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
                @include transition(0.3s);
                display: none;
            }
            &.clicked {
                span {
                    -ms-transform: rotate(0deg);
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                }
            }
        }
    }
    .address-list {
        padding-bottom: 39px;
        display: none;
        @media(min-width: 991.98px) and (max-width: 1025px) {
            padding-bottom: 0;
        }
        // @include media-breakpoint-up(md) {
        //     display: block !important;
        // }
        // @include media-breakpoint-down(sm) {
        //     display: none;
        // }
        .address-cols {
            @include media-breakpoint-down(sm) {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            > .address-blocks {
                margin-bottom: 32px;
                display: inline-block;
                width: 18%;
                vertical-align: top;
                margin-right: 24px;
                @include media-breakpoint-down(lg) {
                    margin-right: 1.95%;
                }
                @include media-breakpoint-down(md) {
                    width: 22.5%;
                }
                @include media-breakpoint-down(sm) {
                    width: 48%;
                    margin-right: 0;
                }
                &:nth-child(5n) {
                    margin-right: 0;
                    @include media-breakpoint-down(md) {
                        margin-right: 2.55%;
                    }
                    @include media-breakpoint-down(sm) {
                        margin-right: 0;
                    }
                }
                &:nth-child(4n) {
                    @include media-breakpoint-down(md) {
                        margin-right: 0;
                    }
                }
                @media(max-width: 1025px) {
                    margin-bottom: 0;
                }
                @include media-breakpoint-down(md) {
                    margin-bottom: 20px;
                }
                &:nth-last-child(2),
                &:last-child {
                    @media(max-width: 1025px) {
                        margin-bottom: 0;
                    }
                    @include media-breakpoint-down(sm) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    .address-blocks {
        span {
            @extend .text-16;
            font-family: $font-bold;
            color: $white-color;
            margin-bottom: 7px;
            display: block;
            a {
                color: $white-color;
                margin: 0;
            }
        }
        a {
            color: rgba(255, 255, 255, 0.6);
            text-decoration: none;
            display: inline-block;
            margin-bottom: 10px;
            &:hover {
                color: rgba(255, 255, 255, 0.92);
            }
        }
    }
    .full-width-border {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding: 30px 0;
        @include media-breakpoint-down(md) {
            padding: 38px 0 42px 0;
        }
        .ft-menu {
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                @include media-breakpoint-down(md) {
                    flex-wrap: wrap;
                }
                li {
                    margin: 0;
                    padding: 0;
                    line-height: normal;
                    @include media-breakpoint-down(md) {
                        width: 50%;
                        margin-bottom: 14px;
                        padding-right: 15px;
                    }
                    &:before {
                        content: none;
                    }
                    &:last-child {
                        a {
                            border-right: 0;
                        }
                    }
                    &:first-child {
                        a {
                            padding-left: 0;
                        }
                    }
                    a {
                        @extend .text-14;
                        font-family: $font-bold;
                        text-decoration: none;
                        color: $white-color;
                        letter-spacing: normal;
                        padding: 4px 15px;
                        border-right: 1px solid rgba(255, 255, 255, 0.1);
                        @include media-breakpoint-down(md) {
                            padding: 0;
                            border: 0;
                        }
                        &:hover {
                            color: rgba(255, 255, 255, 0.6);
                        }
                    }
                }
            }
        }
        .social-share {
            padding: 0;
            @include media-breakpoint-down(md) {
                margin-top: 20px;
            }
            ul {
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                @include media-breakpoint-down(md) {
                    justify-content: flex-start;
                }
                li {
                    margin: 0 0 0 32px;
                    padding: 0;
                    line-height: normal;
                    @include media-breakpoint-down(md) {
                        margin: 0 32px 0 0;
                    }
                    &:before {
                        content: none;
                    }
                    a {
                        text-decoration: none;
                        padding: 0;
                        line-height: 20px;
                        display: inline-block;
                        @include media-breakpoint-down(lg) {
                            width: 20px;
                            height: 20px;
                        }
                        img {
                            transition: all 0.35s ease;
                        }
                        &:hover {
                            img {
                                opacity: 0.6;
                            }
                        }
                    }
                }
            }
        }
    }
    .copyright {
        padding: 16px 0;
        span {
            font-size: 13px;
            line-height: 19px;
            color: rgba(255, 255, 255, 0.61);
        }
    }
    .ft-data {
        display: block;
        @include media-breakpoint-down(md) {
            display: flex;
            flex-wrap: wrap;
        }
        > div {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
        .social-share {
            float: right;
        }
    }
}

.home {
    footer {
        padding-top: 0;
        .loc-title {
            h2 {
                padding-left: 0;
                margin: 37px 0 12px 0;
                background: none;
                pointer-events: none;
            }
        }
        .address-list {
            display: block !important;
        }
    }
}