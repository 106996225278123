.professional-detail-head {
    padding: 110px 0 0 0;
    min-height: 385px;
    position: relative;

    @include media-breakpoint-down(md) {
        padding: 81px 0px 0px 0px;
    }

    @include media-breakpoint-down(sm) {
        padding-top: 0;
    }

    @include media-breakpoint-down(lg) {
        min-height: 320px;
    }

    @include media-breakpoint-down(sm) {
        display: block;

    }

    .breadcrumbs {
        @include media-breakpoint-down(sm) {
            position: absolute;
            top: 81px;
            left: 0px;
            z-index: 3;
        }
    }

    .col-md-4 {
        @include media-breakpoint-down(sm) {
            min-height: 350px;
            padding-top: 140px;
            margin-bottom: 20px;
        }

        @include media-breakpoint-down(xs) {
            min-height: 250px;
            padding-top: 114px;
        }

        .detail-img {
            // max-width: 263px;
            max-width: 335px;
            width: 100%;
            position: relative;
            z-index: 2;
            margin-left: 45px;

            @include media-breakpoint-down(md) {
                max-width: 197px;
                margin: 11px 35px 0 0px;
            }

            @include media-breakpoint-down(sm) {
                margin-top: 0;
            }

            @include media-breakpoint-down(xs) {
                margin: 0px 35px 0 25px;
                max-width: 148px;
            }
        }
    }

    .col-lg-7 {
        position: relative;
        z-index: 2;
    }

    h1 {
        position: relative;
        z-index: 2;

        @include media-breakpoint-down(sm) {
            order: 2;
            -webkit-align-self: center;
            align-self: center;
        }
    }

    .divImg {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        z-index: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        background-position: bottom center;

        @include media-breakpoint-down(sm) {
            height: 350px;
        }

        @include media-breakpoint-down(xs) {
            height: 250px;
        }
    }


    &:after {
        content: "";
        background: rgb(255, 255, 255);
        background: linear-gradient(270deg, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 0) 100%);
        opacity: 0.85;
        width: 66%;
        position: absolute;
        top: 0px;
        left: auto;
        width: 57%;
        z-index: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        z-index: 0;
    }
}

.professional-detail-desc {
    position: relative;
    z-index: 2;
    padding: 30px 0 20px;

    @include media-breakpoint-down(md) {
        padding: 0 0 20px 0;
    }

    .h3 {
        margin-bottom: 12px;
        @media(min-width: 1919.98px) {
            font-size: 36px;
            line-height: 46px;
        }
    }

    h5 {
        margin-bottom: 9px;
        position: relative;
        @media(min-width: 1919.98px) {
            font-size: 26px;
            line-height: 34px;
        }
        &:before {
            content: "";
            width: 71px;
            height: 1px;
            background: #D6631E;
            position: absolute;
            bottom: -8px;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 12px;
        }
        a {
            color: $rich-black;
            text-decoration: none;
            &:hover {
                color: $cyber-grape;
            }
        }
    }

    .detail-foot {
        padding-right: 70px;
        padding-top: 18px;

        @include media-breakpoint-down(md) {
            padding-right: 0;
        }

        @include media-breakpoint-down(xs) {
            padding-right: 0;
            -webkit-align-items: flex-end;
            align-items: flex-end;
            display: block !important;
        }

        .contact-info {
            width: 100%;
            max-width: 272px;
            display: inline-block;
            @include media-breakpoint-down(lg) {
                max-width: 240px;
            }
            @include media-breakpoint-down(xs) {
                max-width: 100%;
                margin-bottom: 15px;
            }

            a {
                display: block;
                text-decoration: none;
                margin-bottom: 8px;
                position: relative;
                padding-left: 28px;
                letter-spacing: 0.09px;
                z-index: 2;
                color: $light-black;
                @include media-breakpoint-down(sm){
                    font-size: 16px;
                    line-height: 26px;
                }
                &.other-language {
                    padding: 0;
                    color: $secondary-color;
                }

                span {
                    position: absolute;
                    left: 0;
                    top: 0;

                    img {
                        max-height: 16px;
                        max-width: 13.5px;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &:hover {
                    color: $cyber-grape;
                }
            }
        }

        .secondary-links {
            width: 220px;
            display: inline-block;
            @include media-breakpoint-down(lg) {
                max-width: 190px;
            }
            @include media-breakpoint-down(xs) {
                max-width: 100%;
            }
            span {
                display: block;
                color: $light-black;
                margin-bottom: 10px;
                letter-spacing: 0.1px;
                a {
                    text-decoration: none;
                    padding: 0;
                    margin: 0;
                }
            }
            a {
                display: block;
                color: $rich-black;
                padding-left: 28px;
                position: relative;
                margin-bottom: 8px;
                &:hover {
                    color: $cyber-grape;
                    span {
                        svg {
                            * {
                                stroke: #79757F;
                            }
                        }
                    }
                }
                span {
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
            .icons {
                display: flex;
                justify-content: flex-start;
                a {
                    padding: 0;
                    margin: 0 13px 0 0;
                    text-decoration: none;
                }
            }
        }
    }
    
}
.professional-detail-head {
    .professional-detail-desc {
        .detail-foot {
            a {
                span {
                    svg {
                        * {
                            stroke: $rocket-metallic;
                        }
                    }
                }
            }
        }
    }
}
.location-detail-head {
    .contact-info {
        a {
            span {
                svg {
                    * {
                        stroke: $rocket-metallic;
                    }
                }
            }
        }
    }
}

.professional-mobile-head {
    padding: 20px 0;
}

.professional-detail-content {
    padding: 0;
}

.professional-detail-arrow {
    text-decoration: none;
    position: fixed;
    right: 25px;
    bottom: 25px;
    z-index: 0;
    line-height: 0;
    width: 50px;
    height: auto;
    text-align: center;
    -webkit-animation-name: hvr-bob-float, hvr-bob;
    animation-name: hvr-bob-float, hvr-bob;
    -webkit-animation-duration: .3s, 1.2s;
    animation-duration: .3s, 1.5s;
    -webkit-animation-delay: 0s, .3s;
    animation-delay: 0s, .3s;
    -webkit-animation-timing-function: ease-out, ease-in-out;
    animation-timing-function: ease-out, ease-in-out;
    -webkit-animation-iteration-count: 1, infinite;
    animation-iteration-count: 1, infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-direction: normal, alternate;
    animation-direction: normal, alternate;
    &:hover {
        text-decoration: none;
    }
    span {
        font-size: 12px;
        color: $gray;
        line-height: 14px;
        display: block;
        margin: 0 0 5px 0;
        text-transform: uppercase;
    }
    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.cmn-overview-wrap {
    padding: 40px 0 60px 0;

    @include media-breakpoint-down(md) {
        padding: 20px 0 30px;
    }

    .overview-section {

        h3 {
            margin-bottom: 32px;

            @include media-breakpoint-down(md) {
                margin-bottom: 24px;
            }
        }

        .read-btn {
            padding-left: 32px;
            position: relative;
            line-height: 24px;
            display: inline-block;
            text-decoration: none;
            color: $rocket-metallic;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            font-family: $font-medium;

            &:after {
                content: "";
                background: url('../img/secondary-plus-gray.svg') no-repeat center center;
                background-size: 100%;
                height: 22px;
                width: 22px;
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
            }

            &.minus-icon {
                &:after {
                    background: url('../img/minus-gray.svg') no-repeat center center;
                    background-size: 100%;
                    transform: rotate(90deg);
                }
            }
        }
    }
}

.cmn-practice-areas {
    padding: 60px 0;

    @include media-breakpoint-down(md) {
        padding: 30px 0 24px 0;
    }

    h3 {
        margin-bottom: 32px;

        @include media-breakpoint-down(md) {
            margin-bottom: 24px;
        }

        @include media-breakpoint-down(sm) {
            margin-left: 12px;
        }
    }

    [class*="col-"] {
        @include media-breakpoint-down(sm) {
            margin-left: -12px;
        }

        .cmn-practice-inner-areas {

            a {
                color: $gray;
                text-decoration: none;
                margin: 0 40px 16px 0;
                position: relative;
                display: inline-block;

                @include media-breakpoint-down(sm) {
                    margin: 0 12px 16px 12px;
                }

                &:after {
                    content: "";
                    height: 30px;
                    width: 1px;
                    background: $gainsboro;
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: -20px;

                    @include media-breakpoint-down(sm) {
                        right: -12px;
                        top: -2px;
                    }
                }

                &:first-child {
                    margin-left: 0;

                    @include media-breakpoint-down(sm) {
                        margin-left: 12px;
                    }
                }

                &:last-child {
                    margin-right: 0;

                    &:after {
                        content: normal;
                    }
                }

                &:hover {
                    color: $cyber-grape;
                }
            }
        }
    }
}

.credentials-section {
    @include media-breakpoint-down(md) {
        padding: 30px 0;
    }

    h3 {
        margin-bottom: 32px;
    }

    h4 {
        margin-bottom: 0;
    }

    span {
        display: block;
    }

    hr {
        margin: 15px 0;
        border-color: #DFDFDF;

        @include media-breakpoint-down(md) {
            margin-bottom: 12px;
            margin-top: 4px;
        }
    }

    @include media-breakpoint-down(md) {
        [class*="col-"] {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.cmn-box {
    padding: 60px 0;

    @include media-breakpoint-down(md) {
        padding: 30px 0;
    }

    h3 {
        margin-bottom: 32px;

        @include media-breakpoint-down(md) {
            margin-bottom: 24px;
        }
    }

    [class*="col-"] {
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
            margin-bottom: 0;
        }

        .cmn-box-inner {
            border-left: 1px solid $rich-black;
            padding: 0 40px 8px 40px;
            position: relative;

            /** height: 100%; **/
            @include media-breakpoint-down(md) {
                padding: 0 0 24px 0;
                border: 0 !important;
            }
            h5{
                margin-bottom: 16px;
                @include transition(0.3s);
            }
            h6 {
                margin-bottom: 16px;

                @include media-breakpoint-down(md) {
                    border-bottom: 1px solid $rich-black;
                    padding-bottom: 12px;
                    margin-bottom: 11px;
                }
            }

            h5 {
                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                }

                a {
                    color: $rich-black;
                    text-decoration: none;
                    &:focus,
                    &:active {
                        //color: $chocolate-web;

                        &:hover {
                            //color: $chocolate-web;
                        }
                    }
                }
            }

            &:hover {
                border-left: 1px solid $cyber-grape;
                h6 {
                    color: $cyber-grape;
                    @include media-breakpoint-down(md) {
                        border-bottom: 1px solid $cyber-grape;
                    }
                }
                h5 {

                    a {
                        color: $cyber-grape;
                    }
                }
                svg {
                    stroke: $cyber-grape;
                    * {
                        stroke: $cyber-grape;
                    }
                }
            }
            &:focus,
            &:active{
                border-left: 1px solid $chocolate-web;
                h6 {
                    color: $chocolate-web;
                    @include media-breakpoint-down(md) {
                        border-color: $chocolate-web;
                    }
                }
                h5 {
                    a {
                        color: $chocolate-web;
                        &:hover {
                            color: $chocolate-web;
                        }
                    }
                }
                svg {
                    stroke: $chocolate-web;
                    * {
                        stroke: $chocolate-web;
                    }
                }
            }
            &.awards-no-link {
                svg {
                    display: none;
                    stroke: $rich-black;
                    * {
                        stroke: $rich-black;
                    }
                }
                &:hover ,
                &:focus,
                &:active{
                    border-left: 1px solid $rich-black;
                    h6 ,
                    h5{
                        color: $rich-black;
                    }
                    svg {
                        stroke: $rich-black;
                        * {
                            stroke: $rich-black;
                        }
                    }
                }
            }
        }
    }

    svg {
        stroke: $cyber-grape;
        z-index: 1;
        height: 26px !important;
        width: 26px !important;

        * {
            stroke: $cyber-grape;
        }
    }
}

.cmn-accordion {
    padding: 60px 0;

    @include media-breakpoint-down(md) {
        padding: 30px 0;
    }

    h3 {
        margin-bottom: 32px;

        @include media-breakpoint-down(md) {
            margin-bottom: 23px;
        }
    }

    .accordion {
        .accord-block {
            border-top: 1px solid $gainsboro;

            .accordion-heading-div {
                h5 {
                    margin: 0;

                    a {
                        text-decoration: none;
                        color: $rich-black;
                        padding: 38px 20px 38px 44px;
                        display: block;
                        position: relative;

                        @include media-breakpoint-down(md) {
                            padding-top: 28px;
                            padding-bottom: 28px;
                        }

                        @include media-breakpoint-down(sm) {
                            padding-left: 40px;
                            padding-right: 0;
                        }

                        &:before {
                            height: 18px;
                            width: 18px;
                            position: absolute;
                            top: 43px;
                            transition: all .5s;
                            content: "";
                            bottom: auto;
                            left: 0;
                            right: auto;
                            margin: auto;
                            background: url(../img/accordion-icon.svg) no-repeat center center;

                            @include media-breakpoint-down(md) {
                                top: 33px;
                            }

                            @include media-breakpoint-down(sm) {
                                top: 31px;
                            }
                        }
                    }
                }

                &[aria-expanded=true] {
                    h5 {
                        a {
                            color: $cyber-grape;

                            &:before {
                                transform: rotate(225deg);
                                background: url(../img/accordion-icon-purple.svg) no-repeat center center;
                            }
                        }
                    }
                }
                &:hover {
                    h5 {
                        a  {
                            color: $cyber-grape;
                            &:before {
                                background: url(../img/accordion-icon-purple.svg) no-repeat center center;
                            }
                        }
                    }
                }
            }

            .accordion-data {
                padding-left: 46px;
                opacity: 0;
                transition: all .3s ease-in;

                @include media-breakpoint-down(sm) {
                    padding-left: 50px;
                }
                .read-btn{
                    text-align: left;
                    svg{
                        display: none;
                    }
                    &:before{
                        content: "";
                        background-size: 22px;
                        @include media-breakpoint-down(md){
                            background-size: 18px;
                        }
                    }
                    &.minus-icon{
                        &:before{
                            content: "";
                            transform: rotate(90deg);
                            background: url(../img/minus-gray.svg) no-repeat center center;
                            background-size: 22px;
                            @include media-breakpoint-down(md){
                                background-size: 18px;
                            }
                        }
                    }
                }

                &.show {
                    opacity: 1;
                }
            }

            &:last-child {
                border-bottom: 1px solid $gainsboro;
            }
        }
    }
}
.credentials-section {
    &:last-child {
        padding-bottom: 140px;
    }
}
@-webkit-keyframes hvr-bob {
    0% {
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px);
    }
    50% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }
    100% {
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px);
    }
}
@keyframes hvr-bob {
    0% {
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px);
    }
    50% {
        -webkit-transform: translateY(-6px);
        transform: translateY(-6px);
    }
    100% {
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px);
    }
}
@-webkit-keyframes hvr-bob-float {
    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
}
@keyframes hvr-bob-float {
    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
}

.professional-detail {
    .cmn-overview-wrap {
        padding-bottom: 30px;
    }
    .credentials-section,
    .cmn-box ,
    .cmn-accordion{
        padding-top: 30px;
        padding-bottom: 30px;
    }
}

.overview-section {
    .default-text {
        margin-bottom: 20px;
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .toggle-text {
        margin-bottom:20px;
        display:none;
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .plus-link{
        position:relative;
        padding: 0 0 0 30px;
        color: #79757F;
        margin: 0;
        position: relative;
        display: inline-block;
        letter-spacing: normal;
        background: none;
        min-width: 0;
        border: 0;
        font-size: 17px;
        line-height: 23px;
        font-family: $font-medium;
        text-decoration: none;
        &:before {
            content: "";
            background: url('../img/secondary-plus-gray.svg') no-repeat center center;
            background-size: 100%;
            height: 22px;
            width: 22px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
        }
        &.minus-link {
            &:before {
                background: url('../img/minus-gray.svg') no-repeat center center;
                background-size: 100%;
                transform: rotate(90deg);
                content: "";
                height: 22px;
                width: 22px;
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
            }
        }
    }
}
.cmn-highlight {
    padding: 30px 0 10px 0;
    h3 {
        margin-bottom: 32px;
    }
    ul {
        margin-bottom: 20px;
    }
}