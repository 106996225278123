.featured-wrap {
    padding: 140px 0 16px;

    @include media-breakpoint-down(md) {
        padding: 81px 0 0;
    }
    // @include media-breakpoint-down(sm) {
    //     padding: 72px 0 0;
    // }

    .breadcrumbs {
        @include media-breakpoint-down(md) {
            margin-bottom: 37px;
        }
    }

    .col-md-6 {
        @media(min-width: 1919.98px) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .cmn-featured-slider {
        width: auto;
        margin: 0 -15px;

        @media(min-width: 1919.98px) {
            margin-bottom: 30px;
        }

        @include media-breakpoint-down(sm) {
            margin: 0 -6px 40px -6px;

            &:nth-child(2) {
                margin-bottom: 20px;
            }
        }

        .slick-list {
            @media(min-width: 1919.98px) {
                width: 3000px;
                overflow: hidden;
            }

            @include media-breakpoint-down(sm) {
                width: auto;
                overflow: hidden;
            }
        }

        .slick-track {
            display: -webkit-flex;
            display: flex;
        }

        .slider-inner {
            padding: 0 15px;
            height: inherit;

            @media(min-width: 1919.98px) {
                width: 533px;
                opacity: 0.4;
            }

            @include media-breakpoint-down(sm) {
                width: 308px;
                padding: 0 6px;
            }

            .box-with-image {
                position: relative;
                padding: 40px 30px;
                min-height: 354px;
                height: 100%;

                @media(min-width: 1919.98px) {
                    min-height: 300px;
                    padding: 37px 52px 32px 52px;
                }

                @include media-breakpoint-only(lg) {
                    min-height: 300px;
                    padding: 40px 20px;
                }

                @include media-breakpoint-down(sm) {
                    padding: 40px 33px;
                    min-height: 280px;
                }

                &.avatar-img-wrap {
                    padding-bottom: 100px;

                    @media(min-width: 1919.98px) {
                        padding-bottom: 92px;
                    }

                    @include media-breakpoint-down(sm) {
                        padding-bottom: 106px;
                    }

                    .avatar-div {
                        position: absolute;
                        z-index: 2;
                        margin: 0;
                        bottom: 40px;
                        left: 30px;
                        // height: 60px;
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: row;

                        @media(min-width: 1919.98px) {
                            left: 52px;
                            bottom: 32px;
                        }

                        @include media-breakpoint-only(lg) {
                            left: 30px;
                        }

                        @include media-breakpoint-only(lg) {
                            left: 33px;
                        }

                        span {
                            display: flex;
                            margin-left: -20px;
                            width: auto;
                            height: 60px;
                            width: 60px;
                            border-radius: 100%;
                            position: relative;
                            background: $cultured;
                            align-content: baseline;
                            overflow: hidden;
                            align-items: self-end;

                            &:first-child {
                                margin-left: 0;
                            }
                            img{
                                border-radius: 100%;
                                height: auto;
                                max-height: 60px;
                            }
                        }
                    }

                    svg {
                        opacity: 0;
                        visibility: hidden;
                        position: absolute
                    }
                }

                &:before {
                    position: absolute;
                    content: "";
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.9) 100%);
                    bottom: 0;
                }

                &:after {
                    position: absolute;
                    content: "";
                    background: $secondary-color;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: -1;
                    bottom: 0;
                }

                .divImg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    background-position: 50%;
                }

                h6,
                h5 {
                    position: relative;
                    z-index: 1;
                    color: #ffffff;
                }

                h6 {
                    margin: 0 0 12px 0;
                }

                .h1 {
                    position: relative;
                    z-index: 2;
                    color: $white-color;
                    max-width: 545px;
                    margin: 0 0 16px 0;
                }

                h2 {
                    position: relative;
                    z-index: 2;
                    color: $white-color;
                    max-width: 418px;
                    margin: 0 0 16px 0;
                }

                &:hover {
                    &:before {
                        z-index: 1;
                    }

                    &:after {
                        z-index: 1;
                        opacity: 1;
                    }

                    h5,
                    h6 {
                        z-index: 2;
                    }
                    
                    .avatar-div{
                        opacity: 0;
                        visibility: hidden;
                    }
                    svg{
                        opacity: 1 !important;
                        visibility: visible !important;
                    }

                    .stretched-link {
                        &:after {
                            z-index: 3;
                        }
                    }
                }

                svg {
                    height: 26px !important;
                    width: 26px !important;
                    position: relative;
                    z-index: 2;
                }
            }

            &.white-bg {
                .box-with-image {
                    border: 1px solid $gainsboro;

                    &:before {
                        position: absolute;
                        content: "";
                        background: $secondary-color;
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: 0;
                        right: 0;
                        z-index: 0;
                        bottom: 0;
                    }

                    &:after {
                        content: none;
                    }

                    &:before {
                        opacity: 0;
                        @include transition(0.3s);
                    }

                    h6 {
                        color: $rich-black;
                    }

                    h5 {
                        color: $rich-black;
                    }

                    &:hover {
                        &:before {
                            opacity: 1;
                            @include transition(0.3s);
                        }

                        h6 {
                            color: $white-color;
                        }

                        h5 {
                            color: $white-color;
                        }
                    }

                    &:focus,
                    &:active {
                        &:before {
                            background: $cyber-grape;
                            opacity: 1;
                            z-index: 1;
                        }
                    }
                }
            }

            &.slick-active {
                opacity: 1;
            }
        }

        .slick-dots {
            margin: 0;
            padding: 18px 15px 0 15px;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;

            li {
                margin: 0 8px 0 0;
                padding: 0;
                line-height: 0;

                &:before {
                    content: normal;
                }

                button {
                    font-size: 0;
                    padding: 0;
                    box-shadow: none;
                    height: 8px;
                    width: 8px;
                    border: 2px solid $rocket-metallic;
                    border-radius: 100%;
                    outline: none;
                    background: $rocket-metallic;
                }

                &.slick-active {
                    button {
                        background: transparent;
                    }
                }
            }
        }

        .slick-arrow {
            border: 0;
            height: 40px;
            width: 40px;
            display: inline-block;
            font-size: 0;
            background: url('../img/icon-carouselarrow-right.svg') no-repeat center center;
            background-size: 100%;
            position: absolute;
            left: -50px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 3;
            outline: 0;
            box-shadow: none;

            @include media-breakpoint-down(lg) {
                left: 0px;
            }

            &:hover,
            &.slick-disabled {
                opacity: 0.6;
            }

            &.slick-next {
                left: auto;
                right: -50px;
                transform: rotate(180deg);

                @include media-breakpoint-down(lg) {
                    right: 0px;
                }
            }
        }

    }

    .h4,
    h4 {
        margin-bottom: 12px;
    }
}

.news-Insights-filter {
    padding: 0 0 32px;

    @include media-breakpoint-down(md) {
        padding-bottom: 37px;
    }

    .search-box {
        &:before {
            background: url('../img/search-icon.svg') no-repeat top left;
            @include media-breakpoint-down(lg) {
                content: none;
            }
        }
        label {
            @include media-breakpoint-down(lg) {
                left: 0;
            }
        }
        input[data-empty="false"] + label,
        input:focus + label {
            @include media-breakpoint-down(lg) {
                left: 0;
            }
        }
        form {
            input[type="text"] {
                @include media-breakpoint-down(lg) {
                    padding-right: 60px;
                    padding-left: 0;
                }
            }
            input[type='submit'] {
                position: absolute;
                height: 61px;
                background: none;
                outline: none;
                -webkit-appearance: none;
                appearance: none;
                width: 25px;
                top: 0;
                left: 0;
                padding: 0;
                font-size: 0;
                border: 0;
                @include media-breakpoint-down(lg) {
                    background-image: url('../img/mobile-search-icon.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    right: 0;
                    top: auto;
                    bottom: 0;
                    height: 52px;
                    left: auto;
                    width: 46px;
                    background-color: $cyber-grape;
                }
            }
        }
    }
    .col-xl-3 {
        padding-top: 19.5px;
        @media(max-width: 1919.98px) {
            padding-top: 26.5px;
        }
        @media(max-width: 991.98px) {
            padding-top: 25.5px;
        }
    }

    .social-share {
        padding-top: 12px;
        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            margin-left: inherit !important;
            max-width: 100%;
            margin-right: auto;
            padding-left: 30px;
            justify-content: flex-start !important;
        }

        span {
            margin-right: 24px;
            color: $rich-black;
            text-transform: capitalize;
            margin-top: 5px;
        }

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @include media-breakpoint-down(md) {
                justify-content: flex-start;
            }

            li {
                margin: 0 21px 0 0px;
                padding: 0;
                line-height: normal;

                a {
                    text-decoration: none;
                    padding: 0 0 5px 0;
                    line-height: 20px;
                    display: inline-block;
                    position: relative;

                    @include media-breakpoint-down(lg) {
                        width: 20px;
                        height: 20px;
                    }

                    img {
                        max-width: 22px;
                    }
                    svg {
                        * {
                            fill: $rocket-metallic;
                        }
                    }
                    &:before {
                        content: "";
                        background: $rocket-metallic;
                        height: 1px;
                        width: 100%;
                        bottom: 0;
                        opacity: 0;
                        left: 0;
                        position: absolute;
                    }

                    &:hover {
                        svg,
                        svg * {
                            fill: $rocket-metallic;
                        }
                        &:before {
                            opacity: 1;
                        }
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .date-field {
        position: relative;
        &:before {
            background: url('../img/calendar.png') no-repeat 0 0;
            content: "";
            position: absolute;
            left: 15px;
            top: 25px;
            height: 19px;
            display: inline-block;
            width: 19px;
            z-index: 1;
            background-size: cover;
        }
        input {
            padding-left: 30px;
        }
    }
    #filterToggle {
        color: #79757F;
        text-align: left;
        margin-bottom: 16px;
        background: url('../img/secondary-plus-gray.svg') no-repeat 0 0;
        background-size: 18px;
        padding: 0 0 0 29px;
        position: relative;
        margin: 20px 0 0 0;
        &.slider-open {
            background:none;
            &:before{
                background: url('../img/minus-gray.svg') no-repeat 0 0;
                background-size: 18px;
                position: absolute;
                left: -6px;
                top: 0;
                content: "";
                height: 24px;
                width: 24px;
                transform: rotate(90deg);
            }
        }
        @include media-breakpoint-up(xl) {
            display: none;
        }
    }
    .toggleFilter {
        display: none;
        @include media-breakpoint-up(xl) {
            display: flex !important;
        }
    }
}

.news-list-wrap {
    padding: 32px 0 38px 0;

    @include media-breakpoint-down(md) {
        padding: 37px 0 20px 0;
    }

    .news-list {
        padding: 40px 0;
        border-bottom: 1px solid $gainsboro;
        position: relative;

        @include media-breakpoint-down(md) {
            padding: 35px 0;
        }

        .news-img {
            min-width: 169px;
            max-width: 169px;
            margin-right: 30px;

            @include media-breakpoint-down(sm) {
                margin-bottom: 20px;
            }

            img {
                width: 100%;
            }
        }

        .news-content {
            position: static;
            color: $rich-black;

            h6 {
                margin-bottom: 4px;
                color: $rich-black;
            }

            .text-14 {
                margin-bottom: 16px;
            }

            h4 {
                margin-bottom: 16px;
                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                }

                a {
                    color: $rich-black;
                    display: block;
                    text-decoration: none;
                }
            }
            p {
                &:last-child {
                    margin-bottom: 0;
                }
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
            > div {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            &:hover {
                h6 {
                    color: $cyber-grape;
                }
                h4 {
                    margin-bottom: 16px;
                    @include media-breakpoint-down(md) {
                        margin-bottom: 0;
                        color: $rich-black;
                    }

                    a {
                        color: $cyber-grape;
                        mark {
                            color: $cyber-grape !important;
                        }
                    }
                }
            }
        }

        &:first-child {
            border-top: 1px solid $gainsboro;
        }
    }

    .loader-wrap {
        padding: 80px 0;

        @include media-breakpoint-down(md) {
            padding: 40px 0;
        }

        img {
            -webkit-animation: rotation 2s infinite linear;
            animation: rotation 2s infinite linear;
        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }

        @-webkit-keyframes rotation {
            from {
                -webkit-transform: rotate(0deg);
            }

            to {
                -webkit-transform: rotate(359deg);
            }
        }


    }
}

.news-insights-banner {
    .banner-text h1:after {
        bottom: -23px;
    }
}

.news-insights-wrap {
    padding: 48px 0 20px 0;

    .news-insights-info {
        color: $gray;
        padding-bottom: 42px;
        @include media-breakpoint-down(md){
            padding-bottom: 20px;
        }

        h6 {
            margin-bottom: 8px;
            color: $rich-black;
        }
    }
    a {
        text-decoration: underline;
        color: #79757F;
        &:hover {
            color: $secondary-color;
        }
    }
    strong {
        a {
            text-decoration: underline;
            color: #79757F;
            &:hover {
                color: $secondary-color;
            }
        }
    }
}
.news-insights-box{
    padding-bottom: 115px;
    @include media-breakpoint-down(md){
        padding: 0 0 50px;
    }
}

.featured-wrap {
    .cmn-featured-slider {
        .slick-arrow {
            &.slick-arrow {
                &.slick-disabled {
                    pointer-events: none;
                    opacity: 1;
                }
            }
        }
    }
}
.loader-wrap {
    img {
        animation: rotation 2s infinite linear;
    }
}

.cmn-featured-slider-wrap {
    overflow-y: hidden;
    overflow-x: auto;
    margin: 0 0 40px 0;
    .cmn-featured-slider {
        display: flex;
        margin: 0 0 0 0;
        flex-direction: row;
        flex-wrap: nowrap;
        .slider-inner {
            margin-right: 12px;
            padding: 0;
            max-width: 308px;
            min-width: 308px;
        }
    }
}

.share-heading {
    display: flex;
    .new-share {
        position: relative;
        margin-left: 19px;
        #shareSocial {
            height: 18px;
            width: 18px;
            background: url('../img/iconshare.png') no-repeat 0 0;
            font-size: 0;
            display: inline-block;
            vertical-align: middle;
        }
        .a2a_kit {
            position: absolute;
            top: -12px;
            left: auto;
            right: auto;
            box-shadow: 0 2px 8px 0 rgba(136,136,136,0.5);
            margin-left: 50px;
            padding: 13px 12px;
            border-radius: 5px;
            width: auto;
            justify-content: flex-start;
            align-items: center;
            display: none;
            @include media-breakpoint-down(xs) {
                top: -55px;
                margin-left: -70px;
            }
            &.open {
                display: flex;
            }
            a {
                margin-right: 30px;
            }
            .a2a_dd {
                display: none !important;
            }
        }
        .a2a_button_linkedin {
            height: 18px;
            width: 18px;
            background: url('../img/iconlinkedin.svg') no-repeat 0 0;
            span {
                display: none;
            }
        }
        .a2a_button_twitter {
            height: 16px;
            width: 20px;
            background: url('../img/icontwitter.svg') no-repeat 0 0;
            span {
                display: none;
            }
        }
        .a2a_button_email {
            height: 16px;
            margin-right: 0 !important;
            width: 22px;
            background: url('../img/iconmail.png') no-repeat 0 0;
            span {
                display: none;
            }
        }
    }
}
.insightSlider-div ,
.newsSlider-div {
    .cmn-featured-slider {
        .slider-inner {
            .box-with-image {
                @media(min-width: 1919.98px) {
                    min-height: 300px !important;
                }
            }
        }
    }
}
.datepicker-dropdown {
    border: none;
	padding: 0;
    line-height: normal;
    table {
        font-weight: normal;
        font-family: $font-regular;
        th {
            font-weight: normal;
            font-family: $font-bold;
        }
        tr {
            td,
            th {
                text-align: center;
                width: 42px;
                height: 42px;
                border-radius: 0;
                border: none;
                font-size: 14px;
                border: 1px solid $gainsboro;
                span {
                    border-radius: 0px;
                }
            }
        }
    }
}

.datepicker table tr td span.active:active, 
.datepicker table tr td span.active:hover:active, 
.datepicker table tr td span.active.disabled:active, 
.datepicker table tr td span.active.disabled:hover:active, 
.datepicker table tr td span.active.active, 
.datepicker table tr td span.active:hover.active, 
.datepicker table tr td span.active.disabled.active, 
.datepicker table tr td span.active.disabled:hover.active, 
.datepicker table tr td.active:active, 
.datepicker table tr td.active.highlighted:active, 
.datepicker table tr td.active.active, 
.datepicker table tr td.active.highlighted.active ,
.datepicker table tr td.active.active:hover{
	color: $white-color;
    background-color: $secondary-color;
    border-color: $secondary-color;
}