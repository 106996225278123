.professional-detail-head.location-detail {
    overflow: hidden;
    padding: 95px 0px 10px 0px;

    @include media-breakpoint-down(md) {
        padding: 81px 0px 10px 0px;
    }
    @include media-breakpoint-down(sm) {
        display: block;
        padding-top: 0px;

        .divImg {
            height: 250px;
            margin-bottom: 40px;
            position: relative;
        }

        .breadcrumbs {
            position: absolute;
            top: 82px;
            left: 0px;
            z-index: 3;
        }
    }

    &:before {
        background: linear-gradient(180deg, #FFFFFF 0%, rgba(255,255,255,0) 100%);
        content: '';
        opacity: 0.85;
        position: absolute;
        top: 0;
        left: 0;
        height: 580px;
        width: 100%;
        z-index: 1;
    }
    &:after {
        background: rgb(255,255,255);
        background: linear-gradient(270deg, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 100%);
        opacity: 0.85;
        width: 66%;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .container {
        position: relative;
        z-index: 2;
    }

    .professional-detail-desc {
        .h3 {
            margin: 0px 0px 15px;
            color: $rich-black;
        }
        
        .detail-foot {
            .contact-info {
                @include media-breakpoint-down(sm) {
                    max-width: 100%;
                }
                a {
                    color: $rich-black;
                    margin: 0px 0px 10px;
            
                    em {
                        display: block;
                        font-style: normal;
                    }
            
                    &:hover {
                        color: $rich-black;
                    }
                    &:first-child {
                        //color: $rocket-metallic;
                        em {
                            color: $rich-black;
                        }
                    }
                }
            }
            
        } 
    }


}
.map-section {
    width: 100%;
    margin-bottom: 60px;
    margin-top: -20px;
    position: relative;
    img {
        width: 100%;
        height: auto;
        &.map-pin {
            width: 42px;
            height: 60px;
            position: absolute;
            top: 56px;
            left: -43px;
            margin: 0 auto;
            right: 0;
        }
    }
}