.cmn-social-section {
    padding: 180px 0 150px 0;
    position: relative;
    z-index: 2;
    background: $white-color;

    @include media-breakpoint-down(sm) {
        padding: 80px 0 50px 0;
    }

    @include media-breakpoint-down(sm) {
        padding-bottom: 66px;
    }

    [class*="col-"] {
        margin-bottom: 30px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 16px;
        }
        @include media-breakpoint-only(md) {
            &:nth-child(2n + 1) {
                animation-delay: 0.2s !important;
                animation-duration: 1s !important;
            }

            &:nth-child(2n + 2) {
                animation-delay: 0.4s !important;
                animation-duration: 1s !important;
            }
        }
        @include media-breakpoint-down(sm) {
            animation-delay: 0.2s !important;
            animation-duration: 1s !important;
        }

        h2 {
            margin-bottom: 25px;
        }

        .cmn-social-inner {
            border: 1px solid transparent;
            background-color: $white-color;
            padding: 60px 40px 40px 40px;
            min-height: 384px;
            @include transition(0.15s);
            height: 100%;
            position: relative;
            overflow: hidden;

            @include media-breakpoint-down(lg) {
                padding: 25px;
                min-height: 315px;
            }

            @include media-breakpoint-down(md) {
                padding: 50px 40px 30px 40px;
                min-height: 340px;
            }

            @include media-breakpoint-down(sm) {
                min-height: 250px;
            }

            .divImg {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                height: 100%;
                width: 100%;
                background-position: 50%;
                @include transition(1s);
                -webkit-transform: scale(1.3);
                transform: scale(1.3);
            }

            .cmn-social-content {
                height: 100%;

                h6 {
                    margin-bottom: 16px;

                }

                a {
                    text-decoration: none;
                    color: $rich-black;
                    @include transition(0.15s);
                    display: block;
                    margin-bottom: 0;
                }

                p {
                    margin-bottom: 16px;
                }

                svg {
                    height: 26px !important;
                    width: 26px !important;
                    position: relative;
                    display: block;
                    z-index: 3;
                }

                &.text-16 {
                    color: $white-color;
                }
            }


            &.bg-grey {
                background: $cultured;
                border-color: transparent;

                svg,
                svg * {
                    stroke: $gray;
                }
            }

            &.bg-white {
                border: 1px solid $gainsboro;
                background: $cultured;

                svg,
                svg * {
                    stroke: $gray;
                }
            }

            &.bg-green {
                background: $primary-color;
                color: $white-color;

                a {
                    color: $white-color;
                }
            }

            &.bg-purple {
                background: $secondary-color;
                color: $white-color;

                a {
                    color: $white-color;
                }

            }

            &.bg-orange {
                background: $chocolate-web;
                color: $white-color;

                a {
                    color: $white-color;
                }
            }

            &.img-wrap {
                border: 0;

                @include media-breakpoint-down(sm) {
                    min-height: 360px;
                    padding: 22px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    height: 192px;
                    left: 0;
                    right: 0;
                    top: auto;
                    z-index: 0;
                    background: rgb(0, 0, 0);
                    transform: translateY(100%);
                    @include transition(0.4s);
                    background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                    background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
                }

                .stretched-link {
                    &:after {
                        z-index: 2;
                    }
                }

                .cmn-social-content {
                    transform: translateY(100%);
                    @include transition(0.4s);
                    position: relative;
                    z-index: 1;

                    span {
                        display: block;
                        margin-bottom: 0;

                        @include media-breakpoint-down(sm) {
                            margin-bottom: 0;
                        }
                    }
                }
                svg{
                    display: none;
                }
            }

            &.overlay-wrap {
                border: 0;
                background: transparent !important;

                @include media-breakpoint-down(sm) {
                    min-height: 340px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    height: 100%;
                    left: 0;
                    right: 0;
                    top: 0;
                    width: 100%;
                    z-index: 0;
                    background: rgb(0, 0, 0);
                    background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.8) 100%);
                    background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.8) 100%);
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0.8) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
                }

                .stretched-link {
                    &:after {
                        z-index: 2;
                    }
                }

                .cmn-social-content {

                    h6 {
                        color: $white-color;
                        position: relative;
                        z-index: 1;
                    }

                    a {
                        color: $white-color;

                        span {
                            position: relative;
                            z-index: 2;
                            display: block;
                            margin: 0 0 16px 0;
                        }
                    }

                    span {
                        display: block;
                        margin-bottom: 16px;
                    }
                }
            }

            &:hover {

                .arrow-icon {
                    margin-left: 0px;
                    opacity: 1;
                    visibility: visible;
                }

                &.img-wrap {
                    background: transparent;

                    &:after {
                        transform: translateY(0%)
                    }

                    .cmn-social-content {
                        transform: translateY(0%);
                    }
                }
            }
        }

        &.col-lg-8 {
            .cmn-social-inner {
                padding: 0;
                border: 0;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;

                .bg-wrap {
                    width: 50%;
                    order: 3;
                    overflow: hidden;
                    position: relative;

                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        height: 335px;
                        order: 2;
                    }

                    .divImg {
                        @include media-breakpoint-down(xs) {
                            background-position: top center;
                        }
                    }
                }

                .cmn-social-content {
                    padding: 60px 40px 20px 40px;
                    width: 50%;
                    background-color: $secondary-color;

                    @include media-breakpoint-down(lg) {
                        padding: 25px;
                    }

                    @include media-breakpoint-down(md) {
                        padding: 50px 40px;
                    }

                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        order: 1;
                        height: auto;
                        min-height: 217px;
                    }

                    h6 {
                        color: $white-color;
                    }

                    a {
                        color: $white-color;

                        span {
                            position: relative;
                            z-index: 2;
                            display: block;
                            margin: 0 0 16px 0;
                        }
                    }
                }

                &:hover {
                    background-color: $white-color;
                }
            }
        }

        &.col-lg-12 {
            .cmn-social-inner {
                padding: 0;
                border: 0;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;

                .bg-wrap {
                    width: 66%;
                    order: 3;
                    overflow: hidden;
                    position: relative;

                    @include media-breakpoint-down(md) {
                        width: 50%;
                    }

                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        height: 335px;
                        order: 2;
                    }

                    .divImg {
                        @include media-breakpoint-down(xs) {
                            background-position: top center;
                        }
                    }
                }

                .cmn-social-content {
                    padding: 103px 40px 20px 40px;
                    width: 34%;
                    background-color: $secondary-color;

                    @include media-breakpoint-down(lg) {
                        padding: 25px;
                    }

                    @include media-breakpoint-down(md) {
                        width: 50%;
                        padding: 50px 40px;
                    }

                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        order: 1;
                        height: auto;
                        min-height: 217px;
                    }

                    h6 {
                        color: $white-color;
                    }

                    a {
                        color: $white-color;

                        span {
                            position: relative;
                            z-index: 2;
                            display: block;
                            margin: 0 0 16px 0;
                        }
                    }
                }

                &:hover {
                    background-color: $white-color;
                }
            }
        }

        &[style="visibility: visible; animation-duration: 1s; animation-delay: 0.2s; animation-name: custom-fadeInUp;"],
        &[style="visibility: visible; animation-duration: 1s; animation-delay: 0.4s; animation-name: custom-fadeInUp;"],
        &[style="visibility: visible; animation-duration: 1s; animation-delay: 0.6s; animation-name: custom-fadeInUp;"] {
            .cmn-social-inner {
                .divImg {
                    transition-delay: 0.4s;
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }
            }
        }
    }
}