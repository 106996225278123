.cmn-tab {
    padding: 0 0 190px 0;
    @include media-breakpoint-down(md) {
        padding: 0 0 122px 0;
    }
    .nav-tabs {
        display: flex;
        justify-content: center;
        border: 0;
        margin: 0 0 80px 0;
        background: $secondary-color;
        @include media-breakpoint-down(sm) {
            background: none;
            margin: 0 0 32px 0;
            justify-content: flex-start;
        }
        .nav-item {
            margin-bottom: 0;
            background: $fern-green;
            @include media-breakpoint-down(sm) {
                width: 50%;
                text-align: center;
            }
            .nav-link {
                @extend .h4;
                border: 0;
                margin: 0;
                padding: 25px 24px;
                text-decoration: none;
                text-transform: inherit;
                border-radius: 0;
                color: $white-color;
                border-right: 1px solid rgba(255, 255, 255, 0.3);
                @include media-breakpoint-down(sm) {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                    padding: 10px 15px;
                    height: 100%;
                    display: flex;
                    -webkit-align-items: center;
                    align-items: center;
                    min-height: 80px;   
                    -webkit-justify-content: center;
                    justify-content: center;
                }
                .for-flex {
                    font-style: normal;
                }
                span {
                    position: relative;
                    display: inline;
                    border-bottom: 3px solid transparent;
                    transition: all 0.3s ease;
                    position: relative;
                    z-index: 1;
                    background: linear-gradient(90deg, transparent, transparent), linear-gradient(90deg, $cyber-grape, $cyber-grape, $cyber-grape);
                    background-size: 0px 0 , 0px 3px;
                    background-position: 3px 3px,0px 100%;
                    background-repeat: no-repeat;
                    transition: background-size .6s;
                    // &:after {
                    //     content: "";
                    //     height: 3px;
                    //     width: 0%;
                    //     background-color: transparent;
                    //     display: block;
                    //     position: absolute;
                    //     bottom: -5px;
                    //     left: 0;
                    //     right: auto;
                    //     z-index: 1;
                    //     margin: auto;
                    //     transition: all 0.35s ease;
                    // }
                }
                &:hover {
                    color: rgba(255, 255, 255, 0.6);
                }
                &.active {
                    color: $cyber-grape;
                    span {
                        
                        background-size: 0 3px,100% 3px;
                        // &:after {
                        //     background-color: $spanish-orange;
                        //     width: 100%;
                        //     transition: all 0.35s ease;
                        // }
                    }
                }
            }
  
            &:first-child {
                .nav-link {
                    margin-left: 0;
                    border-left: 1px solid rgba(255, 255, 255, 0.3);
                }
            }
  
            &:last-child {
                .nav-link {
                    margin-right: 0;
                }
            }
        }
    }
    .tab-content {
        .card {
            @include border-radius(0);
            @include media-breakpoint-up(lg) {
                border: none;
            }
            @include media-breakpoint-down(md) {
                margin-top: -1px;
            }
            .card-header {
                display: none;
                background: rgba(0, 0, 0, 0.03);
                border-bottom: 0;
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }
            .collapse {
                display: block;
            }
        }
  
        & >.tab-pane {
            border: 0;
            margin: 0;
            box-shadow: none;
            @include media-breakpoint-down(md) {
                display: none;
            }
            &.show {
                &.active {
                    @include media-breakpoint-down(md) {
                        display: block;
                    }
                    
                }
            }
        }
    }
}