
.cms-page {
    padding: 180px 0px 115px;
    @include media-breakpoint-down(md) { 
        padding: 150px 0px 80px;
    }
    @include media-breakpoint-down(sm) { 
        padding: 81px 0px 70px;
        min-height: 500px;
    }
    &.cmn-tab {
        padding-top: 70px;
        .tabbing-data {
            .filter-tabbing {
                .filter-data {
                    ul {
                        margin: 0px 0px 35px;
                        padding: 0;
                        column-count: 1;
                        li {
                            list-style: none;
                            margin: 0px 0px 19px;
                            padding: 0px 0px 0px 25px;
                            position: relative;
                            &:before{
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 12px;
                                height: 6px;
                                width: 6px;
                                border-radius: 0;
                                background: $secondary-color;
                            }
                        }
                    }
                    strong {
                        display: block;
                        margin-bottom: 10px;
                    }
                    .default-text {
                        margin-bottom: 20px;
                    }
                    .toggle-text {
                        margin-bottom: 20px;
                        display: none;
                    }
                    .plus-link {
                        position: relative;
                        padding: 0 0 0 30px;
                        color: $rocket-metallic;
                        margin: 0;
                        position: relative;
                        display: inline-block;
                        letter-spacing: normal;
                        background: none;
                        min-width: 0;
                        border: 0;
                        font-size: 17px;
                        line-height: 23px;
                        font-family: "Heebo-Medium", sans-serif;
                        text-decoration: none;
                        &:before {
                            content: "";
                            background: url('../img/secondary-plus-gray.svg') no-repeat center center;
                            background-size: 100%;
                            height: 22px;
                            width: 22px;
                            display: inline-block;
                            position: absolute;
                            left: 0;
                            top: 0;
                            bottom: 0;
                        }
                        &.minus-link {
                            &:before {
                                background: url("../img/minus-gray.svg") no-repeat center center;
                                background-size: 100%;
                                transform: rotate(90deg);
                                content: "";
                                height: 22px;
                                width: 22px;
                                display: inline-block;
                                position: absolute;
                                left: 0;
                                top: 0;
                                bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.cms-section  {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    min-height: 100vh;
    padding: 130px 0px;

    @include media-breakpoint-down(md) { 
        padding: 110px 0px 80px;
    }
}

.no-result-found {
    min-height: 400px;
    @include media-breakpoint-down(sm) {
        min-height: 250px;
    }
    .h3 {
        color: #3E2268;
    }
}

.public-animation {
    h4 {
        text-align: center;
        line-height: 38px;
        margin-bottom: 35px;
        color: $light-black;
        font-size: 31px;
        font-family: $font-bold;
    }
}
.boy-wrapper ,
.boy-wrapper1{
    display: flex;
    @include media-breakpoint-down(md) {
        margin-bottom: 60px;
        justify-content: center;
    }
    .boy,
    .boy1 {
        margin-right: 47px;
        height: 239px;
        width: 92px;
        background-image: url('../img/babla-normal.png');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
        @media(min-width: 1919.98px) {
            height: 352px;
            width: 136px;
        }
        @include media-breakpoint-down(lg) {
            margin-right: 20px;
            height: 217px;
            width: 92px;
        }
        @include media-breakpoint-down(md) {
            margin-right: 20px;
            height: 239px;
            width: 92px;
        }
        @include media-breakpoint-down(xs) {
            margin-right: 10px;
        }
        &:last-child {
            margin-right: 0;
        }
        span {
            font-family: "Heebo-Medium", sans-serif;
            font-size: 18px;
            line-height: 25px;
            position: absolute;
            text-transform: capitalize;
            left: 0;
            color: $rocket-metallic;
            right: 0;
            text-align: center;
            bottom: -35px;
            letter-spacing: 0.01em;
            @media(min-width: 1919.98px) {
                font-size: 26px;
                bottom: -45px;
                line-height: 35px;
            }
            @include media-breakpoint-down(lg) {
                font-size: 16px;
                line-height: 25px;
            }
        }
        .boy-active,
        .boy-active1 {
            background-image: url('../img/babla-active.png');
            background-position: bottom center;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0%;
            transition: height 0.5s linear 0s;
            &.animated {
                .percentage ,
                .percentage1{
                    opacity: 1;
                }
            }
        }
        &.one-stats,
        &.one-stats1 {
            .boy-active.animated,
            .boy-active1.animated {
                transition-delay: 0s;
            }
        }
        &.two-stats,
        &.two-stats1 {
            .boy-active.animated,
            .boy-active1.animated {
                transition-delay: 0.2s;
            }
        }
        &.three-stats,
        &.three-stats1 {
            .boy-active.animated,
            .boy-active1.animated {
                transition-delay: 0.4s;
            }
        }
        .percentage,
        .percentage1 {
            color: $white-color;
            position: absolute;
            transform: translate(-50%, 0%);
            top: 15px;
            font-family: $font-bold;
            left: 50%;
            opacity: 0;
            z-index: 1;
            font-size: 25px;
            line-height: 30px;
            @media(min-width: 1919.98px) {
                font-size: 30px;
            line-height: 26px;
            }
        }
        .percentage,
        .percentage1 {
            top: 34%;
            transform: translate(-50%, -88%);
            @media(max-width: 1919.98px) {
                top: 40%;
            }
        }
    }
}


.center-layout-section {
    &.summer-title-padding {
        .col-right {
            @include media-breakpoint-down(md) {
                justify-content: center;
            }
        }
        .center-layout-data {
            .bar-wrapper {
                @include media-breakpoint-down(md) {
                    margin-top: 40px;
                }
            }
        }
    }
}
.bar-wrapper {
    padding-left: 30px;
    display: flex;
    margin-bottom: 45px;
    padding-right: 30px;
    border-bottom: 3px solid $light-black;
    @include media-breakpoint-down(md) {
        margin-bottom: 60px;
        justify-content: center;
    }
    .bar {
        width: 125px;
        margin-right: 30px;
        position: relative;
        height: 300px;
        text-align: center;
        &:last-child {
            margin-right: 0;
        }
        @media(min-width: 1919.98px) {
            width: 165px;
        }
        @include media-breakpoint-down(lg) {
            margin-right: 20px;
        }
        @include media-breakpoint-down(xs) {
            width: 70px;
            margin-right: 10px;
        }
        span {
            font-family: "Heebo-Medium", sans-serif;
            font-size: 18px;
            line-height: 25px;
            position: absolute;
            text-transform: capitalize;
            left: 0;
            color: $secondary-color;
            right: 0;
            text-align: center;
            bottom: -35px;
            letter-spacing: 0.01em;
            @media(min-width: 1919.98px) {
                font-size: 26px;
                bottom: -45px;
                line-height: 35px;
            }
            @include media-breakpoint-down(lg) {
                font-size: 16px;
                line-height: 25px;
            }
        }
        .bar-active {
            background: $secondary-color;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0%;
            transition: height 0.5s linear 0s;
        }
        &.one-stats {
            .bar-active.animated {
                transition-delay: 0s;
            }
        }
        &.two-stats {
            .bar-active.animated {
                transition-delay: 0.2s;
            }
        }
        &.three-stats {
            .bar-active.animated {
                transition-delay: 0.4s;
            }
        }
        .percentage {
            color: $white-color;
            position: absolute;
            transform: translate(-50%, 0%);
            top: 15px;
            font-family: $font-bold;
            left: 50%;
            z-index: 1;
            font-size: 25px;
            line-height: 30px;
            @media(min-width: 1919.98px) {
                font-size: 35px;
                line-height: 40px;
            }
        }
    }
}