.statistics-section {
    // background: url('../img/image-greenbg.jpg') no-repeat center center;
    // background-size: cover;
    // background-attachment: fixed;
    // padding: 0;
    // min-height: 670px;
    // @include media-breakpoint-down(lg) {
    //     background-attachment: scroll;
    // }

    [class*="col-"] {
        @include media-breakpoint-down(md) {
            margin-bottom: 60px;
        }

        .statistics-column {
            text-align: center;

            h2 {
                color: $white-color;
                font-size: 130px;
                letter-spacing: -3.47px;
                font-family: $font-bold;
                line-height: 110px;
                text-align: center;
                margin-bottom: 10px;

                @include media-breakpoint-down(md) {
                    font-size: 90px;
                    letter-spacing: 0;
                    line-height: 90px;
                    margin-bottom: 2px;
                }

                span {
                    display: inline-block;
                }
            }

            .statistics-subtitle {
                min-height: 52px;
                position: relative;
                @media(min-width: 1919.98px) {
                    min-height: 64px;
                }
                @include media-breakpoint-down(md) {
                    min-height: 1px;
                }
                &:before {
                    content: "";
                    bottom: -35px;
                    width: 53px;
                    height: 4px;
                    background: rgba(255,255,255,0.8);
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    @include media-breakpoint-down(md) {
                        bottom: -20px;
                    }
                }
                h4 {
                    margin-bottom: 2px;
                    color: rgba(255,255,255,0.8);
                }
                .text-14 {
                    display: block;
                    color: rgba(255,255,255,0.8);
                }
            }
            ul {
                margin: 40px 0 0;
                border-top: 0;
                color: $white-color;
                @include media-breakpoint-down(md) {
                    margin-top: 25px;
                }

                li {
                    padding: 23.5px 0;
                    margin: 0;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-align-items: center;
                    align-items: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    border-bottom: 1px solid rgb(117, 143, 117);

                    @include media-breakpoint-down(md) {
                        padding: 14px 0;
                    }

                    h3 {
                        margin: 0 12px 0 0;
                        font-family: $font-medium;
                    }
                }
            }
        }
    }

    .statistics-fix {
        padding: 140px 0;
        width: 100%;
        position: static;
        top: 0;
        z-index: 0;
        bottom: 0;
        height: auto;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        @include media-breakpoint-down(lg) {
            padding: 90px 0;
        }
        @include media-breakpoint-down(md) {
            padding: 75px 0 25px 0;
            position: static;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }
    }

    &.is-inview {
        .statistics-fix {
            position: fixed;
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            @include media-breakpoint-down(md){
                position: static;
            }
            .col-lg-4{
                &:nth-child(1) {
                    animation-delay: 0.2s;
                    animation-duration: 1s;
                    animation-name: custom-fadeInUp;
                }
                &:nth-child(2) {
                    animation-delay: 0.4s;
                    animation-duration: 1s;
                    animation-name: custom-fadeInUp;
                }
                &:nth-child(3) {
                    animation-delay: 0.6s;
                    animation-duration: 1s;
                    animation-name: custom-fadeInUp;
                }
            }
        }
    }
}
.bg-holder {
    width: 100%;
    height: 100%;
    min-height: 700px;
    background-size: cover !important;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: relative;
    color: #fff;
    @include media-breakpoint-down(lg) {
        min-height: 620px !important;
    }
    &.statistics-section {
        .statistics-fix {
            visibility: visible;
            opacity: 1;
        }
    }
    .simpleParallax {
        .thumbnail {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}