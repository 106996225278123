.hero-banner {
    padding: 0;
    width: 100vw;
    min-height: 100vh;
    z-index: 0;
    position: fixed;
    top: 0;
    @include transition(0.5s);

    @include media-breakpoint-only(md) {
        position: static;
    }
    @include media-breakpoint-down(sm) {
        bottom: 0;
    }

    // @include media-breakpoint-down(sm) {
    //     min-height: calc(100vh - 73px);
    //     top: 73px;

    // }

    .hero-items {
        width: 100%;

        .hero-item {
            min-height: 100vh;
            padding: 60px 0 75px 0;
            position: relative;
            color: $white-color;
            width: 100%;

            @include media-breakpoint-only(sm) {
                min-height: 600px;
            }

            @include media-breakpoint-down(sm) {
                min-height: calc(100vh - 73px);
            }

            @include media-breakpoint-only(xs) {
                //height: calc(100vh - 61px);
                min-height: 750px;
                padding-top: 80px;
            }

            /** &:before {
                content: '';
                min-height: inherit;
                font-size: 0;
            } */
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgb(0, 0, 0);
                background: -moz-radial-gradient(circle, rgba(0, 0, 0, 0) 0%, #000000 100%);
                background: -webkit-radial-gradient(circle, rgba(0, 0, 0, 0) 0%, #000000 100%);
                background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, #000000 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
                z-index: 1;
                opacity: 0.7;
            }

            .divImg {
                position: absolute;
                background-color: $gray-base;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-position: top center;
            }

            .container {
                position: relative;
                z-index: 2;

                h1 {
                    margin-bottom: 28px;

                    @include media-breakpoint-down(xs) {
                        margin-bottom: 25px;
                    }
                }

                .btn-primary-link {
                    margin-top: 5px;
                    display: inline-block;
                    color: $white-color;
                    background: none;

                    svg {
                        * {
                            stroke: #ffffff;
                        }
                    }
                }
            }

            &.slick-active {
                .divImg {
                    -webkit-animation: fadeZoom 3s forwards;
                    animation: fadeZoom 3s forwards;
                }
            }
        }

        .slick-arrow {
            border: 0;
            height: 40px;
            width: 40px;
            display: inline-block;
            font-size: 0;
            background: url(../img/icon-carouselarrow-left.svg) no-repeat center center;
            background-size: 100%;
            position: absolute;
            left: 26px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 3;
            outline: 0;
            box-shadow: none;

            @include media-breakpoint-down(lg) {
                left: 0px;
            }

            &:hover,
            &.slick-disabled {
                opacity: 0.6;
            }

            &.slick-next {
                left: auto;
                right: 26px;
                transform: rotate(180deg);

                @include media-breakpoint-down(lg) {
                    right: 0px;
                }
            }
        }
        .slick-arrow {
            display: none !important;
        }
    }

    .hp-banner-arrow {
        position: absolute;
        bottom: 25px;
        left: 0;
        right: 0;
        margin: auto;
        top: auto;
        width: 130px;
        text-align: center;
        border: 0;
        z-index: 1;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.12px;
        text-decoration: none;
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &.banner-fix {
        min-height:100vh;
        top: 0;

        // @include media-breakpoint-down(md) {
        //     min-height: calc(100vh - 60px);
        //     top: 60px;
        // }
    }

    &.hide {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
    }
}

.progressBarContainer {
    position: relative;
    margin: 0px auto;
    top: -50px;
    @extend .container;
    @include media-breakpoint-down(sm)  {
        position: absolute;
        bottom: 50px;
        top: auto;
    }
    @media screen and (max-device-width: 991.98px) and (orientation : landscape) {
        bottom: 20px;
        position: absolute;
        top: auto;
    }
    div {
        display: block;
        width: 35px;
        padding: 0;
        cursor: pointer;
        margin-right: 6px;
        float: left;
        color: white;

        &:last-child {
            margin-right: 0;
        }

        span.progressBar {
            width: 100%;
            height: 4px;
            background-color: rgba(255, 255, 255, 0.4);
            display: block;

            .inProgress {
                background-color: rgba(255, 255, 255, 1);
                width: 0%;
                height: 4px;
            }
        }
    }
}

.hp-inner-content {
    min-height: 1200px;
    position: relative;
    margin-top: 100vh;
    background: #fff;
    padding: 0;

    @include media-breakpoint-only(sm) {
        margin-top: 0;
        float: left;
        width: 100%;
    }
}

// Scroll section
.home {
    .intro-section {
        margin-top: 100vh;
    }
    .m-top {
        margin-top: 100vh;
        position: relative;
        z-index: 2;
        background: #f2f3f7;
        @include media-breakpoint-down(sm) {
            padding-top: 20px;
        }
        .video-popup-section {
            padding-bottom: 30px;
            padding-top: 30px;
            h3 {
                margin-top: 30px;
                margin-bottom: 15px;
            }
            p {
                margin-bottom: 15px;
            }
            .img-wrap {
                width: 100%;
                height: 260px;
                position: relative;
                @include media-breakpoint-down(sm) {
                    margin-bottom: 30px;
                }
                .divImg {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
                a {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    text-align: center;
                    top: 50%;
                    display: flex;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    justify-content: center;
                    align-items: center;
                    &:before {
                        content: "";
                        background-image: url('../img/video-play.png');
                        background-position: center center;
                        background-repeat: no-repeat;
                        height: 60px;
                        width: 60px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        background-size: 60px;
                        display: block;
                        transform: translate(-50%, -50%);
                        z-index: 1;
                    }
                    &:hover {
                        &:before {
                            content: "";
                            background-image: url('../img/video-play-hover.png');
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: 60px;
                        }
                    }
                }
            }
        }
    }

    // FP Slide
    .full-height-slide {
        background: #000000;
        color: $white-color;
        padding: 60px 0px 0px;
        position: relative;

        .bg-img {
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            width: 100%;
            z-index: 0;
        }

        .slide-img {
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(100%);
            transition: transform 1.2s ease;
            height: 100%;
            width: 43.839%;
            z-index: 1;

            @include media-breakpoint-down(md) {
                bottom: 0px;
                margin: auto;
                height: 55%;
                width: 50%;
            }

            img {
                display: block;
                transition: transform 1.2s ease;
                transform: translateX(-100%);
                height: 100%;
                width: 100%;
            }
        }

        h4 {
            color: $white-color;
            display: block;
            margin-bottom: 20px;
            @include opacity(0.6);
        }

        h2 {
            margin-bottom: 28px;
            @include opacity(0.96);
        }

        .btn {
            color: $white-color;

            svg path {
                stroke: $white-color;
            }

            &:hover {
                color: $lighter-teal;

                svg path {
                    stroke: $lighter-teal;
                }
            }
        }

        &.active {
            .slide-img {
                transform: translateX(0%);

                img {
                    transform: translateX(0%);
                }
            }

            .for-fadeup {
                animation-name: fadeInUpSD;
                animation-duration: 2s;
                opacity: 1;
            }
        }
    }

    // Slide Bullet
    #fp-nav {
        left: 0px;
        right: auto;

        ul {
            li {
                display: block;
                padding: 8px 0px;
                margin: 0px;
                position: relative;
                transition: 750ms all ease;
                height: auto;
                width: 44px;

                &::before {
                    content: normal;
                }

                &:nth-child(0),
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(9),
                &:nth-child(10) {
                    display: none;
                }

                a {
                    span {
                        background: transparent;
                        height: auto;
                        width: auto;
                    }

                    &:before {
                        background: $fern-green;
                        content: '';
                        cursor: pointer;
                        display: block;
                        margin: auto;
                        position: relative;
                        height: 12px;
                        width: 4px;
                        transition: background-color 750ms ease 0s, height 750ms ease 0s, width 150ms ease 0s, left 150ms ease 0s;
                    }

                    &.active {
                        &:before {
                            background: $white-color;
                            height: 32px;
                        }
                    }

                }

                .fp-tooltip.fp-right {
                    top: -6px;
                    left: 38px;
                    right: auto;
                }
            }

            &:hover {
                li a {
                    &:before {
                        width: 8px;
                    }
                }
            }
        }
    }

    // Mobile: Slider
    .mob-slide {

        .fp-slidesContainer {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;

            .full-height-slide {
                display: block;
                flex: 1 0 0%;
                padding: 0px 0px 100px;

                @include media-breakpoint-down(sm) {}

                .slide-img {
                    margin: 0px 0px 40px;
                    position: relative;
                    transform: none;
                    height: 390px;
                    width: 100%;

                    @include media-breakpoint-down(sm) {
                        height: 580px;
                    }

                    @include media-breakpoint-down(xs) {
                        height: 480px;
                    }

                    @media (max-width: 475px) {
                        height: 390px;
                    }

                    img {
                        transform: none;
                    }
                }

            }
        }


        // Slide Bullet
        .fp-slidesNav {
            bottom: 45px;
            padding: 0 10px;

            @include media-breakpoint-down(sm) {
                max-width: 540px;
            }

            @include media-breakpoint-down(xs) {
                max-width: 100%;
            }

            ul {
                li {
                    margin: 0px 5px;
                    padding: 0px;
                    position: relative;
                    transition: 750ms all ease;
                    height: auto;
                    width: auto;

                    &::before {
                        content: normal;
                    }

                    a {
                        span {
                            background: transparent;
                            height: auto;
                            width: auto;
                        }

                        &:before {
                            background: $primary-color;
                            content: '';
                            cursor: pointer;
                            display: block;
                            margin: auto;
                            position: relative;
                            width: 12px;
                            height: 4px;
                            transition: background-color 500ms ease 0s, height 150ms ease 0s, width 500ms ease 0s, left 150ms ease 0s;
                        }

                        &.active {
                            &:before {
                                background: $white-color;
                                width: 32px;
                            }
                        }

                    }
                }
            }
        }


    }

}
#fp-nav {
    ul {
        li {
            a {
                &:hover {
                    &:before {
                        background: $white-color;
                    }
                }
            }
        }
    }
}

.hero-banner {
    .hero-items {
        .hero-item {
            .container {
                @media screen and (max-width: 1360px) and (min-width: 1200px) {
                    padding-left: 70px;
                    padding-right: 70px;
                }
            }
        }
    }
}
.arrow-transition {
    margin-top: 17px;
    -webkit-animation-name: hvr-bob-float, hvr-bob;
    animation-name: hvr-bob-float, hvr-bob;
    -webkit-animation-duration: .3s, 1.5s;
    animation-duration: .3s, 1.5s;
    -webkit-animation-delay: 0s, .3s;
    animation-delay: 0s, .3s;
    -webkit-animation-timing-function: ease-out, ease-in-out;
    animation-timing-function: ease-out, ease-in-out;
    -webkit-animation-iteration-count: 1, infinite;
    animation-iteration-count: 1, infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-direction: normal, alternate;
    animation-direction: normal, alternate;
}

#videoPopup-box {
    .modal-dialog {
        max-width: 760px;
        @include media-breakpoint-down(md) {
            max-width: 90%;
            margin: 0 auto;
        }
        .modal-content {
            padding: 5px;
        }
    }
    .close {
        position: absolute;
        top: 0;
        right: -55px;
        opacity: 1;
        outline: 0;
        @include media-breakpoint-down(md) {
            right: 0;
            top: -35px;
        }
        svg {
            position: absolute;
            top: 0;
            left: 0;
        }
        span {
            display: block;
            height: 23.33px;
            width: 23.33px;
            position: relative;
        }
    }   
}
.home {
    #stickyContent {
        display: none !important;
    }
}