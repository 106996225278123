.breadcrumbs {
    @include media-breakpoint-down(md) {
        width: 100%;
        display: block;
        color: $gray;
        z-index: 2;
        left: 0;
        top: 0;
        position: relative;
        padding: 12px 15px 0 15px;
        max-width: 720px;
        margin: 0 auto 6px auto;
    }
    @include media-breakpoint-down(sm) {
        max-width: 540px;
    }
    @include media-breakpoint-down(xs) {
        max-width: 100%;
    }
    a {
        color: $gray;
        text-decoration: none;
        &:not([href]):not([tabindex]) {
            color: $gray;
        }
        + a {
            position: relative;
            padding: 0px 0 0 8px;
            margin: 0px 0 0 4px;
            &:before {
                content: "";
                height: 15px;
                background: $gray;
                left: 0;
                top: 3px;
                width: 1px;
                position: absolute;
                @media(min-width: 1919.98px) {
                    height: 20px;
                }
            }
        }
    }
}
.cmn-full-height-banner {
    .breadcrumbs {
        a {
            color: $white-color;
        }
    }
}
.dark-banner-header {
    header {
        .navbar.navbar-expand-lg {
            #stickyContent {
                .breadcrumbs {
                    color: $white-color;
                    a {
                        color: $white-color;
                        &:not([href]):not([tabindex]) {
                            color: $white-color;
                        }
                        + a {
                            &:before {
                                background: $white-color;
                            }
                        }
                    }
                }
            }
        }
        &.scroll-fix {
            .navbar.navbar-expand-lg {
                #stickyContent {
                    .breadcrumbs {
                        color: $gray;
                        a {
                            color: $gray;
                            &:not([href]):not([tabindex]) {
                                color: $gray;
                            }
                            &:before {
                                background: $gray;
                            }
                        }
                    }
                }
            }
        }
    }
    &.about-page {
        header {
            .navbar.navbar-expand-lg {
                .navbar-brand {
                    svg *, svg path {
                        @include media-breakpoint-down(md) {
                            fill: #3E2268;
                        }
                        
                    }
                }
    
                .right-nav {
                    svg *, svg path {
                        @include media-breakpoint-down(md) {
                            fill: #36373B;
                        }
                    }
    
                    .btn-search {
                        svg path {
                            @include media-breakpoint-down(md) {
                                stroke: #36373B;
                            }
                        }
                    }
                }
            }
            &.scroll-fix {
                .navbar.navbar-expand-lg {
                    .navbar-brand {
                        svg *, svg path {
                            @include media-breakpoint-down(md) {
                                fill: #3E2268;
                            }
                        }
                    }
                    .right-nav {
                        .btn-search {
                            svg path {
                                @include media-breakpoint-down(md) {
                                    stroke: #36373B;
                                }
                            }
                        }
                    }
                }   
            }
        }
    }
    .breadcrumbs {
        a {
            @include media-breakpoint-down(md) {
                color: $white-color;
            }
            &:not([href]):not([tabindex]) {
                @include media-breakpoint-down(md) {
                    color: $white-color;
                }
            }
        }
    }
    .cmn-full-height-banner {
        .breadcrumbs {
            a {
                + a {
                    &:before {
                        background: $white-color;
                    }
                }
            }
        }
    }
}
