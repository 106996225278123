.probono-commitment {
    padding-bottom: 40px;
    padding-top: 70px;
    @include media-breakpoint-down(md) {
        padding-top: 0;
    }
    .cmn-box-img-bg {
        padding-bottom: 70px;
        padding-top: 70px;
        @include media-breakpoint-down(md) {
            padding-top: 30px;
        }
    }
    .probono-commitment-img {
        img {
            width: 100%;
            height: auto;
        }
    }
    .probono-what-we-do {
        padding-bottom: 50px;
        .probono-what-we-do-data {
            p {
                color: $gray;
            }
        }
        .probono-what-we-do-image {
            position: relative;
            img {
                width: 100%;
                height: auto;
            }
            .box-link {
                position: absolute;
                bottom: 0;
                background: rgba(62, 34, 104, 0.9);
                left: 0;
                text-decoration: none;
                padding: 35px 38px 35px 35px;
                @include media-breakpoint-down(md) {
                    padding: 20px;
                }
                span {
                    display: block;
                    img {
                        height: auto;
                        width: 21px;
                        margin: 0 0 0 10px;
                    }
                }
                .text-18 {
                    line-height: 27px;
                    color: rgba(255, 255, 255, 0.68);
                    letter-spacing: 2px;
                    text-transform: uppercase;
                }
                .h2 {
                    color: $white-color;
                    margin: 0;
                    letter-spacing: normal;
                }
            }
        }
    }
}
.informative-data {
    &.professional-list {
        padding: 0;
        margin: 0;
        .quote {
            padding-bottom: 74px;
            p ,
            h6 ,
            .quote-avatar{
                position: relative;
                z-index: 2;
            }
            h6 {
                color: #86828C;
                margin-bottom: 25px;
            }
            p {
                margin-bottom: 16px;
            }
            .quote-avatar {
                img {
                    border-radius: 100%;
                }
            }
        }
    }
}
.probono-what-we-do {
    .text-div {
        .img-text {
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.outreach-section {
    &.cmn-tab.social-diversity {
        padding-top: 42px;
    }
    .img-text-loop {
        .text-div {
            .img-text {
                p {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
.quote-avatar {
    display: flex;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    align-content: baseline;
    background: $cultured;
    margin: 0 auto;
    overflow: hidden;
    align-items: self-end;
    img {
        height: auto;
        max-height: 60px;
    }
}