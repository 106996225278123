.business-professionals-content {
    padding: 60px 0;

    @include media-breakpoint-down(md) {
        padding: 40px 0 0;
    }

    .tabbing-data {
        .filter-tabbing {
            .filter {
                margin-bottom: 50px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 15px;
                }
            }
        }
    }

    .intro-section {
        padding: 50px 0 70px 0;

        @include media-breakpoint-down(md) {
            padding: 40px 0;
        }

        .container {
            .col-left {
                width: 40%;

                @include media-breakpoint-down(lg) {
                    width: 35%;
                }

                @include media-breakpoint-down(md) {
                    width: 100%;

                    h2 {
                        margin-bottom: 4px !important;
                    }
                }
            }

            .col-strip {
                width: 100px;
            }

            .col-right {
                width: 50%;

                @include media-breakpoint-down(md) {
                    width: 100%;
                }

                .text-18 {
                    margin-bottom: 24px;
                }
                .text-14 {
                    font-family: $font-bold;
                }
            }
        }
    }

    .img-text-section {
        .img-text-loop {
            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }

        .btn-primary-link {
            font-family: $font-regular;
        }

    }

    .inclusive-leadership {
        padding: 70px 0;

        @include media-breakpoint-down(md) {
            padding: 40px 0 15px;
        }

        .container {
            padding-top: 0px;
            padding-bottom: 0px;

            .col-left {
                span {
                    color: $gray;
                }

                @include media-breakpoint-down(md) {
                    padding-bottom: 20px;
                }
            }

            .col-strip {
                @include media-breakpoint-down(md) {
                    display: none;
                }

                i {
                    top: 0;
                    bottom: 0;
                }
            }

            .col-right {
                ul {
                    margin: 13px 0;
                    ;
                    border-top: 1px solid rgba(54, 50, 59, 0.15);
                    display: -webkit-flex;
                    display: flex;
                    -webkit-flex-wrap: wrap;
                    flex-wrap: wrap;
                    width: 100%;

                    li {
                        padding: 23px 16px;
                        margin: 0;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        border-bottom: 1px solid rgba(54, 50, 59, 0.15);

                        @include media-breakpoint-down(md) {
                            padding: 15px 0;
                        }

                        &:before {
                            content: none;
                        }

                        h3 {
                            margin: 0 16px 0 0;
                            color: $spanish-orange;
                            font-family: $font-medium;
                        }
                    }
                }

                .btn-primary-link {
                    font-family: $font-regular;
                }
            }
        }
    }

    .social-share {
        padding-bottom: 90px;

        @include media-breakpoint-down(md) {
            padding-top: 30px;
            padding-bottom: 80px;
        }
    }

    .overlapping-images {
        .img-text-loop {
            @include media-breakpoint-down(md) {
                padding: 40px 0;
            }
        }

        .text-div {
            .btn-primary-link {
                font-family: $font-regular;
            }
        }
    }

    .quote-wrap {
        padding: 70px 0 40px;

        @include media-breakpoint-down(md) {
            padding-top: 40px;
        }

        .professional-list {
            margin-bottom: 30px;

            @include media-breakpoint-down(md) {
                margin-bottom: 0;
            }

            .quote {
                .divImg {
                    background-position: center center;
                }

                h6 {
                    font-family: $font-bold;
                }
            }
        }
    }

    .get-to-know-title {
        padding: 35px 0 25px;

        @include media-breakpoint-down(md) {
            padding-bottom: 0;
        }

        h2 {
            color: $rich-black;
            margin-bottom: 80px;
        }
    }

    .business-discover-tab {
        .img-text-loop {

            p {
                a {
                    text-decoration: underline;
                    color: $rocket-metallic;
                    padding: 0;
                    &:hover {
                        color: $rich-black;
                    }
                }
            }
            .btn-primary-link {
                text-decoration: none;
                color: $gray;
                padding: 0;
                &:hover {
                    color: $gray;
                }
            }
        }

        .tabbing-data {
            padding: 70px 0 50px;

            @include media-breakpoint-down(md) {
                padding: 40px 0 20px;
            }

            h4 {
                margin-bottom: 28px;
            }
        }

        .professional-list {
            padding: 70px 0 20px;

            @include media-breakpoint-down(md) {
                padding: 40px 0 0;
            }

            [class*="col-"] {
                @include media-breakpoint-down(sm) {
                    margin-bottom: 40px;
                }

                .team {
                    .contact-info {
                        @include media-breakpoint-down(sm) {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                        }

                        a {
                            @include media-breakpoint-down(sm) {
                                font-size: 0;
                                padding: 0;
                                width: 20px;
                                margin-right: 16px;
                                margin-bottom: 0;
                                height: 20px;
                            }

                            span {
                                @include media-breakpoint-down(sm) {
                                    height: 20px;
                                    width: 20px;
                                }

                                img {
                                    @include media-breakpoint-down(sm) {
                                        height: 20px;
                                        width: 20px;
                                        max-height: 20px;
                                        max-width: 20px;
                                    }
                                }
                            }
                        }
                    }

                    .team-img {
                        @include media-breakpoint-down(xs) {
                            height: 142px;
                        }

                        img {
                            @include media-breakpoint-down(xs) {
                                max-height: 142px;
                            }
                        }
                    }
                }
            }
        }
    }
    .tabbing-data {
        .filter-tabbing {
            .filter-data {
                display: block;
            }
        }
    }
}

.current-positions-wrap {
    padding: 50px 0 0;

    @include media-breakpoint-down(md) {
        padding-top: 40px;
    }

    h3 {
        margin-bottom: 31px;
    }

    .cmn-select {
        max-width: 350px;
        margin-bottom: 37px;
    }

    .row {
        border-bottom: 1px solid $gainsboro;
        padding-top: 47px;
        padding-bottom: 47px;
        position: relative;

        &:nth-child(3) {
            border-top: 1px solid $gainsboro;
        }

        h4 {
            margin-bottom: 5px;
        }

        p {
            color: $gray;
        }

        span {
            margin-bottom: 28px;
        }

    }

    .loader-wrap {
        padding: 80px 0 20px;

        @include media-breakpoint-down(md) {
            padding: 40px 0;
        }

        img {
            -webkit-animation: rotation 2s infinite linear;
            animation: rotation 2s infinite linear;
        }

        @keyframes rotation {
            from {
                transform: rotate(0deg);
            }

            to {
                transform: rotate(359deg);
            }
        }

        @-webkit-keyframes rotation {
            from {
                -webkit-transform: rotate(0deg);
            }

            to {
                -webkit-transform: rotate(359deg);
            }
        }


    }
}
.tabbing-data {
    .filter-tabbing {
        .filter {
            .btn-primary-link {
                padding: 0;
                color: $gray;
                font-size: 40px;
                line-height: 50px;
                color: $gray-base;
                &:hover {
                    color: $gray
                }
            }
        }
    }
}
.law-student-discover {
    .tabbing-data {
        &.compensation-benefits {
            padding-bottom: 0;
            .filter-tabbing {
                .filter {
                    margin-bottom: 35px;
                }
            }
        }
    }
}