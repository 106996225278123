.cmn-career-section {
    padding: 180px 0 150px 0;
    position: relative;
    z-index: 2;
    background: $white-color;
    clear: both;
    @include media-breakpoint-down(md){
        padding: 80px 0 65px 0;
    }

    [class*="col-"] {
        margin-bottom: 30px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 16px;
        }
        @include media-breakpoint-only(md) {
            &:nth-child(1) {
                animation-delay: 0.2s !important;
            }
            &:nth-child(2) {
                animation-delay: 0.2s !important;
            }
            &:nth-child(3) {
                animation-delay: 0.4s !important;
            }
            &:nth-child(4) {
                animation-delay: 0.2s !important;
            }
            &:nth-child(5) {
                animation-delay: 0.4s !important;
            }
        }
        @include media-breakpoint-down(sm) {
            animation-delay: 0.2s !important;
        }

        h2 {
            margin-bottom: 36px;
            @include media-breakpoint-down(sm){
                margin-bottom: 10px;
            }
        }
        .btn-primary-link{
            transform: unset !important;
        }

        .cmn-social-inner {
            border: 1px solid transparent;
            background-color: $white-color;
            padding: 35px;
            min-height: 385px;
            @include transition(0.5s);
            height: 100%;
            position: relative;
            overflow: hidden;
            @include media-breakpoint-down(sm){
                min-height: 272px;
            }


            .divImg {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                height: 100%;
                width: 100%;
                background-position: 50%;
                @include transition(2.5s);
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
            }

            .cmn-social-content {

                p {
                    margin-bottom: 24px;
                }

                &.text-16 {
                    color: $white-color;
                }
            }


            &.bg-white {
                border: 1px solid $gainsboro;

            }

            &.bg-green {
                background: $secondary-color;
                color: $white-color;

                a {
                    color: $white-color;

                    &.btn-primary-link {
                        background: none;
                        svg {
                            * {
                                stroke: #ffffff;
                            }
                        }
                    }

                }
            }

            &.img-wrap {
                border: 0;

                @include media-breakpoint-down(sm) {
                    min-height: 360px;
                    padding: 22px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    height: 192px;
                    left: 0;
                    right: 0;
                    top: auto;
                    z-index: 0;
                    background: rgb(0, 0, 0);
                    transform: translateY(100%);
                    @include transition(0.5s);
                    background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                    background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
                }

                .stretched-link {
                    &:after {
                        z-index: 2;
                    }
                }

                .cmn-social-content {
                    transform: translateY(100%);
                    @include transition(0.5s);
                    position: relative;
                    z-index: 1;
                    height: 100%;

                    span {
                        display: block;
                        margin-bottom: 16px;

                        @include media-breakpoint-down(sm) {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            &:hover {

                &.img-wrap {
                    background: transparent;

                    &:after {
                        transform: translateY(0%)
                    }

                    .cmn-social-content {
                        transform: translateY(0%);
                    }
                }
            }
        }

        &.col-lg-8 {
            .cmn-social-inner {
                padding: 0;
                border: 0;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
                overflow: visible;
                @include media-breakpoint-down(md){
                    min-height: 320px;
                }

                .bg-wrap {
                    margin-top: -37px;
                    width: 100%;
                    max-height: calc(385px + 37px);
                    order: 3;
                    overflow: hidden;
                    position: relative;

                    @include media-breakpoint-down(sm) {
                        margin-top:0;
                    }

                    .divImg {
                        background-position: top center;
                    }
                }

                .sticker {
                    position: absolute;
                    height: 215px;
                    width: 215px;
                    z-index: 1;
                    background-color: $fern-green;
                    border-radius: 100%;
                    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.5);
                    bottom: -18px;
                    left: 11px;
                    color: $white-color;
                    -webkit-display: flex;
                    display: flex;
                    -webkit-align-items: center;
                    align-items: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    padding: 25px;
                    text-align: center;
                    @include media-breakpoint-down(sm) {
                        bottom: -40px;
                        left: 19px;
                    }

                    h4 {
                        margin-bottom: 8px;
                    }

                    small {
                        color: $white-color;
                        text-transform: uppercase;
                        font-size: 12px;
                        letter-spacing: 1px;
                        line-height: 20px;
                    }
                }

                .cmn-social-content {
                    padding: 60px 40px 20px 40px;
                    width: 50%;
                    background-color: $primary-color;

                    @include media-breakpoint-down(lg) {
                        padding: 25px;
                    }

                    @include media-breakpoint-down(md) {
                        padding: 50px 40px;
                    }

                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        order: 1;
                        height: auto;
                        min-height: 217px;
                    }

                    a {
                        span {
                            position: relative;
                            z-index: 2;
                            display: block;
                            margin: 0 0 16px 0;
                        }
                    }
                }

                &:hover {
                    background-color: $white-color;
                }
            }
        }

        &[style="visibility: visible; animation-duration: 1s; animation-delay: 0.2s; animation-name: custom-fadeInUp;"],
        &[style="visibility: visible; animation-duration: 1s; animation-delay: 0.4s; animation-name: custom-fadeInUp;"],
        &[style="visibility: visible; animation-duration: 1s; animation-delay: 0.6s; animation-name: custom-fadeInUp;"] ,
        &[style="visibility: visible; animation-duration: 1s; animation-delay: 0.2s;"]{
            .cmn-social-inner {
                .divImg {
                    transition-delay: 0.4s;
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }
            }
        }
    }
}

.cmn-career-section {
    [class*="col-"] {
        .cmn-social-inner {
            &.img-wrap {
                &:after {
                    @include media-breakpoint-down(md) {
                        transform: translateY(0%);
                    }
                }
                .cmn-social-content  {
                    @include media-breakpoint-down(md) {
                        transform: translateY(0%);
                    }
                }
            }
        }
    }
}