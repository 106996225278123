@media(min-width: 1200px) and (max-width: 1919.98px) {
  .container {
    max-width: 1200px;
  }
}
@include media-breakpoint-down(xs) {
  .container {
    width: 100%;
  }
}

body {
  @include body-font;
}

.page-wrapper {
  display: block;
  overflow: hidden;
  width: 100%;
}

main {
  float: left;
  min-height: 600px;
  padding: 0px;
  width: 100%;
  @include media-breakpoint-down(lg) {
    min-height: 450px;
  }
  @include media-breakpoint-down(md) {
    min-height: 350px;
  }
}

section {
  float: left;
  padding: 70px 0px;
  width: 100%;
}

img {
  display: inline-block;
  max-width: 100%;
}

.divImg {
  @include bg-cmn;
}

p {
  margin-bottom: 30px;
}

a {
  @include transition(0.35s);
  color: $rocket-metallic;
  text-decoration: underline;
  &:hover {
    color: $cyber-grape;
  }
}
/*** Heading ***/
h1,
.h1 {
  @include font-xl;
}

h2,
.h2 {
  @include font-lg;
}

h3,
.h3 {
  @include font-md;
}

h4,
.h4 {
  @include font-sm;
}
h5,
.h5 {
  @include font-xs;
}
h6,
.h6 {
  @include font-xxs;
}
.text-18 {
  @include text-18;
}
.text-16 {
  @include text-16;
}
.text-14 {
  @include text-14;
}
.link-18 {
  @include link-18;
  text-decoration: none;
}
.link-16 {
  @include link-16;
  text-decoration: none;
}
.link-14 {
  @include link-14;
  text-decoration: none;
}
b,
strong {
  font-weight: normal;
  font-style: normal;
  font-family: $font-bold;
}
/*** Listing ***/

// Listing Style
ul {
  margin: 0px 0px 35px;
  padding: 0;

  li {
    list-style: none;
    margin: 0px 0px 19px;
    padding: 0px 0px 0px 25px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 12px;
      height: 6px;
      width: 6px;
      border-radius: 0;
      background: $secondary-color;
    }
  }

  ol {
    margin: 8px 0px 0px;
  }

  ul {
    margin: 8px 0px 0px;

    li {
      margin: 0px 0px 3px;
    }
  }
}

ul.list-unstyled {
  li {
    margin: 0px 0px 13px 0px;
    padding: 0px;
    position: relative;

    &:before {
      content: normal;
    }
  }
}

ol {
  margin: 0px 0px 35px;
  padding: 0px;
  list-style: none;
  counter-reset: av-counter;

  li {
    counter-increment: av-counter;
    @include body-font;
    margin: 0px 0px 12px;
    padding: 0px 0px 0px 30px;
    position: relative;

    &:before {
      // content: counters(av-counter, ".") " ";
      background: transparent;
      display: inline-block;
      font-size: inherit;
      line-height: inherit;
      height: auto;
      width: auto;
      content: counter(av-counter) ".";
      counter-increment: none;
      font-weight: 700;
      color: $body-color;
      font-weight: normal;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }

  ol {
    margin: 8px 0px 0px;

    li {
      margin: 0px;
    }
  }

  ul {
    counter-reset: av-counter;
    margin: 8px 0px 0px;

    li {
      margin: 0px;

      &:before {
        background: $secondary-color;
        content: "";
        top: 12px;
        height: 6px;
        width: 6px;
      }
    }
  }
}
ul ol li {
  margin-bottom: 0;
}

//btn
.btn {
  background: $rocket-metallic;
  border: 2px solid transparent;
  font-family: $font-medium;
  color: $white-color;
  text-align: center;
  text-decoration: none;
  outline: none;
  min-width: 161px;
  line-height: 24px;
  font-size: 16px;
  letter-spacing: normal;
  text-transform: inherit;
  padding: 12px 10px;
  @include transition(0.3s);
  @include border-radius(0px);
  box-shadow: none;

  &.btn-primary {
    color: $white-color;
    position: relative;
    border: 2px solid transparent;
    z-index: 1;
    font-family: $font-medium;
    &:focus {
      box-shadow: none;
      outline: none;
    }
    &:hover {
      background: none;
      color: $rocket-metallic;
      border: 2px solid $rocket-metallic;
    }
    &:active,
    &:focus {
      background: $rocket-metallic;
      color: $white-color;
      border: 2px solid $rocket-metallic;
      &:hover {
        background: none;
        color: $primary-color;
        border: 2px solid $rocket-metallic;
      }
    }
    &:active {
      outline: none;
      -webkit-transform: scale(0.95);
      -moz-transform: scale(0.95);
      -ms-transform: scale(0.95);
      color: $white-color;
      background: $rocket-metallic;
      transform: scale(0.95);
    }
    &.btn-dark {
      color: $white-color;
    }
    &:not(:disabled):not(.disabled):active {
      background: none;
      color: $rocket-metallic;
      border: 2px solid $rocket-metallic;
    }
  }
  &.btn-secondary {
    border: 2px solid $rocket-metallic;
    background: transparent;
    padding: 18px 20px;
    color: $body-color;
    position: relative;
    &:focus {
      box-shadow: none;
      outline: none;
    }
    &:hover,
    &:active,
    &:focus {
      color: $white-color;
      background-color: $rocket-metallic;
    }
  }
  &:focus,
  &:hover,
  &:not(:disabled):not(.disabled):active {
    outline: none;
    box-shadow: none;
  }
  &.btn-primary-link {
    @extend .link-18;
    padding: 0 0 0 0;
    color: $gray;
    margin: 0;
    background: none;
    display: inline-block;
    letter-spacing: normal;
    min-width: 0;
    border: 0;
    @include transition(0.3s);
    &:hover {
      @include transition(0.3s);
      padding: 0;
      color: $rocket-metallic;
      svg {
        right: -14px;
        @include transition(0.3s);
      }
    }
    &:active {
      outline: none;
      -webkit-transform: scale(0.95);
      -moz-transform: scale(0.95);
      -ms-transform: scale(0.95);
      transform: scale(0.95);
    }
    svg {
      height: 26px !important;
      position: relative;
      right: -7px;
      @include transition(0.3s);
      width: 26px !important;
      * {
        stroke: #86828C;
      }
    }
  }
  &.btn-secondary-link {
    @extend .link-14;
    padding: 0 0 0 30px;
    color: $rocket-metallic;
    margin: 0;
    position: relative;
    display: inline-block;
    letter-spacing: normal;
    background: none;
    min-width: 0;
    border: 0;
    &:before {
      background: url('../img/secondary-plus-gray.svg') no-repeat left center;
      content: none;
      position: absolute;
      left: -2px;
      top: -2px;
      height: 24px;
      @include transition(0.3s);
      width: 24px;
    }
    &:hover {
      &:before {
        @include transition(0.3s);
      }
    }
    svg {
      position: absolute;
      left: 0;
      height: 20px !important;
      width: 20px !important;
      * {
        stroke: $rocket-metallic;
      }
    }
  }
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  border: none;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: $white-color;
  background: $primary-color;
  border-color: $primary-color;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

// inputs
.form-group {
  position: relative;
  label {
    @extend .text-18;
    margin: 0;
    color: rgba(14, 14, 14, 0.5);
    position: absolute;
    font-family: $font-regular;
    top: 19px;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    left: 0;
  }
  input,
  textarea {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    @extend .text-18;
    font-family: $font-regular;
    padding: 20px 10px 10px 0;
    &:focus {
      + label {
        font-size: 12px;
        line-height: 16px;
        color: rgba(54, 50, 59, 0.5);
        letter-spacing: normal;
        font-family: $font-regular;
        transform: translate3d(0, 3px, 0);
        left: 0;
        top: 1px;
      }
    }
  }
}
.form-group input[data-empty="false"] + label,
.form-group input:focus + label,
.form-group select[data-empty="false"] + label,
.form-group select:focus + label,
.form-group textarea[data-empty="false"] + label,
.form-group textarea:focus + label,
.input-focus label,
.input-focus textarea,
.input-focus label {
  font-size: 12px;
  line-height: 16px;
  color: rgba(54, 50, 59, 0.5);
  letter-spacing: normal;
  transform: translate3d(0, 3px, 0);
  left: 0;
  top: 1px;
}
.search-box {
  &:before {
    background: url(../img/search-icon.svg) no-repeat top left;
    height: 16px;
    width: 16px;
    position: absolute;
    left: 0px;
    top: 26px;
    content: "";
    @media(min-width: 1919.98px) {
      top: 30px;
    }
    @include media-breakpoint-down(sm) {
      top: 25px;
    }
  }
  label {
    left: 28px;
    pointer-events: none;
  }
  input {
    padding-left: 28px;
    &:focus {
      + label {
        font-size: 12px;
        line-height: 16px;
        color: rgba(54, 50, 59, 0.5);
        letter-spacing: normal;
        font-family: $font-regular;
        transform: translate3d(0, 3px, 0);
        left: 28px;
        top: 1px;
      }
    }
  }
}
.search-box input[data-empty="false"] + label,
.search-box input:focus + label {
  left: 28px;
}

//seect2
.select2-container {
  .select2-selection {
    @include border-radius(0px);
    color: $body-color;
    font-size: 16px;
    height: 42px;
    padding: 0;
    width: 100%;
    background: $input-bg;
    font-family: $font-regular;
    line-height: 42px;
    outline: 0;
    border: 0;

    .select2-selection__rendered {
      padding: 0px 40px 4px 0px;
      // font-size: 18px;
      // line-height: 30px;
      @extend .text-18;
      color: $body-color;
      letter-spacing: normal;
      border: solid $secondary-color;
      border-width: 0 0 1px;

      .select2-selection__placeholder {
        // font-size: 18px;
        // line-height: 30px;
        @extend .text-18;
        color: rgba(54, 50, 59, 0.5);
        letter-spacing: 0px;
        // @include opacity(0.6);
      }
    }

    .select2-selection__arrow {
      height: 100%;
      width: 30px;
      right: 0px;
      top: -6px;

      b {
        background: url("../img/icon-input-dropdown-purple.svg") no-repeat center;
        border: 0;
        background-size: 10px;
        width: 30px;
        display: block;
        top: 0;
        bottom: 0;
        margin: 0 auto;
        left: 0;
        right: 0px;
        height: 100%;
        @include transition(0.3s);
      }
    }
  }

  .select2-dropdown {
    border: 0;
    @include border-radius(0);
    padding-bottom: 0px;
    z-index: 1030;
    box-shadow: none;

    .select2-search__field {
      outline: none;
      border-color: $input-border-color;
      margin-bottom: 4px;
      padding: 5px 10px;
      @include border-radius(0);
      font-size: 16px;
      letter-spacing: 0.09px;
    }

    .select2-results {
      ul {
        border: 1px solid #dfdfdf;
        box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.1);
        margin-top: 0;
        padding: 10px 0;
        li {
          color: $rich-black;
          // font-size: 14px;
          // line-height: 20px;
          @extend .text-14;
          font-weight: normal;
          padding: 10px 21px;
          font-family: $font-regular;
          margin: 0;
          background: none;
          border-bottom: 0px solid #D8D8D8;
          &:last-child {
            border-bottom: none;
          }

          &:before {
            content: normal;
          }

          &.select2-results__option--highlighted {
            color: $cyber-grape;
          }

          &:hover {
            color: $cyber-grape;
            background-color: transparent;
          }
        }

        .mCSB_inside {
          .mCSB_container {
            margin-right: 0px;

            &.mCS_y_hidden {
              margin-right: 0;
            }
          }
        }

        .mCSB_scrollTools {
          top: 5px;
          bottom: 5px;
          right: 8px;
          background-color: #f1f1f1;

          .mCSB_dragger_bar {
            background: $gray-base;
            border-radius: 0;
          }
        }
      }
    }
  }

  &.select2-container--open {
    .select2-selection {
      .select2-selection__arrow {
        b {
          transform: rotate(180deg);
        }
      }
    }
  }

  &.select2-container--below {
    .select2-selection__rendered {
      color: $input-color;
    }
  }

  &.select2-container--open {
    .select2-selection {
      background: $white-color;
    }
  }
  &.select2-container--focus {
    .select2-selection__rendered {
      background: $white-color;
    }
  }
  &.select2-container--disabled {
    .select2-selection {
      background-color: rgba(15, 33, 43, 0.08);
      .select2-selection__rendered {
        background: inherit;
        span {
          opacity: 0.5;
        }
      }
      .select2-selection__arrow {
        opacity: 0.2;
        background: $secondary-color;
      }
    }
  }
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 360px;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background: none;
}

.mCSB_scrollTools {
  width: 4px;
  opacity: 1;

  .mCSB_draggerRail {
    background: transparent;
  }
}

.select2-container .select2-dropdown .select2-results ul .mCSB_scrollTools .mCSB_dragger_bar {
  background-color: $gray;
  margin: 0;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: transparent;
}

.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
}
blockquote {
  padding: 60px 33px;
  border: solid #dfdfdf;
  border-width: 1px 0;
  @include font-md;
  margin-bottom: 60px;
  @include media-breakpoint-down(md) {
    padding: 30px 25px;
    margin-bottom: 40px;
  }
}

//pagination
.pagination {
  display: flex;
  align-items: center;
  padding-top: 0px;
  font-family: $font-regular;
  &.justify-content-start{
    padding-top: 40px;
  }
  .page-numbers {
    margin: 0 8px;
    font-size: 16px;
    line-height: 26px;
    text-decoration: none;
    padding: 0 8px;
    color: $body-color;
    letter-spacing: normal;
    @include media-breakpoint-down(xs) {
      margin: 0 3px;
    }
    &:hover,
    &.current {
      opacity: 0.5;
    }
    &.next {
      color: $primary-color;
      padding: 0;
      background: url(../images/pagination.svg) no-repeat left center;
      font-size: 0;
      transform: rotate(180deg);
      position: relative;
      top: 0px;
      width: 20px;
      margin: 0;
      display: inline-block;
      height: 20px;
      vertical-align: middle;
      background-size: 10px;
      &:hover{
        opacity: 1;
      }
    }
    &.prev {
      color: $primary-color;
      padding: 0;
      background: url(../images/pagination.svg) no-repeat left center;
      font-size: 0;
      width: 20px;
      margin: -1px 0 0;
      display: inline-block;
      height: 20px;
      vertical-align: middle;
      background-size: 10px;
      &:hover{
        opacity: 1;
      }
    }
  }
}
.wow {
  visibility: hidden;
}

@-webkit-keyframes custom-fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 48px, 0);
    transform: translate3d(0, 48px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes custom-fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 48px, 0);
    -ms-transform: translate3d(0, 48px, 0);
    transform: translate3d(0, 48px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.customfadeInUp {
  -webkit-animation-name: custom-fadeInUp;
  animation-name: custom-fadeInUp;
}

@-webkit-keyframes custom-fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -48px, 0);
    transform: translate3d(0, -48px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes custom-fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -48px, 0);
    -ms-transform: translate3d(0, -48px, 0);
    transform: translate3d(0, -48px, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.custom-fadeInDown {
  -webkit-animation-name: custom-fadeInDown;
  animation-name: custom-fadeInDown;
}

@-webkit-keyframes custom-fadeInLeft {
  0% {
      opacity: 0;
      -webkit-transform: translate3d(48px, 0, 0);
      transform: translate3d(-100%, 0, 0)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }
}

@keyframes custom-fadeInLeft {
  0% {
      opacity: 0;
      -webkit-transform: translate3d(-48px, 0, 0);
      transform: translate3d(-100%, 0, 0)
  }

  100% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }
}

.custom-fadeInLeft {
  -webkit-animation-name: custom-fadeInLeft;
  animation-name: custom-fadeInLeft
}


@-webkit-keyframes fadeZoom {
  0% {
  opacity: 0;
  transform: scale3d(1.05,1.05,1)}
to {
  opacity: 1;
  transform: none}
}
@keyframes fadeZoom {
  0% {
  opacity: 0;
  transform: scale3d(1.05,1.05,1)}
to {
  opacity: 1;
  transform: none}
}

/* Scroll Arrow */
#scroll-down-arrow {
  width: 40px;
  height: 40px;
  svg {
      height: 100% !important;
      width: 100% !important;
  }
}
.cmn-quote-box{
  min-height: 302px;
  position: relative;
  padding: 40px 40px 72px;
  text-align: center;
  height: 100%;
  color: $rich-black;
  
  @media screen and (min-width:1920px) {
      min-height: 410px;
      padding: 70px;
  }

  @include media-breakpoint-only(lg) {
      padding: 20px;
  }
  @include media-breakpoint-down(md) {
      padding: 40px 26px !important;
  }
  
  .divImg {
      background-color: $white-color;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      z-index: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      -webkit-filter: grayscale(100%);
      /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
  }
  span{
    position: relative;
    z-index: 1;
    margin-bottom: 16px;
  }
  &:after {
      content: "";
      background-color: rgba(255, 255, 255, 0.92%);
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      z-index: 0;
      bottom: 0;
      right: 0;
      height: 100%;
  }

  .img-fluid {
      z-index: 2;
      position: relative;
      max-width: 24px;
      margin: 0px auto 20px;
      display: block;
  }

  h6 {
      z-index: 2;
      font-family: $font-bold;
      position: relative;
      margin-bottom: 24px;
      color: $gray;
  }
  .quote-avatar {
    position: relative;
    z-index: 1;
    img {
        border-radius: 100%;
        max-width: 60px;
        display: block;
        height: auto;
        margin: 0px auto;
    }
}

  .text-14 {
      z-index: 2;
      position: relative;
      color: $gray;
  }
}
/* Model */
.content-popup{
  .modal-lg {
    margin-top: 170px;
    @include media-breakpoint-up(lg){
      max-width: 768px;
    }
    @include media-breakpoint-down(sm){
      margin-top: 80px;
    }
  }
  .modal-content{
    border: 0;
    border-radius: 0;
    padding: 70px 70px 68px;
    @include media-breakpoint-down(md){
      padding: 40px;
    }
    @include media-breakpoint-down(xs){
      padding: 30px;
    }
  }
  .close {
    position: absolute;
    top: 0;
    right: -55px;
    opacity: 1;
    outline: 0;
    @include media-breakpoint-down(md){
      right: -35px;
    }
    @include media-breakpoint-down(xs){
      right: 0;
      top: -35px;
    }
    span{
      display: block;
      height: 23.33px;
      width: 23.33px;
      position: relative;
      img{
        height: 23.33px;
        width: 23.33px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      svg{
        height: 23.33px;
        width: 23.33px;
      }
    }
    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.modal-backdrop.show {
  opacity: 0.6;
}
.banner-fix {
  .fp-tableCell {
    vertical-align: top;
  }
}
.filter-reset {
  padding-top: 7px;
  a {
    color: $gray;
    text-decoration: underline;
    &:hover {
      color: $cyber-grape;
    }
  }
}

/* Bob */
@-webkit-keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
  }
  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
  }
}
@keyframes hvr-bob {
  0% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
  }
  50% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
  }
}
@-webkit-keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
  }
}
@keyframes hvr-bob-float {
  100% {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }
}

body {
  .acsb-trigger {
    opacity: 0.6 !important;
  }
}

.fluid-cta {
  &.btn-primary-link {
    @include transition(0.3s);
    @include font-md;
    text-align: left;
    color: #000000;
    &:hover {
      @include transition(0.3s);
      color: $rocket-metallic;
    }
  }
}