.cmn-bottom-nav-career {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: flex-end;
    width: 100%;
    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
    }
    .nav-blocks {
        width: 50%;
        background-color: rgba(0,0,0,0.5);
        position: relative;
        border-top: 1px solid rgba(134, 130, 140, 0.6);
        border-bottom: 1px solid rgba(134, 130, 140, 0.6);
        border-right: 1px solid rgba(134, 130, 140, 0.6);
        @include transition(0.3s);
        padding: 45px 34px 60px 45px;
        z-index: 1;
        // background-image: url(../img/arrow-white.svg);
        // background-position: right 34px center;
        // background-repeat: no-repeat;
        @include media-breakpoint-down(lg) {
            padding: 45px 55px 60px 45px;
        }
        @include media-breakpoint-down(md) {
            width: 100%;
            padding: 27px 55px 35px 15px;
            border-right: 1px solid rgba(134, 130, 140, 0.6);
            border-left: 1px solid rgba(134, 130, 140, 0.6);
            border-bottom: 0;
        }
        &:hover {
            background-color: $fern-green;
            border: 1px solid $fern-green;
            @include transition(0.3s);
        }
        &:last-child {
            border-left: 0;
            @include media-breakpoint-down(md) {
                border-left: 1px solid rgba(134, 130, 140, 0.6);
                border-bottom: 1px solid rgba(134, 130, 140, 0.6);
            }
        } 
        &:first-child {
            border-left: 1px solid rgba(134, 130, 140, 0.6);
        }
        .h3 {
            margin: 0 0 25px 0;
            display: inline-block;
            text-decoration: none;
            color: $white-color;
            @include media-breakpoint-down(md) {
                margin-bottom: 16px;
            }
        }   
        p {
            color: $white-color;
            margin: 0;
            &:last-child {
                margin: 0;
            }
        }
        .block-data {
            svg {
                height: 17px !important;
                width: 26px !important;
                right: 32px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                margin: -8px 0 0 0;
                @include media-breakpoint-down(md) {
                    right: 20px;
                }
                * {
                    stroke: $white-color;
                }
            }
        }
    }
    &.legal-professional-blocks {
        align-items: unset;
        text-align: center;
        .nav-blocks {
            background-image: none;
            @include media-breakpoint-down(md) {
                width: 100%;
                display: block !important;
            }
            &:hover {
                background: rgba(0, 0, 0, 0.5);
                border-color: rgba(134, 130, 140, 0.6);
            }
        }
        h4,
        span {
            color: $white-color;
            @include media-breakpoint-down(md) {
                display: block;
                text-align: center;
            }
        }
        span {
            color: rgba(255,255,255,0.7);
        }
    }
}