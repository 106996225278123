.contact-wrap {
    padding: 185px 0px 130px;
    @include font-xs;

    @include media-breakpoint-down(lg) { 
        padding: 140px 0px 80px;
    }
    @include media-breakpoint-down(sm) { 
        padding: 81px 0px 60px;
    }
    .form-group {
        margin: 0px 0px 22px;
    }

    .btn-primary {
        border-radius: 2px;
        min-width: 108px;
    }
    .breadcrumbs {
        @include media-breakpoint-down(sm) { 
            padding-left: 0;
        }
    }
    form {
        .btn-primary {
            margin-top: 16px;
            background: $secondary-color;
            &:active,
            &:focus {
                background: $secondary-color;
            }
            &:hover {
                color: $secondary-color;
                border-color: $secondary-color;
                background: none;
            }
            &:focus {
                color: $white-color;
                &:hover {
                    color: $secondary-color;
                    border-color: $secondary-color;
                }
            }
        }
        .form-group {
            .field-validation-error {
                line-height: 18px;
                display: block;
                span {
                    font-size: 14px;
                    line-height: 16px;
                    color: $chocolate-web;
                }
            }
        }
    }
    
}
.forbreadcrumbs {
    .breadcrumbs {
        @include media-breakpoint-down(md) {
            padding-left: 0;
        }
    }
}
.buisness {
    p {
        @extend .text-18;
    }
    .business-checkbox {
        -webkit-column-count: 2;
        column-count: 2;
        @include media-breakpoint-down(sm) {
            -webkit-column-count: 1;
            column-count: 1;
        }
    }
    label {
        @extend .text-16;
        display: inline-block;
        padding: 0 0 0 0px;
        width: 100%;
        cursor: pointer;
        padding-right: 5px;
        position: relative;
        padding-left: 30px;
        @include media-breakpoint-down(sm) {
            padding-left: 25px;
        }
        input {
            margin-right: 15px;
            position: absolute;
            left: 0;
            height: 15px;
            top: 6px;
            width: 15px;
            @include media-breakpoint-down(sm) {
                height: 13px;
                width: 13px;
            }
        }
    }
}