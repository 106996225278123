$primary-color: #467F88;
$secondary-color: #3E2268;
$white-color: #ffffff;
$light-black:#36323B;
$spanish-orange: #EA6307;
$gray:#86828C;
$yellow:#FFFD55;
$green: #3C6B72;
$gainsboro: #DFDFDF;
$cultured: #F1F1F1;
$isabelline: #F7F4F2;
$lighter-teal: #69A9B3;
$chocolate-web: #d6631e;
$fern-green: #5e7f5c;
$rich-black: #0e0e0e;
$cyber-grape: #5d4d7e;
$rocket-metallic: #79757F;


// Bootstrap color variable
$body-bg: #ffffff;
$body-color: $rich-black;
$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default;   // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee
$theme-colors: (
  "primary": $primary-color,
  "secondary": $secondary-color
);

// Link color 
$link-color:  $body-color !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

// Container 
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1600px
) !default;

// Buttons 
$btn-font-weight: normal !default;
$btn-border-radius: 4px !default;

// Forms Input 
$input-bg: #fff !default;
$input-bg-disabled: $gray-lighter !default;
$input-color: $body-color !default;
$input-border-width: 1px;
$input-border-color: $secondary-color !default;
$input-border-radius: 0px !default;
$input-focus-border-color: $secondary-color !default; 
$input-focus-color: $body-color !default; 
$input-placeholder-color: rgba(14, 14, 14, 0.5) !default;
$input-height: 61.5px !default;
$input-focus-box-shadow: transparent !default;

// form-group margin 
$form-group-margin-bottom:15px !default;