.full-height {
    padding: 0;
}
.full-height-section {
    position: relative;
    @include media-breakpoint-up(lg) {
        height: calc(100vh - 81px) !important;
    }
    @include media-breakpoint-down(md) {
        display: flex;
        padding-bottom: 70px;
        padding-top: 0;
        flex-wrap: wrap;
    }
    .bgimg {
        background-size: cover;
        background-position: center;
        @include media-breakpoint-up(lg) {
            height: 100vh;
        }
        &.divImg {
            background-color: $white-color;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            z-index: 0;
            height: 100%;
            bottom: 0;
            right: 0;
            filter: blur(10px);
            -webkit-filter: blur(10px);
        }
    }
    .heightcalc {
        @include media-breakpoint-up(lg) {
            height: 100vh;
        }
        @include media-breakpoint-down(md){
            padding-top: 51px;
            padding-bottom: 13px;
        }
    }
    h1,
    .h1 {
        color: rgba(255, 255, 255, 0.96);
        position: relative;
        margin: 0 0 40px 0;
        padding: 0 0 40px 0;
        @include media-breakpoint-down(md) {
            margin: 0 0 35px 0;
            padding: 0 0 38px 0;
        }
        &:after {
            content: "";
            background: $spanish-orange;
            height: 3px;
            width: 42px;
            bottom: 0;
            left: 0;
            position: absolute;
        }
    }
    p {
        @include media-breakpoint-up(lg) {
            margin: 0;
        }
        color: rgba(255, 255, 255, 0.96);
    }
    .volume-controls {
        position: absolute;
        bottom: 28px;
        display: none;
        z-index: 1;
        left: 30px;
        @include media-breakpoint-down(md) {
            left: 20px;
            bottom: 22px;
        }
        a {
            text-decoration: none;
            outline: none;
            margin-right: 25px;
        }
    }
}
.right-img {
    width: 43.839%;
    right: 0;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 1;
    @include media-breakpoint-down(md) {
        width: 100%;
        top: 0;
        margin-top: 0;
        height: 475px;
        position: relative;
    }
    @include media-breakpoint-down(sm) {
        height: 390px;
    }
    .divImg {
        left: auto;
        background-position: 50%;
        right: 0;
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 50%;
        top: 0;
    }
    .animate-wrap{
        width: 100%;
        position: relative;
        overflow: hidden;
        transition: transform 0.8s ease;
        transform: translateX(0);
        height: 100%;
        img ,
        .embed-responsive-item{
            display: block;
            width: 100%;
            height: 100vh;
            transition: transform 0.8s ease;
            transform: translateX(-100%);
            @include media-breakpoint-down(md) {
                opacity: 0;
            }
            @include media-breakpoint-down(sm) {
                height: 100%;
            }
        }
        &.animated {
            transform: translateX(-100%);
            right: -100%;
            img ,
            .embed-responsive-item{
                transform: translateX(0);
            }
        }
    }
    .embed-responsive {
        height: 100%;
    }
}
.vertical-bullets {
    top: 50%;
    transform: translateY(-50%);
    position: fixed;
    left: 16px;
    z-index: 11;
    background: none;
    border: 0;
    &.disabled-bullets {
        display: none !important;
    }
    &:hover {
        .inner-subnav {
            ul {
                li {
                    a {
                        &:before {
                            transition: all 0.2s ease-in-out;
                            width: 8px;
                            left: -2px;
                        }
                    }
                }
            }
        }
    }
    .inner-subnav {
        text-align: left;
        ul {
            margin: 0;
            padding: 0;
            li {
                padding: 0;
                margin: 0;
                display: block;
                &:before {
                    content: none;
                }
                &.text-visible {
                    a {
                        span {
                            transition: all 0.2s ease-in-out;
                            width: auto;
                            opacity: 1;
                            top: -1px;
                            left: 0;
                            padding: 0 0 0 20px;
                        }
                    }
                }
                &:hover {
                    a {
                        span {
                            transition: all 0.2s ease-in-out;
                            width: auto;
                            opacity: 1;
                            top: -1px;
                            left: 0;
                            padding: 0 0 0 20px;
                        }
                    }
                }
                a {
                    padding: 0;
                    position: relative;
                    display: block;
                    line-height: normal;
                    font-size: 12px;
                    line-height: 12px;
                    margin: 8px 0;
                    vertical-align: top;
                    height: 12px;
                    width: 4px;
                    &:before {
                        content: "";
                        height: 12px;
                        width: 4px;
                        top: 0;
                        transition: all 0.2s ease-in-out;
                        background: $primary-color;
                        position: absolute;
                        left: 0;
                        opacity: 1;
                        border-radius: 0;
                    }
                    &:hover {
                        &:before {
                            background: $white-color;
                        }
                    }
                    &.active {
                        line-height: 32px;
                        height: 32px;
                        span {
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        &:before {
                            height: 32px;
                            background: $white-color;
                        }
                    }
                }
                span {
                    position: absolute;
                    overflow: hidden;
                    white-space: nowrap;
                    top: -1px;
                    font-size: 13px;
                    line-height: 16px;
                    max-width: 220px;
                    left: 10px;
                    display: block;
                    color: $white-color;
                    opacity: 0;
                    cursor: pointer;
                    transition: width 0.2s ease-in-out;
                    width: 0;
                }
            }
        }
    }
}
body {
    &.about-page {
        padding-top: 81px;
        @include media-breakpoint-down(md) {
            padding-top: 81px;
        }
        main {
            @include media-breakpoint-up(lg) {
                min-height: 0;
            }
        }
        header {
            @include media-breakpoint-up(lg) {
                background: #ffffff;
            }
            &.fixed-top {
                @include media-breakpoint-up(lg) {
                    background: #ffffff;
                } 
            }
        }
        // header {
        //     @include media-breakpoint-up(lg) {
        //         position: relative;
        //     }           
        //     &.fixed-top {
        //         @include media-breakpoint-up(lg) {
        //             position: relative;
        //             background: #ffffff;
        //         } 
        //     }
        // }
        .breadcrumbs {
            @include media-breakpoint-down(md) {
                background: $white-color;
                padding-bottom: 12px;
                max-width: 100%;
                margin-bottom: 0;
                a {
                    color: $gray;
                    &:not([href]):not([tabindex]) {
                        color: $gray;
                    }
                }
            }
        }
    }
}


/******ABOUT 1 HTML **********/
.full-height {
    .slick-dots {
        display: none;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        left: 16px;
        z-index: 11;
        background: none;
        border: 0;
        margin: 0;
        padding: 0;
        @include media-breakpoint-down(md) {
            position: absolute;
            top: auto;
            bottom: 60px;
            left: auto;
            padding: 0 15px;
            transform: none;
        }
        li {
            padding: 0;
            margin: 0;
            display: block;
            @include media-breakpoint-down(md) {
                display: inline-block;
                margin-right: 8px;
                line-height: normal;
            }
            &:before {
                content: none;
            }
            button {
                padding: 0;
                position: relative;
                display: block;
                line-height: normal;
                line-height: 12px;
                margin: 8px 0;
                vertical-align: top;
                background: $fern-green;
                color: #ffffff;
                height: 12px;
                border: 0;
                box-shadow: none;
                width: 4px;
                outline: none;
                font-size: 0;
                @include transition(0.3s);
                @include media-breakpoint-down(md) {
                    width: 12px;
                    line-height: 12px;
                    height: 4px;
                    margin: 0;
                }
                span {
                    text-transform: capitalize;
                    position: absolute;
                    overflow: hidden;
                    white-space: nowrap;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 13px;
                    line-height: 16px;
                    max-width: 220px;
                    left: 10px;
                    display: block;
                    color: #ffffff;
                    opacity: 0;
                    cursor: pointer;
                    transition: width 0.2s ease-in-out;
                    width: 0;
                }
                &:hover {
                    background: $white-color;
                }
            }
            &.slick-active {
                button {
                    background: $white-color;
                    line-height: 32px;
                    height: 32px;
                    @include transition(0.3s);
                    @include media-breakpoint-down(md) {
                        width: 32px;
                        line-height: 12px;
                        background: $white-color;
                        height: 4px;
                    }
                }
            }
            &.text-visible {
                button {
                    span {
                        transition: all 0.2s ease-in-out;
                        width: auto;
                        opacity: 1;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        padding: 0 0 0 20px;
                    }
                }
            }
            &:hover {
                button {
                    span {
                        transition: all 0.2s ease-in-out;
                        width: auto;
                        opacity: 1;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        padding: 0 0 0 20px;
                    }
                }
            }
        }
        &:hover {
            li {
                button {
                    @include media-breakpoint-up(lg) {
                        @include transition(0.3s);
                        width: 8px;
                    }
                }
            }
        }
        &.disabled-bullets {
            display: none !important;
        }
    }
}
.slider-nav {
    top: 50%;
        transform: translateY(-50%);
        position: fixed;
        left: 16px;
        z-index: 11;
        background: none;
        border: 0;
        margin: 0;
        padding: 0;
}

.full-height {
    .slick-slide {
        border: 0;
        .animation-div {
            overflow: hidden;
        }
        .right-img {
            .animate-wrap {
                right: -100%;
            }
        }
        &.slick-current {
            .animate-wrap {
                transform: translateX(-100%);
                right: -100%;
                img ,
                .embed-responsive-item {
                    transform: translateX(0);
                    @include media-breakpoint-down(md) {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .volume-controls {
        display: flex;
    }
}

@-webkit-keyframes fadeInUpSD {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 48px, 0);
        -ms-transform: translate3d(0, 48px, 0);
        transform: translate3d(0, 48px, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }
}

@keyframes fadeInUpSD {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0, 48px, 0);
        -ms-transform: translate3d(0, 48px, 0);
        transform: translate3d(0, 48px, 0);
    }
    100% {
        opacity: 1;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }
}
.fadeInUpSD {
    -webkit-animation-name: fadeInUpSD;
    animation-name: fadeInUpSD;
}
.slick-active {
    .for-fadeup {
        animation-name: fadeInUpSD;
        animation-duration: 1s;
        opacity: 1;
    }
}
    
.for-fadeup {
    @include media-breakpoint-down(md) {
        opacity: 0;
    }
}