.cmn-full-height-banner {
    // min-height: 877px;
    height: 100vh;
    position: relative;
    padding-top: 82px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include media-breakpoint-down(md) {
        min-height: 0;
        padding: 82px 0 0 0;
        display: flex;
        height: auto;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    .hp-banner-arrow {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        margin: auto;
        top: auto;
        width: 130px;
        border: 0;
        z-index: 1;
        font-size: 14px;
        color: #ffffff;
        text-decoration: none;
        line-height: 16px;
        letter-spacing: 0.12px;
        text-transform: uppercase;
        text-align: center;
        @include media-breakpoint-down(md) {
            display: none;
        }
        #scroll-down-arrow {
            width: 40px;
            height: 40px;
            background: url('../img/icon-scrollarrow-white-new.svg') no-repeat center center;
            margin: 0 auto;
            -webkit-animation-name: hvr-bob-float, hvr-bob;
            animation-name: hvr-bob-float, hvr-bob;
            -webkit-animation-duration: .3s, 1.5s;
            animation-duration: .3s, 1.5s;
            -webkit-animation-delay: 0s, .3s;
            animation-delay: 0s, .3s;
            -webkit-animation-timing-function: ease-out, ease-in-out;
            animation-timing-function: ease-out, ease-in-out;
            -webkit-animation-iteration-count: 1, infinite;
            animation-iteration-count: 1, infinite;
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
            -webkit-animation-direction: normal, alternate;
            animation-direction: normal, alternate;
            position: relative;
            top: 10px;
        }
    }
    .container {
        @include media-breakpoint-down(md) {
            padding: 0;
        }
    }
    &:before {
        background: radial-gradient(circle, rgba(0,0,0,0) 0%, #000000 100%);
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        content: "";
        z-index: 1;
        opacity: 0.7;
    }
    .divImg {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        @include transition(0.3s);
        top: 0;
        bottom: 0;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .container {
        position: relative;
        z-index: 1;
    }
    .banner-data {
        position: relative;
        @include media-breakpoint-down(md) {
            padding: 47px 15px 0 15px;
            margin-bottom: 105px;
        }
        &:before {
            position: absolute;
            background: $spanish-orange;
            bottom: -28px;
            left: 0;
            right: 0;
            width: 42px;
            content: "";
            height: 3px;
            @include media-breakpoint-down(md) {
                left: 15px;
            }
        }
        
        h1 {
            color: $white-color;
            margin: 0;
            position: relative;
            @include media-breakpoint-down(md){
                margin: 0 0 20px 0;
            }
            
        }
        span {
            color: #ffffff;
        }
    }
    .sub-links {
        ul {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            li {
                &:before {
                    content: none;
                }
                margin: 0 0 4px 0;
                padding: 0;
                width: calc(50% - 2px);
                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
                &:last-child {
                    @include media-breakpoint-down(lg) {
                        margin-bottom: 0;
                    }
                }
                a {
                    text-decoration: none;
                    color: $white-color;
                    padding: 10px 30px;
                    margin: 0;
                    background: rgba(0, 0, 0, 0.45);
                    height: 100%;
                    display: flex;
                    justify-content: flex-start;
                    min-height: 185px;
                    align-items: center;
                    @include transition(0.3s);
                    @media(min-width: 1919.98px) {
                        padding: 10px 50px 10px 30px;
                    }
                    @include media-breakpoint-down(lg) {
                        min-height: 80px;
                        padding: 5px 40px 5px 16px;
                        background: rgba(0, 0, 0, 0.6);
                    }
                    @include media-breakpoint-down(xs) {
                        font-size: 19px;
                        line-height: 28px;
                        letter-spacing: -0.06px;
                    }
                    &:hover {
                        background: $fern-green;
                        @include transition(0.3s);
                    }
                    &.active {
                        background: rgba(94, 127, 92,0.85);
                        svg {
                            * {
                                stroke: $fern-green;
                            }
                        }
                    }
                    svg {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%) !important;
                        margin: 0;
                        right: 27px;
                        height: 17px !important;
                        width: 27px !important;
                    }
                }
            }
        }
    }
    #scroll-down-arrow {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}
.social-intro {
    padding: 140px 0 70px 0;
    @include media-breakpoint-down(md) {
        padding: 60px 0 40px 0;
    }
    .overflow {
        @include media-breakpoint-up(lg) {
            overflow: hidden;
        }
    }
    .ver-strip{
        background: $fern-green;
        display: block;
        margin: auto;
        position: absolute;
        left: 0px;
        right: 0px;
        top: -10px;
        bottom: -10px;
        width: 3px;
        z-index: 0;
        @include media-breakpoint-down(md) {
            bottom: 15px;
            left: 0;
            right: 0;
            height: 3px;
            width: 80px;
        }
    }
    h2 {
        display: flex;
        height: 100%;
        align-items: center;
        @include media-breakpoint-down(md) {
            text-align: center;
            padding: 0;
            display: block;
            margin: 0 0 32px 0;
        }
    }
    p {
        margin-bottom: 16px;
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }
    h6 {
        margin: 0;
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }
}
.img-text-section {
    @include media-breakpoint-down(md) {
        padding: 40px 0 42px 0;
    }
    .img-text {
        img {
            width: 100%;
            height: auto;
            @include media-breakpoint-down(sm) {
                margin-bottom: 86px;
            }
        }
        p {
            &:last-child {
                margin: 0;
            }
        }
    }
    &.social-landing-images {
        .left-img-div {
            position: relative;
            min-height: 534px;
            @media(min-width: 1919.98px) {
                min-height: 640px;
            }
            @include media-breakpoint-down(lg) {
                min-height: 453px;
            }
            @include media-breakpoint-down(md) {
                min-height: 315px;
                margin-bottom: 40px;
            }
            @include media-breakpoint-down(sm) {
                min-height: 0;
            }
            .divImg {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                height: 100%;
                width: 100%;
            }
            .img-text-back-img {
                position: relative;
                width: 470px;
                height: 412px;
                left: 0;
                @media(min-width: 1919.98px) {
                    width: 580px;
                    height: 500px;
                }
                @include media-breakpoint-down(lg) {
                    width: 380px;
                    height: 333px;
                }
                @include media-breakpoint-down(md) {
                    width: 280px;
                    height: 245px;
                }
                @include media-breakpoint-down(sm) {
                    width: 80%;
                    height: 375px;
                }
                @include media-breakpoint-down(xs) {
                    width: 87%;
                    height: 259px;
                }
            }
            .img-text-front-img {
                position: absolute;
                width: 470px;
                height: 272px;
                bottom: 0;
                right: 15px;
                @media(min-width: 1919.98px) {
                    width: 580px;
                    height: 340px;
                }
                @include media-breakpoint-down(lg) {
                    width: 380px;
                    height: 220px;
                }
                @include media-breakpoint-down(md) {
                    width: 280px;
                    height: 163px;
                }
                @include media-breakpoint-down(sm) {
                    width: 80%;
                    position: relative;
                    height: 233px;
                    right: 0;
                    margin-top: -130px;
                    margin-left: auto;
                }
                @include media-breakpoint-down(xs) {
                    width: 87%;
                    height: 171px;
                    margin-top: -90px;
                }
            }
        }
    }
}

.social-commitment-main {
    .cmn-full-height-banner {
        .divImg {
            opacity: 0;
            transition: all 1s ease;
        }
    }
}