header {
    background: transparent;
    border-bottom: 1px solid rgba($light-black, .15);
    transition: background 0.2s ease-in-out;

    .navbar.navbar-expand-lg {
        padding: 15px 0;
        z-index: 3;
        @include transition(0.5s);

        @include media-breakpoint-down(sm) {
            padding: 15px 0px;
        }

        .container {
            max-width: 100%;
            padding-right: 40px;
            padding-left: 40px;
            position: relative;
            width: 100%;

            @include media-breakpoint-down(md) {
                padding: 0px 15px;
            }
        }

        .navbar-brand {
            border-right: 1px solid rgba($light-black, .15);
            display: inline-block;
            margin: 0;
            padding: 10px 30px 10px 0px;
            @include transition(0.3s);

            @include media-breakpoint-down(sm) {
                padding: 10px 15px 10px 0px;
            }

            img {
                display: block;
                max-width: 256px;
                height: auto;
                width: auto;
                @include transition(0.3s);

                @include media-breakpoint-down(sm) {
                    max-width: 92px;
                }
            }
        }


        #stickyContent {
            -webkit-display: flex;
            display: flex;
            padding-left: 20px;
            @include media-breakpoint-up(lg){
                width: calc(100% - 300px);
            }
            .breadcrumbs{
                width: 100%;
            }

            @include media-breakpoint-down(md) {
                display: none;
            }
            .top-breadcrumbs{
                margin-left: 20px;
                color: $gray;
            }
            .header-headshot {
                height: 38px;
                width: 38px;
                background: $cultured;
                margin-right: 13px;
                border-radius: 100%;
                display: flex;
                overflow: hidden;
                align-items: self-end;
                align-content: baseline;
            }
            .detail-img {
                max-height: 38px;
                height: auto;
                border-radius: 100%;
                margin: 0;
            }

            .professional-detail-desc {
                padding: 0;

                &.location-detail-head {
                    .h3 {
                        font-size: 18px;
                        line-height: 24px;
                        margin: 0px;
                    }

                    .detail-foot .contact-info a {
                        margin-bottom: 0px;

                        span {
                            line-height: normal;
                        }
                    }
                }

                h5 {
                    display: none;
                }

                .h3 {
                    color: #86828C;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 20px;
                    font-family: $font-regular;
                    font-weight: 400;
                    margin-bottom: 7px;
                    display: inline-block;
                }

                .detail-foot {
                    padding: 0 0 0 8px;
                    display: inline-block !important;
                    vertical-align: middle;

                    .secondary-links {
                        display: none;
                    }

                    .contact-info {
                        -webkit-display: flex;
                        display: flex;

                        a {
                            font-size: 0;
                            width: 16px;
                            height: 16px;
                            padding: 0;
                            margin-right: 10px;
                        }
                    }
                }

                .cmn-practice-inner-areas {
                    display: -webkit-flex;
                    display: flex;

                    a {
                        text-decoration: none;
                        margin: 0 7px;
                        position: relative;
                        display: inline-block;
                        color: $primary-color;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0;

                        @include media-breakpoint-down(sm) {
                            margin: 0 12px 6px 12px;
                        }

                        &:after {
                            content: "";
                            height: 20px;
                            width: 1px;
                            background: $gainsboro;
                            display: inline-block;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            right: -7px;

                            @include media-breakpoint-down(sm) {
                                right: -12px;
                                top: -2px;
                            }
                        }

                        &:first-child {
                            margin-left: 0;

                            @include media-breakpoint-down(sm) {
                                margin-left: 12px;
                            }
                        }

                        &:last-child {
                            margin-right: 0;

                            &:after {
                                content: normal;
                            }
                        }
                    }
                }
            }
        }


        .right-nav {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: auto;

            .navbar-toggler {
                border: none;
                border-radius: 0px;
                cursor: pointer;
                color: #7B7B7B;
                display: inline-block;
                font-family: $font-regular;
                font-size: 12px;
                line-height: 16px;
                outline: none;
                margin-left: 30px;
                padding: 0px;
                position: relative;
                @include transition(0.4s);
                height: 50px;
                width: 31px;
                z-index: 2;

                @include media-breakpoint-down(sm) {
                    height: auto;

                    .navbar-toggler-text {
                        display: none;
                    }
                }

                #iconHamburger {
                    display: block;
                    height: 24px;
                    overflow: hidden;
                }
                span {
                    display: block;
                    margin: 3px 0px 0px;
                    transition: all 0.35s ease;
                }

                &:active {
                    color: $spanish-orange;

                    #iconHamburger svg * {
                        fill: $spanish-orange;
                    }
                }
            }

            .search-bar {
                border-left: 1px solid rgba($light-black, .15);
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: center;
                margin: auto;
                padding-left: 19px;
                position: absolute;
                top: 0px;
                bottom: 0px;
                right: 112px;
                transition: all 0.3s ease-in-out;
                width: 56px;
                z-index: 1;

                @include media-breakpoint-down(md) {
                    right: 70px;
                }

                @include media-breakpoint-down(sm) {
                    padding-left: 11px;
                    right: 58px;
                    transition: none;
                }

                &:after {
                    background: $secondary-color;
                    content: '';
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    height: 1px;
                    width: 0px;
                    z-index: 0;
                    transition: all 0.3s ease-in-out;
                }

                .btn-search {
                    color: #7B7B7B;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: center;
                    font-family: $font-regular;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    text-decoration: none;
                    width: 36px;

                    span {
                        display: block;
                        margin: 3px 0px 0px;
                        order: 2;
                        transition: all 0.35s ease;

                        @include media-breakpoint-down(sm) {
                            display: none;
                        }
                    }

                    svg {
                        height: 24px !important;
                        width: 20px !important;
                    }

                    &:active {
                        color: $spanish-orange;
    
                        svg * {
                            stroke: $spanish-orange;
                        }
                    }
                }

                form {
                    float: left;
                    overflow: hidden;
                    padding-left: 4px;
                    width: 0;
                    opacity: 0;

                    .form-group label {
                        top: 11px;
                        white-space: nowrap;
                    }

                    .form-group input[data-empty="false"]+label,
                    .form-group input:focus+label,
                    .form-group select[data-empty="false"]+label,
                    .form-group select:focus+label,
                    .form-group textarea[data-empty="false"]+label,
                    .form-group textarea:focus+label,
                    .input-focus label,
                    .input-focus textarea,
                    .input-focus label {
                        top: 1px;
                    }

                    .form-control {
                        background: transparent;
                        border-color: transparent;
                        height: 50px;
                        padding: 15px 10px 1px 0;
                    }

                    input[type='submit'] {
                        border: none;
                        font-size: 0;
                        margin: 0;
                        pointer-events: none;
                        position: absolute;
                        height: 0;
                        width: 0;
                        visibility: hidden;
                        z-index: -1;
                    }
                    input[type='reset'] {
                        background: url('../img/icon-close-dark.svg') no-repeat center center;
                        background-size: 14px auto;
                        border: none;
                        display: none;
                        padding: 0px;
                        pointer-events: none;
                        position: absolute;
                        bottom: 12px;
                        right: -2px;
                        height: 20px;
                        width: 20px;
                        z-index: 2;
                    }

                    .form-group input[data-empty="false"]~input[type='reset'] {
                        pointer-events: all;
                        display: block;
                        @include media-breakpoint-down(sm) {
                            display: none;
                        }
                    }
                }

                .btn-close {
                    background: url('../img/icon-close-dark.svg') no-repeat center center;
                    display: none;
                    margin-left: 12px;
                    text-indent: -99999px;
                    height: 30px;
                    width: 30px;
                }

                &.open-search {
                    border-color: transparent;
                    padding: 0px;
                    left: 0px;
                    right: 0px;
                    width: 500px;

                    @include media-breakpoint-down(md) {
                        width: 440px;
                    }

                    @include media-breakpoint-down(sm) {
                        background: $white-color;
                        border-color: transparent;
                        margin: 0px 14px;
                        height: 50px;
                        width: auto;
                        z-index: 3;

                        form {
                            background: url('../img/search-icon.svg') no-repeat left center;
                            background-size: 16px auto;
                            padding-left: 25px;
                            width: calc(100% - 43px);
                        }

                        .btn-close { 
                            display: block;
                        }
                    }

                    &:after {
                        width: 100%;
                        // @include media-breakpoint-down(sm) {
                        //     width: 90%;
                        // }
                    }

                    .btn-search {
                        margin-top: 6px;
                        @include media-breakpoint-down(sm) {
                            display: none;
                        }
                        span {
                            display: none;
                        }
                        svg {
                            height: 16px !important;
                            width: 16px !important;
                        }
                    }
                    form {
                        opacity: 1;
                        width: calc(100% - 40px);
                        transition: all 0.3s ease-in-out;
                        @include media-breakpoint-down(sm)  {
                            transition: none;
                        }
                    }
                }

            }
        }

        .navbar-collapse {
            background: rgba(0, 0, 0, .8);
            display: block !important;
            overflow: hidden;
            max-width: 596px;
            padding: 80px 0px 30px;
            position: fixed;
            top: 0;
            right: 0;
            transition: all 0.4s ease-out !important;
            transform: translateX(100%);
            height: 100%;
            width: 100%;
            z-index: 1032;

            .close-icon {
                display: inline-block;
                position: absolute;
                top: 25px;
                right: 48px;
                height: 25px;
                width: 25px;
                z-index: 3;
                @include media-breakpoint-down(md) {
                    right: 18px;
                    top: 18px;
                }
                @include media-breakpoint-down(sm) {
                    right: 20px;
                    top: 20px;
                }

                &:hover {
                    background: transparent;
                }

            }

            &.collapse-in {
                transform: translateX(0);

                .navbar-nav {
                    transform: translateX(0);
                }
            }

            &.collapsing {
                transition: unset;
                height: auto;
            }

            .mCustomScrollbar {
                height: 100%;
            }

            .mCustomScrollBox {
                .mCSB_container {
                    margin-right: 0px;

                    &.active {
                        height: 100%;
                    }
                }

                .mCSB_dragger_bar {
                    background-color: #797979;
                }

                .mCSB_scrollTools {
                    .mCSB_draggerContainer {
                        top: 5px;
                        bottom: 5px;
                        right: 5px;
                    }
                }
            }
        }

        .navbar-nav {
            width: 100%;
            display: block;
            margin: 0;
            padding: 0px 0px 0px;
            height: 100%;
            position: relative;
            transform: translateX(100%);
            transition: all 0.4s ease-out;
            transition-delay: 0.4s;

            li {
                position: static;
                outline: none;
                margin: 0px;
                line-height: normal;
                width: 100%;

                &:first-child {
                    padding-top: 90px;

                    @include media-breakpoint-down(sm) {
                        padding-top: 60px;
                    }
                }

                >a {
                    color: $white-color;
                    display: block;
                    font-family: $font-regular;
                    font-size: 28px;
                    font-weight: 400;
                    line-height: 40px;
                    outline: none;
                    text-decoration: none;
                    padding: 12px 75px 12px 48px;
                    position: relative;
                    text-align: right;
                    @include transition(0.4s);

                    @include media-breakpoint-down(sm) {
                        padding: 12px 16px;
                    }

                    &:after {
                        background: $rocket-metallic;
                        content: '';
                        position: absolute;
                        bottom: 12px;
                        right: 75px;
                        height: 1px;
                        width: 0px;
                        z-index: 0;
                        @include transition(0.35s);
                        @include media-breakpoint-down(sm) {
                            right: 15px;
                        }
                    }

                    &:hover {
                        color: $rocket-metallic;
                        @include media-breakpoint-down(sm) {
                            color: $white-color;
                        }

                        &:after {
                            width: 60px;
                            @include media-breakpoint-down(sm) {
                                content: none;
                            }
    
                        }
                    }
                }

                &.active {
                    > a {
                        color: $rocket-metallic;

                        &:after {
                            width: 60px;
                        }
                    }
                }

                .sub-menu {
                    position: absolute;
                    top: 0;
                    background: #282a2b;
                    z-index: 2;
                    padding: 0px;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                    bottom: 0;
                    transition: all 0.4s ease-out;
                    -webkit-transform: translateX(100%);
                    transform: translateX(100%);

                    li {
                        a {
                            font-size: 14px;
                            font-family: $font-regular;
                            letter-spacing: 2.7px;
                            color: #bbbbbb;
                            padding-top: 26px;
                            padding-bottom: 26px;

                            &:hover {
                                color: $primary-color;

                                @include media-breakpoint-down(md) {
                                    color: #bbbbbb;
                                }
                            }

                            span {
                                font-size: 11px;
                                font-family: $font-regular;
                                letter-spacing: 1px;
                                line-height: 13px;
                                display: block;
                                padding-top: 4px;
                            }
                        }

                        &.back {
                            a {
                                font-size: 16px !important;
                                color: $white-color !important;
                                padding: 34px 50px !important;
                                white-space: nowrap;

                                &:before {
                                    content: "";
                                    background: transparent url("../img/left-arrow.svg") no-repeat center center;
                                    width: 31px;
                                    height: 18px;
                                    display: inline-block;
                                    margin: -4px 25px 0 0;
                                    vertical-align: middle;
                                    @include transition(0.4s);
                                }
                            }
                        }
                    }
                }

                &.active {
                    .sub-menu {
                        -webkit-transform: translateX(0%);
                        transform: translateX(0%);
                        overflow: auto;
                    }
                }
            }

            &.small-links {
                margin: 0px 0px 30px;
                transition-delay: 0.5s;

                li {
                    &:first-child {
                        padding-top: 45px;

                        @include media-breakpoint-down(sm) {
                            padding-top: 38px;
                        }
                    }

                    >a {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        padding: 10px 75px 10px 48px;

                        @include media-breakpoint-down(sm) {
                            padding: 10px 16px;
                        }

                        &:after {
                            bottom: 10px;
                        }
                    }
                }
            }
            ul {
                &.small-links {
                    transition: none;
                    transition-delay: inherit;
                    li {
                        &:first-child {
                            padding-top: 0;
                        }
                    }
                }
            }
        }

        .header-overlay {
            &:after {
                content: "";
                position: fixed;
                top: 0;
                left: 0;
                z-index: 1031;
                width: 100vw;
                height: 100vh;
                visibility: hidden;
                opacity: 0;
                transition: all 0.4s ease;
            }
        }
    }

    &.add-overlay {
        .header-overlay {
            &:after {
                visibility: visible !important;
                opacity: 1 !important;
            }
        }

        //Header Fixed on Scroll
        &.scroll-fix {
            background: $white-color;
            box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);

            .navbar.navbar-expand-lg {
                padding: 9px 0px;

                @include media-breakpoint-down(sm) {
                    padding: 10px 0;
                }

                .navbar-brand {
                    padding: 9px 30px 9px 0px;

                    @include media-breakpoint-down(sm) {
                        padding: 10px 15px 10px 0px;
                    }

                    img {
                        max-width: 120px;

                        @include media-breakpoint-down(sm) {
                            max-width: 92px;
                        }
                    }
                }
            }

        }
    }

    &.show-search {
        background: $white-color;
    }

    //Header Fixed on Scroll
    &.scroll-fix {
        background: $white-color;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);

        .navbar.navbar-expand-lg {
            padding: 9px 0px;

            @include media-breakpoint-down(sm) {
                padding: 10px 0;
            }

            .navbar-brand {
                padding: 9px 30px 9px 0px;

                @include media-breakpoint-down(sm) {
                    padding: 10px 15px 10px 0px;
                }

                img {
                    max-width: 120px;

                    @include media-breakpoint-down(sm) {
                        max-width: 92px;
                    }
                }
            }
            .right-nav{
                .btn-search{
                    span{
                        display: none;
                    }
                }
                .navbar-toggler{
                    .navbar-toggler-text{
                        display: none;
                    }
                } 
             }
        }

    }
}

// Home: Header Style
.home {
    padding-top: 80px;

    @include media-breakpoint-down(sm) {
        padding-top: 69px;
    }

    header {
        background: none;
        border-color: rgba(54, 50, 59, 0.15);

        .navbar.navbar-expand-lg {
            .navbar-brand {
                padding: 10px 30px 10px 0px;
                border-right: 1px solid rgba(54, 50, 59, 0.15);

                @media (max-width: 1024.98px) { 
                    padding: 10px 30px 10px 0px;
                }

                @include media-breakpoint-down(sm) {
                    padding: 14px 16px 14px 0px;
                }
            }
            .right-nav {
                .search-bar {
                    border-color: rgba(54, 50, 59, 0.15);
                    &.open-search {
                        border-color: transparent;
                    }
                }
            }
        }

        &.scroll-fix {
            background: $white-color;
            border-color: rgba($light-black, .15);

            .navbar.navbar-expand-lg {
                .navbar-brand {
                    padding: 9px 30px 9px 0px;
                    @include media-breakpoint-down(sm) {
                        padding-right: 16px;
                    }
                }
            }
        }
    }
}

// Header: Transparet - Dark Banner - White logo, Icon Style
.dark-banner-header {
    header {
        border-color: rgba($gainsboro, .30);

        .navbar.navbar-expand-lg {
            .navbar-brand {
                border-color: rgba($gainsboro, .30);
                padding: 8px 16px 8px 0px;

                @include media-breakpoint-down(sm) {
                    svg {
                        width: 92px;
                    }
                }


                svg *, svg path {
                    fill: $white-color;
                }
            }

            .right-nav {
                .navbar-toggler {
                    color: $white-color;
                }
                svg *, svg path {
                    fill: $white-color;
                }

                .search-bar {
                    border-color: rgba($gainsboro, .30);
                }

                .btn-search {
                    color: $white-color;
                    svg path {
                        stroke: $white-color;
                    }
                }
            }
        }

        &.scroll-fix, &.show-search {
            .navbar.navbar-expand-lg {
                .navbar-brand {
                    border-right-color: rgba($light-black, .15);

                    @include media-breakpoint-down(sm) {
                        padding: 3px 16px 3px 0px;
                    }

                    svg *, svg path {
                        fill: $secondary-color;
                    }
                }
    
                .right-nav {
                    .navbar-toggler {
                        color: #7B7B7B;
                    }

                    .search-bar {
                        border-left-color: rgba($light-black, .15);
                    }

                    svg *, svg path {
                        fill: #36373B;
                    }
    
                    .btn-search {
                        color: #7B7B7B;
                        svg path {
                            stroke: #36373B;
                        }
                    }
                }
            }   
        }

        &.show-search {
            .navbar.navbar-expand-lg {
                .right-nav {
                    .search-bar {
                        border-left-color: transparent !important;
                    }
                }
            }   
        }
    }
}
main {
    > .breadcrumbs {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

.autocomplete-items {
    position: absolute;
    top: 100%;
    box-shadow: 0px 9px 20px 0 rgba(0,0,0,0.1);
    background: #fff;
    height: auto;
    width: 100%;
    .mCSB_container > div{
        padding: 5px 15px 15px 15px;
        &:first-child {
            padding-top: 20px;
        }
        &:last-child {
            padding-bottom: 20px;
        }
        .suggestion-subneading {
            @extend h6;
            margin-bottom: 5px;
        }
        a {
            text-decoration: none;
            display: block;
            color: $rich-black;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            margin-bottom: 5px;
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                color: $cyber-grape;
            }
        }
    }
    #no-found {
        padding: 15px;
        text-decoration: none;
        display: block;
        color: #0e0e0e;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 0;
        line-height: 24px;
    }
}
.search-bar form {
    overflow: inherit !important;
}
header.show-search #autocomplete-data.autocomplete-items {
    display: block;
}
#autocomplete-data.autocomplete-items {
    display: none;
}
header {
    .navbar.navbar-expand-lg {
        .navbar-collapse {
            &.collapsing {
                height: 100%;
            }
        }
    }
}


.home {
    .new-menu {
        display: flex;
        @include media-breakpoint-down(lg) {
            display: none;
        }
    } 
    .show-search {
        .new-menu {
            display: none;
        } 
    }
}
.new-menu {
    margin-right: 116px;
    display: none;
    justify-content: flex-end;
    margin-left: auto;
    @media(max-width: 1260px) {
        margin-right: 80px;
    }
    ul {
        margin: 0;
        display: flex;
        li {
            margin: 0 0 0 20px;
            padding: 0;
            @media(max-width: 1260px) {
                margin: 0 0 0 15px;
            }
            &:before {
                content: none;
            }
            a {
                font-size: 14px;
                color: $rocket-metallic;
                text-decoration: none;
                &:hover {
                    color: $cyber-grape;
                }
            } 
            &.seperator {
                margin-left: 18px;
                padding-left: 18px;
                &:before {
                    content: "";
                    background: rgba(54, 50, 59, 0.15);
                    height: 50px;
                    width: 1px;
                    position: absolute;
                    top: -5px;
                    left: 0;
                }
            }  
        }
    }
}
.home {
    header {
        .navbar.navbar-expand-lg {
            .navbar-brand {
                svg {
                    * {
                        fill: $light-black;
                    }
                }
            }
        }
        &.scroll-fix {
            .navbar.navbar-expand-lg {
                .navbar-brand {
                    svg {
                        * {
                            fill:rgb(62, 34, 104);
                        }
                    }
                }
            }
        }
    }
}
.dark-image-banner {
    header {
        .navbar.navbar-expand-lg {
            .navbar-brand {
                border-color: #858585;
                svg {
                    * {
                        fill: $white-color;
                    }
                }
            }
            .right-nav {
                .search-bar {
                    border-color: #858585;
                    &.open-search {
                        border-color: transparent;
                        &:after {
                            background: $white-color;
                        }
                    }
                    .btn-search {
                        color: $white-color;
                        svg {
                            * {
                                stroke: $white-color;
                            }
                        }
                    }
                    form {
                        .form-group {
                            label {
                                color: $white-color;
                            }
                            input {
                                color: $white-color;
                            }
                        }
                    }
                }
                .navbar-toggler {
                    color: $white-color;
                    svg {
                        * {
                            fill: $white-color;
                        }
                    }
                }
            }
        }
        .new-menu {
            ul {
                li {
                    &.seperator {
                        &:before {
                            background: rgb(133, 133, 133);
                        }
                    }
                    a {
                        color: $white-color;
                        &:hover {
                            color: $gainsboro;
                        }
                    }
                }
            }
        }
        &.scroll-fix {
            .navbar.navbar-expand-lg {
                .navbar-brand {
                    border-color: rgba(54, 50, 59, 0.15);
                    svg {
                        * {
                            fill: rgb(85, 26, 139);
                        }
                    }
                }
                .right-nav {
                    .search-bar {
                        border-color: rgba(54, 50, 59, 0.15);
                        &.open-search {
                            border-color: transparent;
                            &:after {
                                background: #3E2268;
                            }
                        }
                        .btn-search {
                            color: #7B7B7B;
                            svg {
                                * {
                                    stroke: rgb(54, 50, 59);
                                }
                            }
                        }
                        form {
                            .form-group {
                                label {
                                    color: rgba(14, 14, 14, 0.5);
                                }
                                input {
                                    color: rgba(14, 14, 14, 0.5);
                                }
                            }
                        }
                    }
                    .navbar-toggler {
                        color: $white-color;
                        svg {
                            * {
                                fill: rgb(54, 50, 59);
                            }
                        }
                    }
                }
            }
            .new-menu {
                ul {
                    li {
                        &.seperator {
                            &:before {
                                background: rgba(54, 50, 59, 0.15);
                            }
                        }
                        a {
                            color: $rocket-metallic;
                            &:hover {
                                color: $cyber-grape;
                            }
                        }
                    }
                }
            }
        }
    }
    .hero-banner {
        .arrow-transition {
            .white-img {
                display: inline-block;
            }
            .dark-img {
                display: none;
            }
        }
        .hero-items {
            .hero-item {
                .container {
                    h1 ,
                    p {
                        color: $white-color;
                    }
                    .btn-primary-link{
                        color: $white-color !important;
                        svg {
                            * {
                                stroke: $white-color !important;
                            }
                        }
                    }
                }
            }
        }
        .hp-banner-arrow {
            color: #F7F4F2;
        }
        .progressBarContainer {
            div {
                span.progressBar {
                    box-shadow: none;
                }
            }
        }
    }
}
.hero-banner {
    .arrow-transition {
        .dark-img {
            display: inline-block;;
        }
        .white-img {
            display: none;
        }
    }
    .hero-items {
        .hero-item {
            .container  {
                h1 ,
                p {
                    color: rgba(54, 50, 59, 0.96);
                }
                .btn-primary-link{
                    color: rgba(54, 50, 59, 1) !important;
                    svg {
                        * {
                            stroke: rgba(54, 50, 59, 1) !important;
                        }
                    }
                }
            }
        }
    }
    .hp-banner-arrow {
        color: #86828C;
    }
    .progressBarContainer {
        div {
            span.progressBar {
                box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
            }
        }
    }
}


.home {
    header {
        .navbar.navbar-expand-lg {
            .right-nav{
                margin-left: unset;
                @include media-breakpoint-down(lg) {
                    margin-left: auto;
                }
            }
        }
        &.show-search {
            .navbar.navbar-expand-lg {
                .right-nav {
                    @media(min-width: 1199.98px) {
                        margin-left: auto;
                    }
                }
            }
        }
    }
}

.home {
    header {
        .navbar.navbar-expand-lg {
            .right-nav {
                .search-bar:after {
                    background: #36323B;
                }
            }
        }
        &.scroll-fix {
            .navbar.navbar-expand-lg {
                .right-nav {
                    .search-bar:after {
                        background: #3E2268;
                    }
                }
            }
        }
    }
}

header {
    .navbar.navbar-expand-lg {
        #stickyContent {
            .breadcrumbs {
                a {
                    display: none;
                    &:first-child {
                        display: inline-block;
                    }
                }
            }
        }
    }
    &.scroll-fix {
        .navbar.navbar-expand-lg {
            #stickyContent {
                .breadcrumbs {
                    a {
                        display: inline-block;
                    }
                }
            }
        }
    }
}

#autocomplete-data {
    max-height: 240px;
    @include media-breakpoint-down(lg) {
        touch-action: none;
    }
    .mCSB_scrollTools  {
        top: 5px;
        bottom: 5px;
        right: 8px;
        background-color: $cultured;
        .mCSB_dragger_bar {
            line-height: 30px;
            background-color: $gray;
            margin: 0;
        }
    }
}

.home-white-header-bg {
    @include media-breakpoint-down(sm) {
        padding-top: 88px;
    }
    .hero-banner {
        top: 88px;
        min-height: calc(100vh - 88px);
        @include media-breakpoint-down(sm) {
            min-height: 1px;
        }
        .hero-items {
            .hero-item {
                min-height: calc(100vh - 88px);
            }
        }
    }
    header {
        .new-menu {
            ul {
                li {
                    a {
                        color: $rocket-metallic;
                        &:hover {
                            color: $light-black;
                        }
                    }
                    &.seperator {
                        &:before {
                            background: rgba(54, 50, 59, 0.15);
                        }
                    }
                }
            }
        } 
        .navbar.navbar-expand-lg {
            @include media-breakpoint-down(sm) {
                padding: 13px 0px;
            }
            .right-nav {
                .search-bar {
                    border-color: rgba(54, 50, 59, 0.15);
                    &.open-search {
                        &:after {
                            background: #36323B;
                            @include media-breakpoint-down(sm) {
                                width: 100%;
                            }
                        }
                    }
                    .btn-search {
                        color: #7B7B7B;
                        svg * {
                            stroke: $light-black;
                        }
                    }
                    form {
                        .form-group {
                            label {
                                color: rgba(14, 14, 14, 0.5);
                            }
                            input {
                                color: $rich-black
                            }
                        }
                    }
                }
                .navbar-toggler {
                    color: #7B7B7B;
                    svg * {
                        fill: $light-black;
                    }
                }
            }
            .navbar-brand {
                border-right: 1px solid rgba(54, 50, 59, 0.15);
                svg {
                    * {
                        fill: rgb(62, 34, 104);
                    }
                }
            }
        }
    }
    &.dark-image-banner {
        header {
            &.scroll-fix {
                .navbar.navbar-expand-lg {
                    .navbar-brand {
                        svg {
                            * {
                                fill: rgb(62, 34, 104);
                            }
                        }
                    }
                }
            }
        }
    }
    &.home {
        .m-top {
            margin-top: calc(100vh - 65px);
        }
    }
}


@supports (-webkit-touch-callout: none) {
    .home-white-header-bg {
        .hero-banner {
            @include media-breakpoint-only(lg) {
                min-height: calc(100vh - 135px);
            }
            .hero-items {
                .hero-item {
                    min-height: calc(100vh - 135px);
                }
            }
        }
    }
}