.cmn-bg-banner {
    position: relative;
    min-height: 375px;
    padding: 100px 0 50px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media(min-width: 1919.98px) {
        min-height: 575px;
    }
    @include media-breakpoint-down(md) {
        flex-wrap: wrap; 
        align-items: flex-start; 
        padding-top: 81px;
    }
    @include media-breakpoint-down(sm) {
        padding: 81px 0 90px 0;
        min-height: 276px;
    }
    .container {
        @include media-breakpoint-down(md) {
            position: relative;
        }
    }
    .banner-text {
        @include media-breakpoint-down(md) {
            padding-top: 26px;
        }
        h1 {
            margin: 0;
            position: relative;
            &:after {
                position: absolute;
                background: $spanish-orange;
                bottom: -28px;
                left: 0;
                right: 0;
                width: 42px;
                content: "";
                height: 3px;
            }
        }
    }
    .divImg {
        left: 0;
        background-position: 50%;
        right: 0;
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
        top: 0;
    }
}
.tabbing-data {
    .search-box {
        margin-bottom: 55px;
    }
    .filter-tabbing {
        .no-child {
            margin-bottom: 88px;
            justify-content: center;
            @include media-breakpoint-down(md) {
                margin-bottom: 60px;
                justify-content: flex-start;
            }
        }
        .filter {
            @include media-breakpoint-down(md) {
                justify-content: flex-start;
            }
        }
        .filter-tab4 ,
        .filter-tab8 {
            @include media-breakpoint-down(md) {
                flex-wrap: wrap
            }
        }
        .filter-tab2 ,
        .filter-tab3{
            margin-bottom: 88px;
            justify-content: center;
            @include media-breakpoint-down(md) {
                margin-bottom: 60px;
                flex-wrap: wrap
            }
        }
        .filter,
        .filter-tab4  {
            a {
                @extend .text-18;
                color: $rich-black;
                padding: 4px 19px;
                position: relative;
                margin-bottom: 14px;
                border-right: 1px solid $gainsboro;
                text-decoration: none;
                @include media-breakpoint-down(md) {
                    margin-bottom: 19px;
                }
                &:last-child {
                    border-right: 0;
                }
                span {
                    position: relative;
                    &:after {
                        content: "";
                        height: 3px;
                        width: 0%;
                        background-color: transparent;
                        display: block;
                        position: absolute;
                        bottom: -5px;
                        left: 0;
                        z-index: 1;
                        margin: auto;
                        transition: all 0.35s ease;
                    }
                }
                &.current ,
                &:hover {
                    color: $cyber-grape;
                    span {  
                        &:after {
                            width: 100%;
                            content: "";
                            background-color: $rocket-metallic;
                        }
                    }
                }
                &.current {
                    color: $rocket-metallic;
                }
                &:focus ,
                &:active {
                    span {
                        color: $chocolate-web;
                    }
                    &:hover  {
                        span {
                            color: $chocolate-web;
                        }
                    }
                }
                &.current {
                    &:focus ,
                    &:active {
                        span {
                            color:$cyber-grape;
                        }
                        &:hover  {
                            span {
                                color: $cyber-grape;
                                &:after {
                                    content: "";
                                }
                            }
                        }
                    }
                }
                &:hover {
                    span {
                        &:after {
                            content: normal;
                        }
                    }
                }
                &.current {
                    &:hover {
                        span {
                            &:after {
                                content: "";
                            }
                        }
                    }
                }
            }
        }
        .filter-data{
            display: none;
            &.current {
                display: block;
            }
            ul {
                margin: 0;
                padding: 0;
                column-count: 3;
                @include media-breakpoint-down(md) {
                    column-count: 2;
                }
                @include media-breakpoint-down(sm) {
                    column-count: 1;
                }
                li {
                    margin: 0 0 24px 0;
                    padding: 0 7px 0 0;
                    @include media-breakpoint-down(sm) {
                        margin-bottom: 20px;
                        padding: 0;
                    }
                    &:before {
                        content: none;
                    }
                    a {
                        color: $rich-black;
                        position: relative;
                        display: inline-block;
                        text-decoration: none;
                        span {
                            display: inline;
                            border-bottom: 3px solid transparent;
                            @include transition(0.3s);
                            position: relative;
                            z-index: 1;
                            background: linear-gradient(90deg,transparent,transparent),linear-gradient(90deg,#EA6307,#EA6307,#EA6307);
                            background-size: 0px 0 , 0px 3px;
                            background-position: 3px 3px,0px 100%;
                            background-repeat: no-repeat;
                            transition: background-size .6s;
                            background: none;
                        }
                        &:hover {
                            color: $cyber-grape;
                            span {
                                @include transition(0.3s);
                                background-size: 0 3px,100% 3px;
                            }
                        }
                    }
                }
            }
            .text-div ,
            .informative-data{
                ul {
                    margin-bottom: 20px;
                    column-count: 1;
                    li {
                        list-style: none;
                        margin: 0px 0px 19px;
                        padding: 0px 0px 0px 25px;
                        position: relative;
                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 12px;
                            height: 6px;
                            width: 6px;
                            border-radius: 0;
                            background: $secondary-color;
                        }
                    }
                }
            }
        }
    }
}
.practice-tab {
    justify-content: center;
    margin-bottom: 88px;
    @include media-breakpoint-down(md) {
        margin-bottom: 60px;
    }
}
.filter-wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    align-items: center;
    @include media-breakpoint-down(md) {
        display: block;
    }
    .search-box {
        margin-bottom: 0;
        width: 90%;
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
    .filter-reset {
        padding-top: 0;
        @include media-breakpoint-down(md) {
            padding-top: 10px;
        }
    }
}
.prac-listing {
    .tabbing-data {
        .filter-wrap  {
            visibility: visible !important;
        }
    }
    .filter-tabbing {
        .filter-data ,
        .filter{
            visibility: visible !important;
        }
    }
}