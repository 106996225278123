.alumni-connect {
    padding: 80px 0px 70px;

    @include media-breakpoint-down(lg) { 
        padding: 70px 0px 20px;
    }
    @include media-breakpoint-down(sm) { 
        padding: 55px 0px 30px;
    }
    
    blockquote {
        border: 1px solid $gainsboro;
        margin: 60px 0px;
        padding: 30px 32px;
        @include body-font;

        @include media-breakpoint-down(sm) { 
            margin: 30px 0px 40px;            
        }

        a {
            font-family: $font-medium;
            text-decoration: underline;
            color: $rocket-metallic;
            &:hover {
                text-decoration: underline;
                color: $cyber-grape;
            }
        }
    }
    
    .Willkie-connect {
        background: $cultured;
        padding: 45px 34px;

        @include media-breakpoint-down(sm) {
            padding: 35px 30px;    
        }
    
        .btn-primary {
            min-width: 255px;
            background: $secondary-color;
            &:active,
            &:focus {
                background: $secondary-color;
                border-color: $secondary-color;
                color: $secondary-color;
            }
            &:hover {
                color: $secondary-color;
                border-color: $secondary-color;
                background: none;
            }
            &:focus {
                color: $white-color;
                &:hover {
                    color: $secondary-color;
                    border-color: $secondary-color;
                }
            }

            @include media-breakpoint-down(sm) {
                min-width: 230px;    
            }
    
            svg {
                margin-left: 8px;
                vertical-align: initial;
            }
    
            &:hover {
                svg * {
                    fill: $secondary-color;
                }
            }
        }
        .h3 {
            font-size: 40px;
            line-height: 50px;
            letter-spacing: -0.13px;
            @media(max-width: 1599px) {
                font-size: 28px;
                line-height: 40px;
                letter-spacing: -0.09px;
            }
            @media(max-width: 1199px) {
                line-height: 30px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 22px;
            }
        }
    }
}

.alumni-spotlight {
    @include media-breakpoint-down(sm) { 
        padding: 0px 0px 40px;
    }

    h4 {
        color: $rich-black;
        margin: 0px 0px 10px;
    }
    h2 {
        color: $light-black;
    }
    .continue-reading {
        padding-left: 32px;
        position: relative;
        line-height: 26px;
        display: inline-block;
        text-decoration: none;
        color: $primary-color;
        cursor: pointer;

        &:after {
            content: "";
            background: url(../img/secondary-plus.svg) no-repeat center center;
            height: 24px;
            width: 24px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
        }

        &.read-less {
            &:after {
                background: url(../img/minus.svg) no-repeat center center;
                transform: rotate(90deg);
            }
        }
    }

    ~ .professional-list{
        padding-top: 80px;
        @include media-breakpoint-down(sm){
            padding-top: 40px;
        }
    }
}
.read-more ,
.read-less {
    padding: 0 0 0 30px;
    color: $rocket-metallic;
    margin: 0;
    position: relative;
    display: inline-block;
    letter-spacing: normal;
    background: none;
    min-width: 0;
    border: 0;
    font-size: 17px;
    line-height: 23px;
    font-family: $font-medium;
    text-decoration: none;
    &:hover {
        color: $rocket-metallic;
        text-decoration: none;
    }
}
.read-more {
    &:after {
        content: "";
        background: url(../img/secondary-plus-gray.svg) no-repeat center center;
        background-size: 100%;
        height: 22px;
        width: 22px;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
    }
}
.read-less {
    &:after {
        background: url(../img/minus-gray.svg) no-repeat center center;
        background-size: 100%;
        transform: rotate(90deg);
        content: "";
        height: 22px;
        width: 22px;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
    }
}
.alumni-img {
    padding-right: 85px;
    @include media-breakpoint-down(md) {
        padding-right: 15px;
    }
    img {
        width: 100%;
        height: auto;
    }
}

.alumni-spotlight {
    .default-text {
        margin-bottom: 20px;
        p:last-child {
            margin-bottom: 0;
        }
    }
    .toggle-text {
        margin-bottom: 20px;
        display: none;
        p:last-child {
            margin-bottom: 0;
        }
    }
    .plus-link {
        position: relative;
        padding: 0 0 0 30px;
        color: #79757F;
        margin: 0;
        position: relative;
        display: inline-block;
        letter-spacing: normal;
        background: none;
        min-width: 0;
        border: 0;
        font-size: 17px;
        line-height: 23px;
        font-family: "Heebo-Medium", sans-serif;
        text-decoration: none;
        &:before {
            content: "";
            background: url('../img/secondary-plus-gray.svg') no-repeat center center;
            background-size: 100%;
            height: 22px;
            width: 22px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
        }
        &.minus-link:before {
            background: url('../img/minus-gray.svg') no-repeat center center;
            background-size: 100%;
            transform: rotate(90deg);
            content: "";
            height: 22px;
            width: 22px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
        }
    }
}