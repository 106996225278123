@font-face {
    font-family: "Heebo-Medium";
    src: url('../fonts/Heebo-Medium.woff') format('woff'),
        url('../fonts/Heebo-Medium.woff2') format('woff2'),
        url('../fonts/Heebo-Medium.svg#Heebo-Medium') format('svg'),
        url('../fonts/Heebo-Medium.eot'),
        url('../fonts/Heebo-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Heebo-Medium.ttf') format('truetype');

    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Heebo-Regular";
    src: url('../fonts/Heebo-Regular.woff') format('woff'),
        url('../fonts/Heebo-Regular.woff2') format('woff2'),
        url('../fonts/Heebo-Regular.svg#Heebo-Regular') format('svg'),
        url('../fonts/Heebo-Regular.eot'),
        url('../fonts/Heebo-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Heebo-Regular.ttf') format('truetype');

    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Heebo-Bold";
    src: url('../fonts/Heebo-Bold.woff') format('woff'),
        url('../fonts/Heebo-Bold.woff2') format('woff2'),
        url('../fonts/Heebo-Bold.svg#Heebo-Bold') format('svg'),
        url('../fonts/Heebo-Bold.eot'),
        url('../fonts/Heebo-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Heebo-Bold.ttf') format('truetype');

    font-weight: normal;
    font-style: normal;
    font-display: swap;
}