.search-page {
    padding: 100px 0 0;
    @include media-breakpoint-down(md) {
        padding-top: 80px;
        .breadcrumbs {
            margin-bottom: 20px;
        }
    }
    h1 {
        color: $gray;
    }

    .filter {
        max-width: 100%;
        margin: 0px auto 45px !important;
    }

    .filter-bar {
        padding: 0 0 0 0;

        .row {
            @include media-breakpoint-up(lg) {
                margin-right: -20px;
                margin-left: -20px;
            }

            [class*="col-"] {
                @include media-breakpoint-up(lg) {
                    padding-left: 20px;
                    padding-right: 20px;
                }

                .cmn-select {
                    margin-bottom: 20px;
                }
            }
            &.inner-column-five{
                margin-right: -12px;
                margin-left: -12px;
                .column-five{
                    flex: 0 0 20%;
                    max-width: 20%;
                    padding-left: 12px;
                    padding-right: 12px;
                    @include media-breakpoint-down(md){
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                    @include media-breakpoint-down(xs){
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }
        }
        .filter-reset {
            text-align: center;
            @include media-breakpoint-down(md) {
                text-align: left;
            }
        }
    }

    .team-slider {
        @include media-breakpoint-down(xs){
            margin-left: -10px;
            margin-right: -10px;
        }
        .team-slide {
            margin: 0 14px 30px 14px;
            @include media-breakpoint-up(lg){
                width: 172px;
            }
            @include media-breakpoint-down(xs){
                margin-left: 10px;
                margin-right: 10px;
            }

            .team {
                position: relative;
                overflow: hidden;

                .team-img {
                    height: 142px;
                    overflow: hidden;
                    display: -webkit-flex;
                    display: flex;
                    -webkit-align-content: center;
                    align-content: center;
                    -webkit-justify-content: center;
                    justify-content: center;
                    margin-bottom: 8px;
                    position: relative;

                    @media screen and (min-width:1920px) {
                        height: 140px;
                    }

                    @include media-breakpoint-down(lg) {
                        height: 128px;
                    }
                    @include media-breakpoint-down(md) {
                        height: 165px;
                    }

                    @include media-breakpoint-down(xs) {
                        height: 140px;
                    }

                    .divImg {
                        // background-color: $white-color;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        z-index: 0;
                        bottom: 0;
                        @include transition(0.3s);
                        right: 0;
                        height: 100%;
                        background-size: contain;
                        transform: scale(1) translateZ(0);
                        transform-origin: top center;

                        @include media-breakpoint-down(sm) {
                            background-size: contain;
                        }
                    }

                    img {
                        max-height: 142px;
                        display: block;

                        @media screen and (min-width:1920px) {
                            max-height: 140px;
                        }
                        @include media-breakpoint-down(lg) {
                            max-height: 128px;
                        }
                        @include media-breakpoint-down(md) {
                            max-height: 165px;
                        }

                        @include media-breakpoint-down(sm) {
                            max-height: 197px;
                        }

                        @include media-breakpoint-down(xs) {
                            max-height: 140px;
                        }
                    }
                }

                .h5 {
                    color: $rich-black;
                    text-decoration: none;
                    margin-bottom: 4px;
                    display: block;
                    font-size: 19px;
                    line-height: 28px;
                }

                span.text-14 {
                    color: #404040;
                    @media screen and (min-width:1920px) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                .location {
                    text-decoration: none;
                    @extend .text-14;
                    display: inline-block;
                    margin-top: 8px;
                    position: relative;
                    z-index: 2;

                    &:hover {
                        color: $light-black;
                    }
                }

                &:hover {
                    .team-img {
                        img {
                            background-size: contain;
                            transform: scale(1.05) translateZ(0);
                            transform-origin: top center;

                            @include media-breakpoint-down(sm) {
                                background-size: contain;
                            }
                        }
                    }
                    span.text-14 {
                        color: $cyber-grape;
                    }

                    .h5 {
                        color: $cyber-grape;
                        mark {
                            color: $cyber-grape !important;
                        }
                    }
                }

                &.content-hover {
                    .h5 {
                        color: $light-black;
                    }
                }
            }
        }

        .slick-dots {
            margin: 0;
            padding: 18px 15px 0 15px;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;

            li {
                margin: 0 8px 0 0 !important;
                padding: 0 !important;
                line-height: 0;

                &:before {
                    content: normal;
                }

                button {
                    font-size: 0;
                    padding: 0;
                    box-shadow: none;
                    height: 8px;
                    width: 8px;
                    border: 2px solid $rocket-metallic;
                    border-radius: 100%;
                    outline: none;
                    background: $rocket-metallic;
                }

                &.slick-active {
                    button {
                        background: transparent;
                    }
                }
            }
        }
    }

    .news-list-wrap {
        padding: 60px 0 0;

        .news-list {
            padding: 29px 0;
            position: relative;

            .news-img {
                width: 169px;
                max-width: unset;
                span {
                    background: url('../img/Willkie_atty_photos_bckgd_2_full.jpg') no-repeat 0 0;
                    background-size: cover;
                    display: block;
                    width: 169px;
                    height: 139px;
                }

                img {
                    max-width: 169px;
                }
            }
        }
    }
    .team-slider {
        .team-slide {
            .team {
                .team-img {
                    background: url('../img/Willkie_atty_photos_bckgd_2_full.jpg') no-repeat 0 0;
                    background-size: cover;
                }
            }
        }
    }
    .wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @include media-breakpoint-down(sm) {
            display: block;
        }
        h1 {
            @include media-breakpoint-down(sm) {
                margin-bottom: 15px;
            }
        }
        .filter-reset {
            line-height: normal;
            padding-top: 0;
            @include media-breakpoint-down(sm) {
                margin-bottom: 15px;
            }
            a {
                vertical-align: top;
            }
        }
    }
    .search-box {
        margin-bottom: 25px;
    }
}

.loader {
    .strip-holder {
        img {
            -webkit-animation: rotation 2s infinite linear;
            animation: rotation 2s infinite linear;
        }
    }   
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
    }
}
.filter-search-bar {
    margin-bottom: 27px;
    background: url('../img/new-search-icon.svg') no-repeat left 27px;
    background-size: 16px;
    input {
        background-color: transparent;
        padding-left: 26px;
        font-size: 18px;
        line-height: 30px;
        color: #0e0e0e;
        &:focus {
            background: transparent;
            color: #0e0e0e;
        }
    }
}