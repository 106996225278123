
.not-found {

    .container {
        overflow: hidden;
        padding-top: 10px;
        padding-bottom: 10px;
    }
       
    .ver-strip {
        background: $fern-green;
        display: block;
        margin: auto;
        position: absolute;
        left: 0px;
        right: 0px;
        top: -10px;
        bottom: -10px;
        width: 3px;
        z-index: 0;

        @include media-breakpoint-down(md) { 
            margin: 0px 0px 30px;
            position: relative;
            top: 0px;
            bottom: 0px;
            height: 1px;
            width: 100%;
        }
    }
}