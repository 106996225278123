 .location-list {
    padding: 130px 0px 100px;

    @include media-breakpoint-down(md) { 
        padding: 84px 0px 40px;
    }
    @include media-breakpoint-down(sm) { 
        padding-top: 84px;
        .breadcrumbs{
            margin-bottom: 20px;
        }
    }
     
    .location-grid {
        border: 1px solid $gainsboro;
        margin: 0px 0px 30px;
        height: calc(100% - 30px);
        width: 100%;
        position: relative;

        @include media-breakpoint-down(xs) { 
            margin: 0px 0px 16px;
            height: calc(100% - 16px);
        }

    .location-img {
        overflow: hidden;
        max-width: 270px;
        width: 100%;

        @include media-breakpoint-down(md) { 
            max-width: 100%;
        }

        img {
            @include media-breakpoint-down(md) { 
                width: 100%;
            }
        }
    }

    .location-text {
        //max-width: 298px;
        max-width: 100%;
        padding: 0px;
        width: 100%;
        // position: relative;
        @include media-breakpoint-down(sm){
            padding-top: 0;
        }

        @include media-breakpoint-down(md) { 
            max-width: 100%;
        }

        .view-office {
            display: block;
            // position: relative;
            padding: 26px 30px 24px;

            @include media-breakpoint-down(lg) { 
                padding: 20px 20px 20px;
            }
            @include media-breakpoint-down(sm) { 
                padding: 20px 30px 20px;
            }
            
            h3 {
                margin: 0px 0px 8px;
                color: $rich-black;
                @include media-breakpoint-down(sm){
                    margin-bottom: 4px;
                }
            }
            address {
                color: $rocket-metallic;
                font-style: normal;
                margin: 0px;

                a {
                    color: $rocket-metallic;
                    display: block;
                    margin: 3px 0px 0px;
                    text-decoration: underline;
                }
            }

            &:hover {
                h3 {
                    color: $cyber-grape;
                }
            }
        }

        .contact-office {
            display: block;
            position: relative;
            padding: 0px 30px 20px;
            z-index: 1;

            @include media-breakpoint-down(lg) { 
                padding: 0px 20px 15px;
            }
            @include media-breakpoint-down(sm){
                padding-bottom: 20px;
                padding-left: 30px;
            }

            a {
                display: block;
                margin-bottom: 6px;
                padding-left: 25px;
                color: $rich-black;
                position: relative;
                text-decoration: none;
                @include media-breakpoint-down(sm){
                    margin-bottom: 12px;
                }
                span {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 0;
                    img {
                        max-height: 16px;
                        max-width: 13.5px;
                    }
                    svg {
                        * {
                            stroke: $rocket-metallic;
                        }
                    }
                }
                &:hover {
                    color: $cyber-grape;
                    span {
                        svg {
                            * {
                                stroke: $cyber-grape;
                            }
                        }
                    }
                }
            }
            ul{
                position: relative;
                margin: 0;
                width: 100%;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                top: 0;
                border-bottom: 1px solid $gainsboro;
                @include media-breakpoint-up(md){
                    display: none
                }
                @include media-breakpoint-down(sm) {
                    z-index: 9;
                    border: 0;
                }
                li {
                    margin: 0;
                    width: 50%;
                    padding: 0;
                    &:first-child{
                        border-right: 1px solid $gainsboro;
                        @include media-breakpoint-down(sm) {
                            border: 0;
                        }
                    }
                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                    a{
                        padding: 10px 13px;
                        text-decoration: none;
                        font-family: $font-regular;
                        color: $rich-black;
                        display: block;
                        @include media-breakpoint-down(sm) {
                            padding: 0 0;
                        }
                        img{
                            margin-right: 7px;
                            display: inline-block;
                        }
                    }
                }            
            }
        }
        
    }
    }
}
 