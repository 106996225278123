.cmn-box-img-bg {
    margin-bottom: 90px;

    @include media-breakpoint-down(md) {
        padding: 42px 0 42px 0;
        margin-bottom: 44px;
    }

    .awards-recognition-boxes {
        [class*="col-"] {
            @include media-breakpoint-down(md) {
                margin-bottom: 16px;
            }
            @include media-breakpoint-only(md) {
                &:nth-child(2n + 1) {
                    animation-delay: 0.2s !important;
                }
    
                &:nth-child(2n + 2) {
                    animation-delay: 0.4s !important;
                }
            }
            @include media-breakpoint-down(sm) {
                    animation-delay: 0.2s !important;
            }
            .box-with-image {
                position: relative;
                padding: 40px;
                min-height: 361px;

                @media(min-width: 1919.98px) {
                    min-height: 475px;
                }

                @include media-breakpoint-down(lg) {
                    padding: 25px;
                }

                @include media-breakpoint-down(md) {
                    padding: 40px;
                }

                @include media-breakpoint-down(sm) {
                    padding: 37px 38px;
                    min-height: 182px;
                }

                &:before {
                    position: absolute;
                    content: "";
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: 1;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.9) 100%);
                    bottom: 0;
                }

                &:after {
                    position: absolute;
                    content: "";
                    background: $secondary-color;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: -1;
                    bottom: 0;
                }

                .divImg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    background-position: 50%;
                }

                h6,
                h5 {
                    position: relative;
                    z-index: 1;
                    color: #ffffff;
                }

                h6 {
                    margin: 0 0 11px 0;
                }

                .h1 {
                    position: relative;
                    z-index: 2;
                    color: $white-color;
                    max-width: 545px;
                    margin: 0 0 16px 0;
                }

                h2 {
                    position: relative;
                    z-index: 2;
                    color: $white-color;
                    max-width: 418px;
                    margin: 0 0 16px 0;
                }

                &:hover {
                    &:before {
                        z-index: 1;
                    }

                    &:after {
                        z-index: 1;
                        opacity: 1;
                    }

                    h5,
                    h6 {
                        z-index: 2;
                    }
                    .avatar-div{
                        opacity: 0;
                        visibility: hidden;
                    }
                    svg{
                        opacity: 1 !important;
                        visibility: visible !important;
                    }

                    .stretched-link {
                        &:after {
                            z-index: 3;
                        }
                    }
                }

                svg {
                    height: 26px !important;
                    width: 26px !important;
                    position: relative;
                    z-index: 2;
                }
                &:active,
                &:focus {
                    &:after {
                        background: $cyber-grape;
                    }
                }
            }

            &.col-lg-8 {
                .box-with-image {
                    @include media-breakpoint-down(sm) {
                        min-height: 300px !important;
                    }
                    .divImg {
                        background-position: top left;
                    }

                    &:before {
                        background: rgb(0, 0, 0);
                        background: -moz-radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                        background: -webkit-radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                        background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
                        opacity: 0.6;
                        @include media-breakpoint-down(md){
                            opacity: 1;
                        }
                    }
                }
            }

            &.col-lg-12 {
                .box-with-image {
                    min-height: 385px;
                    @include media-breakpoint-down(sm) {
                        min-height: 300px !important;
                    }

                    @include media-breakpoint-down(sm) {
                        min-height: 235px;
                    }

                    .divImg {
                        background-position: top left;
                    }

                    &:before {
                        background: rgb(0, 0, 0);
                        background: -moz-radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                        background: -webkit-radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                        background: radial-gradient(circle, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
                        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
                        opacity: 0.6;
                        @include media-breakpoint-down(md){
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .white-bg {
            .divImg {
                display: none;
            }
            .box-with-image {
                border: 1px solid $gainsboro;

                &:before {
                    position: absolute;
                    content: "";
                    background: $secondary-color;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    z-index: 0;
                    bottom: 0;
                }

                &:after {
                    content: none;
                }

                &:before {
                    opacity: 0;
                    @include transition(0.3s);
                }

                h6 {
                    color: $rich-black;
                }

                h5 {
                    color: $rich-black;
                }

                &:hover {
                    &:before {
                        opacity: 1;
                        @include transition(0.3s);
                    }

                    h6 {
                        color: $white-color;
                    }

                    h5 {
                        color: $white-color;
                    }
                }

                &:focus,
                &:active {
                    &:before {
                        background: $cyber-grape;
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }
        }
    }

    &.hp-cmn-boxes {
        padding: 117px 0 180px 0;
        margin: 0;
        position: relative;
        z-index: 2;
        background: $white-color;

        @include media-breakpoint-down(md) {
            padding-bottom: 80px;
        }

        .awards-recognition-boxes {
            [class*="col-"] {
                margin-bottom: 30px;

                @include media-breakpoint-down(sm) {
                    margin-bottom: 16px;
                }
                h2 {
                    margin-bottom: 68px;
                }
                .box-with-image {
                    min-height: 320px;
                    height: 100%;
                    @include media-breakpoint-down(sm){
                        min-height: 182px;
                    }

                    &.avatar-img-wrap {
                        padding-bottom: 100px;
                        @include media-breakpoint-down(sm){
                            padding-bottom: 106px;
                        }

                        .avatar-div {
                            position: absolute;
                            z-index: 2;
                            margin: 0;
                            bottom: 40px;
                            left: 40px;
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: row;
                            @include media-breakpoint-only(lg){
                                left: 30px;
                            }
                            span{
                                display: flex;
                                margin-left: -20px;
                                width: auto;
                                height: 60px;
                                width: 60px;
                                border-radius: 100%;
                                position: relative;
                                background: $cultured;
                                align-content: baseline;
                                overflow: hidden;
                                align-items: self-end;
                                &:first-child{
                                    margin-left: 0;
                                }
                                img{
                                    height: auto;
                                    max-height: 60px;
                                    border-radius: 100%;
                                }
                            }
                        }
                        svg{
                            position: absolute;
                            opacity: 0;
                            visibility: hidden;
                        }
                    }
                    &:active,
                    &:focus {
                        &:after {
                            background: $cyber-grape;
                        }
                    }
                }

                &.white-bg {
                    .box-with-image {
                        border: 1px solid $gainsboro;
                        min-height: 272px;
                        height: 100%;
                        padding: 40px 40px 23px 40px;

                        &:before {
                            position: absolute;
                            content: "";
                            background: $secondary-color;
                            height: 100%;
                            width: 100%;
                            top: 0;
                            left: 0;
                            right: 0;
                            z-index: 0;
                            bottom: 0;
                        }

                        @media(min-width: 1919.98px) {
                            min-height: 338px;
                        }

                        @include media-breakpoint-down(sm) {
                            padding: 40px 38px 30px;
                            min-height: 215px;
                        }

                        &:after {
                            content: none;
                        }

                        &:before {
                            opacity: 0;
                            @include transition(0.3s);
                        }

                        h6 {
                            color: $rich-black;
                        }

                        h5 {
                            color: $rich-black;
                        }

                        &:hover {
                            &:before {
                                opacity: 1;
                                @include transition(0.3s);
                            }

                            h6 {
                                color: $white-color;
                            }

                            h5 {
                                color: $white-color;
                            }
                        }

                        &:focus,
                        &:active {
                            &:before {
                                background: $cyber-grape;
                                opacity: 1;
                                z-index: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}

.cmn-box-img-bg {
    .awards-recognition-boxes {
        [class*="col-"] {
            .insight-wrap {
                svg {
                    position: absolute;
                    bottom: 0;
                    z-index: 1;
                    height: 26px !important;
                    width: 26px !important;
                }
            }
        }
    }
}

.cmn-box-img-bg.hp-cmn-boxes {
    .awards-recognition-boxes {
        [class*="col-"] {
            &.white-bg {
                .box-with-image {
                    &.avatar-img-wrap {
                        padding-bottom: 100px;
                        @include media-breakpoint-down(sm){
                            padding-bottom: 106px;
                        }
                    }
                }
            }
        }
    }
}
.cmn-social-section {
    [class*="col-"] {
        .cmn-social-inner {
            &.img-wrap {
                &:after {
                    @include media-breakpoint-down(sm) {
                        transform: translateY(0%) !important;
                    }
                }
                .cmn-social-content {
                    @include media-breakpoint-down(sm) {
                        transform: translateY(0%) !important;
                    }
                }
            }
        }
    }
}