.intro-section {
    padding: 180px 0 210px 0;
    position: relative;
    z-index: 2;
    background: $white-color;

    @include media-breakpoint-down(md) {
        padding: 80px 0;
        text-align: center;
    }

    .container {
        overflow: hidden;
        padding-top: 10px;
        padding-bottom: 10px;
        @include media-breakpoint-down(md){
            padding-top: 0px;
            padding-bottom: 0px;

        }

        .col-left {
            width: 300px;
            padding: 0 15px;
            @include media-breakpoint-down(lg){
                width: 30%;
            }
            @include media-breakpoint-down(md){
                width: 100%;
            }
            h2{
                @include media-breakpoint-down(md){
                    margin-bottom: 13px;
                    width: 100%;
                }
            }
        }

        .col-strip {
            width: 84px;
            position: relative;
            @include media-breakpoint-down(lg){
                width: 10%;
            }
            @include media-breakpoint-down(md){
                width: 60px;
                margin: 0px auto;
            }

            i {
                background: $fern-green;
                display: block;
                margin: auto;
                position: absolute;
                left: 0px;
                right: 0px;
                top: -10px;
                bottom: -10px;
                width: 3px;
                z-index: 0;

                @include media-breakpoint-down(md) {
                    margin: 0px 0px 16px;
                    position: relative;
                    top: 0px;
                    bottom: 0px;
                    height: 3px;
                    width: 100%;
                }
            }
        }

        .col-right {
            width: 600px;
            padding: 0 15px;
            @include media-breakpoint-down(lg){
                width: 60%;
            }
            @include media-breakpoint-down(md){
                width: 100%;
            }

            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

}