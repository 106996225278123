.social-inner-banner {
    // min-height: 800px;
    height: calc(100vh - 88px);
    padding-top: 82px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include media-breakpoint-down(md) {
        padding: 82px 0 0 0;
        display: flex;
        flex-wrap: wrap;
        height: auto;
        align-items: flex-start;
    }
    @include media-breakpoint-down(sm) {
        padding-top: 80px;
    }
    .container {
        padding: 0 15px;
        @include media-breakpoint-down(md) {
            padding: 0;
        }
    }
    .banner-data {
        @include media-breakpoint-down(md) {
            // padding: 0;
        }
        h1 {
            margin: 0 0 3px 0;
            @include media-breakpoint-down(md)  {
                // margin: 0;
            }
        }
        &:before {
            bottom: -38px;
        }
        span {
            margin-top: 20px;
            display: block;
        }
        .btn-primary-link {
            color: $white-color;
            &:hover {
                color: $rocket-metallic;
            }
        }
    }

}
.social-diversity {
    &.cmn-tab {
        padding-bottom: 20px;
    }
    .social-intro {
        padding: 32px 0 70px 0;
        @include media-breakpoint-down(md) {
            padding: 0 0 0 0;
        }
    }
    .diversity-intro {
        padding-bottom: 70px;
        @include media-breakpoint-down(md) {
            padding-bottom: 40px;
        }
        h2 {
            padding: 11px 0;
        }
        p {
            &:last-child {
                margin: 0;
            }
        }
    }
    .diversity-strategy {
        padding-top: 70px;
        padding-bottom: 55px;
        @include media-breakpoint-down(md) {
            padding-top: 40px;
            padding-bottom: 10px;
        }
        .diversity-stats {
            @include media-breakpoint-down(md) {
                padding-bottom: 80px;
            }
            h4 {
                margin:-4px 0 10px 0;
            }
            ul {
                margin: 40px 0 0;
                border-top: 1px solid rgba(54, 50, 59, 0.15);
                li {
                    padding: 23px 0;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    border-bottom: 1px solid rgba(54, 50, 59, 0.15);
                    @include media-breakpoint-down(md) {
                        padding: 15px 0;
                    }
                    &:before {
                        content: none;
                    }
                    h3 {
                        margin: 0 12px 0 0;
                        color: $spanish-orange;
                        font-family: $font-medium;
                    }
                }
            }
        }
    }
    .cmn-box-img-bg {
        margin-bottom: 0;
        padding-bottom: 70px;
        padding-top: 55px;
        @include media-breakpoint-down(md) {
            padding-top: 40px;
            padding-bottom: 24px;
        }
    }
}
.diversity-contact {
    &.professional-list {
        padding-top: 70px;
        padding-bottom: 0;
        @include media-breakpoint-down(md) {
            padding-top: 30px;
        }
        h3 {
            margin: 0 0 38px 0;
        }
        [class*="col-"] {
            @include media-breakpoint-down(sm) {
                margin-bottom: 40px;
            }
            .team {
                .contact-info {
                    @include media-breakpoint-down(sm){
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                    }
                    a {
                        @include media-breakpoint-down(sm) {
                            font-size: 0;
                            padding: 0;
                            width: 20px;
                            margin-right: 16px;
                            margin-bottom: 0;
                            height: 20px;
                        }
                        span {
                            @include media-breakpoint-down(sm) {
                                height: 20px;
                                width: 20px;
                            }
                            img {
                                @include media-breakpoint-down(sm) {
                                    height: 20px;
                                    width: 20px;
                                    max-height: 20px;
                                    max-width: 20px;
                                }
                            }
                        }
                    }
                }
                .team-img {
                    @include media-breakpoint-down(xs) {
                        height: 142px;
                    }
                    img {
                        @include media-breakpoint-down(xs) {
                            max-height: 142px;
                        }
                    }
                }
            }
        }
    }
}
.cmn-social-navigation {
    border-top: 1px solid $gainsboro;
    padding: 0;
    margin-top: 80px;
    position: relative;
    @include media-breakpoint-down(sm) {
        margin-top: 60px;
    }
    .container {
        max-width: 100%;
        // padding: 0;
        .row {
            > div {
                padding: 0;
                border-right: 1px solid #DFDFDF;
                
                &:last-child {
                    border: 0;
                }
                @include media-breakpoint-down(lg) {
                    flex: 0 0 50%;
                    max-width: 50%;
                    border-bottom: 1px solid #DFDFDF;
                }
                &:nth-child(2) {
                    @include media-breakpoint-down(lg) {
                        border-right: 0;
                    }
                }
                @include media-breakpoint-down(sm) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                &:nth-child(1),
                &:nth-child(3) {
                    @include media-breakpoint-down(sm) {
                        border-right: 0;
                    }
                }
            }
        }
    }
    // &:before {
    //     content: "";
    //     height: 100%;
    //     width: 1px;
    //     background: $gainsboro;
    //     position: absolute;
    //     top: 0;
    //     bottom: 0;
    //     z-index: 1;
    //     left: 50%;
    //     @include media-breakpoint-down(md) {
    //         content: "";
    //         height: 1px;
    //         top: 0;
    //         bottom: 0;
    //         left: 0;
    //         width: 100%;
    //         top: 50%;
    //     }
    // }
    .social-navigation-block {
        text-decoration: none;
        background: $white-color;
        height: 100%;
        padding: 60px 85px 60px 85px;
        @include transition(1s);
        @media(max-width: 1600px) {
            padding: 60px 40px;
        }
        @include media-breakpoint-down(lg) {
            padding: 30px 20px;
            min-height: 0;
        }
        @include media-breakpoint-down(md) {
            padding: 40px 15px 40px 15px;
            min-height: 0;
        }
        @include media-breakpoint-down(sm) {
            padding: 20px;
        }
        display: block;
        &.text-right {
            padding-right: 100px;
            padding-left: 0;
            @include media-breakpoint-down(lg) {
                padding-left: 50px;
            }
            @include media-breakpoint-down(md) {
                padding: 40px 15px 40px 15px;
            }
            svg {
                transform: rotate(-180deg) !important;
            }
        }
        span {
            //color: rgba(255, 255, 255, 0.6);
            color: rgba(94, 127, 92, 0.6);
            display: block;
            margin: 0 0 8px 0;
        }
        h3 {
            margin: 0 0 0 0;
            color: $fern-green;
            position: relative;
            @include media-breakpoint-down(md) {
                margin: 0 0 10px 0;
            }
        }
        &:hover ,
        &.active{
            background: $fern-green;
            span {
                color: rgba(255, 255, 255,0.6);
            }
            h3 {
                color: $white-color;
            }
            svg {
                * {
                    stroke: $white-color;
                }
            }
        }
        &.active {
            svg {
                * {
                    stroke: $fern-green;
                }
            }
        }
        svg {
            height: 17px !important;
            width: 27px !important;
            * {
                stroke: $rocket-metallic;
            }
        }
    }
}
.img-text-loop-title {
    h2 {
        margin: 0 0 28px 0;
    }
}
.img-text-loop {
    padding-top: 70px;
    padding-bottom: 70px;
    @include media-breakpoint-down(md) {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .img-text {
        @include media-breakpoint-down(md) {
            margin-bottom: 40px;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    h3 {
        margin-bottom: 26px
    }
    .img-div {
        @include media-breakpoint-down(sm) {
            order: 1;
        }
    }
    .text-div {
        @include media-breakpoint-down(sm) {
            order: 2;
        }
    }
    .left-img-div {
        min-height: 534px;
        @media(min-width: 1919.98px) {
            min-height: 640px;
        }
        @include media-breakpoint-down(lg) {
            min-height: 453px;
        }
        @include media-breakpoint-down(md) {
            min-height: 315px;
        }
        @include media-breakpoint-down(sm) {
            min-height: 0;
        }
        .divImg {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
        }
        .img-text-back-img {
            position: relative;
            width: 470px;
            height: 412px;
            left: 0;
            @media(min-width: 1919.98px) {
                width: 580px;
                height: 500px;
            }
            @include media-breakpoint-down(lg) {
                width: 380px;
                height: 333px;
            }
            @include media-breakpoint-down(md) {
                width: 280px;
                height: 245px;
            }
            @include media-breakpoint-down(sm) {
                width: 80%;
                height: 375px;
            }
            @include media-breakpoint-down(xs) {
                width: 87%;
                height: 259px;
            }
        }
        .img-text-front-img {
            position: absolute;
            width: 470px;
            height: 272px;
            bottom: 0;
            right: 15px;
            @media(min-width: 1919.98px) {
                width: 580px;
                height: 340px;
            }
            @include media-breakpoint-down(lg) {
                width: 380px;
                height: 220px;
            }
            @include media-breakpoint-down(md) {
                width: 280px;
                height: 163px;
            }
            @include media-breakpoint-down(sm) {
                width: 80%;
                position: relative;
                height: 233px;
                right: 0;
                margin-top: -130px;
                margin-left: auto;
            }
            @include media-breakpoint-down(xs) {
                width: 87%;
                height: 171px;
                margin-top: -90px;
            }
        }
    }
    .right-img-div {
        min-height: 500px;
        @media(min-width: 1919.98px) {
            min-height: 770px;
        }
        @include media-breakpoint-down(md) {
            min-height: 410px;
        }
        @include media-breakpoint-down(sm) {
            min-height: 0;
        }
        .divImg {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
        }
        .img-text-back-img {
            position: relative;
            width: 371px;
            margin-left: auto;
            height: 500px;
            @media(min-width: 1919.98px) {
                width: 570px;
                height: 770px;
            }
            @include media-breakpoint-down(md) {
                width: 281px;
                height: 410px;
            }
            @include media-breakpoint-down(sm) {
                width: 65%;
                height: 445px;
            }
            @include media-breakpoint-down(xs) {
                height: 313px;
                width: 68.4%;
            }
        }
        .img-text-front-img {
            position: absolute;
            width: 370px;
            height: 240px;
            left: 15px;
            bottom: 130px;
            @media(min-width: 1919.98px) {
                width: 570px;
                height: 370px;
                bottom: 180px;
            }
            @include media-breakpoint-down(md) {
                width: 281px;
                height: 183px;
                bottom: 114px;
            }
            @include media-breakpoint-down(sm) {
                width: 70%;
                height: 244px;
                bottom: 135px;
            }
            @include media-breakpoint-down(xs) {
                width: 62.8%;
                height: 150px;
                bottom: 125px;
            }
        }
    }
    .left-img-div ,
    .right-img-div {
        .img-text-front-img ,
        .img-text-back-img {
            overflow: hidden;
            .divImg ,
            img{
                @include transition(1.5s);
                -webkit-transform: scale(1);
                transform: scale(1.08);
            }
            &[style="visibility: visible; animation-duration: 1s; animation-delay: 0.2s; animation-name: custom-fadeInUp;"] ,
            &[style="visibility: visible; animation-duration: 1s; animation-name: custom-fadeInUp;"]{
                .divImg ,
                img{
                    -webkit-transform: scale(1);
                    @include transition(1.5s);
                    transform: scale(1);
                }
            }
        }
    }
}
.filter-data {
    .informative-data {
        margin-top: 100px;
        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }
        .parent-profile {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include media-breakpoint-down(md) {
                flex-wrap: wrap;
            }
            .parent-care-img {
                width: 170px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 170px;
                @include media-breakpoint-down(md) {
                    margin-bottom: 20px;
                }
                img {
                    width: 100%;
                    max-height: 170px;
                    height: auto;
                    max-width: 170px;
                    border-radius: 100%;
                }
            }
            .parent-care-data {
                width: calc(100% - 200px);
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
                p {
                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }
    }
}
.women-at-willkie {
    .counter-div {
        border-top: 1px solid rgba(54, 50, 59, 0.15);
        li {
            padding: 23px 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border-bottom: 1px solid rgba(54, 50, 59, 0.15);
            &:before {
                content: none;
            }
            h3 {
                margin: 0 12px 0 0;
                color: #EA6307;
                font-family: $font-medium;
            }
        }
    }
}
.wpdc-section {
    padding-top: 80px;
    padding-bottom: 40px;
    @include media-breakpoint-down(md) {
        padding-top: 40px;
    }
    .wpdc-img {
        padding-right: 70px;
        @include media-breakpoint-down(md) {
            padding-right: 0;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
}
.affinity-group-section {
    .tabbing-data {
        .filter-tabbing {
            .filter-tab2 {
                margin-bottom: 0;
                @include media-breakpoint-down(sm) {}
            }
            .filter-data-tab2 {
                span {
                    color: $gray;
                }
            }
        }
    }
    .diversity-contact {
        &.professional-list {
            padding-top: 60px;
        }
    }
}

.experienced-attorney {
    .filter-data {
        .informative-data {
            margin-top: 0;
        }
    }
    .compensation-benefits {
        &.tabbing-data {
            .filter-tab8 {
                justify-content: center;
            }
        }
    }
    .law-student-discover{
        .tabbing-data {
            &.compensation-benefits {
                padding-top: 0;
            }
        }
    }
}
