.practice-detail-overview {
    .overview-section {
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
            margin-bottom: 20px;
        }
    }
    .cmn-testimonial {
        border-top: 1px solid $gainsboro;
        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }
        .testimonial-text {
            padding: 0 0 0 0;
            margin-top: 51px;
            background: none;
            h5 {
                color: $gray;
            }
        }
    }
    .read-toggle {
        .continue-reading {
            margin-top: 30px;
        }
    }
}
.practice-area-team {
    padding-top: 60px;
    padding-bottom: 60px;
    @include media-breakpoint-down(md) {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    [class*="col-"] {
        margin-bottom: 0;
        @include media-breakpoint-down(sm) {
            margin-bottom: 40px;
        }
        &:last-child {
            @include media-breakpoint-down(sm) {
                margin-bottom: 0;
            }
        }
        .team {
            @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            .team-img {
                @include media-breakpoint-up(lg)  {
                    width: 199px;
                    position: relative;
                    height: 164px;
                }
                @include media-breakpoint-down(md) {
                    height: 263px;
                }
                img {
                    @include media-breakpoint-up(lg)  {
                        max-height: 164px;
                    }
                    @include media-breakpoint-down(md) {
                        max-height: 263px;
                    }
                    @include media-breakpoint-down(sm) {
                        background-position: center center;
                    }
                }
            }
            .team-data {
                @include media-breakpoint-up(lg){
                    width: calc(100% - 227px);
                }
                
            }
        }
    }
    .btn {
        margin-top: 42px;
        @include media-breakpoint-down(sm) {
            margin-top: 0;
        }
    }
}
.practice-area-accordion {
    .continue-reading {
        padding-left: 32px;
        position: relative;
        line-height: 26px;
        display: inline-block;
        margin-bottom: 51px;
        text-decoration: none;
        &:after {
            content: none;
            background: url(../img/secondary-plus.svg) no-repeat center center;
            height: 24px;
            width: 24px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
        }
        &.open {
            &:after {
                background: url(../img/minus.svg) no-repeat center center;
                transform: rotate(90deg);
            }
        }
    }
    .accordion-data {
        .card-body {
            .accordion-height {
                max-height: 205px;
                overflow: hidden;
                @include media-breakpoint-down(lg) {
                    max-height: 244px;
                }
                @include media-breakpoint-down(md) {
                    max-height: 298px;
                    margin-bottom: 20px;
                }
                &.open {
                    max-height: unset;
                    margin-bottom: 0;
                }
            }
        }
    }
}
.arrowbox {
    [class*="col-"] {
        .cmn-box-inner {
            padding-bottom: 5px;
            h5 {
                margin-bottom: 16px;
            }
        }
    }
    .hoverArrow {
        width: 26px;
        height: 26px;
        svg {
            width: 26px !important;
            height: 26px !important;
            * {
                stroke: $primary-color;
            }
        }
    }
}
.industry-detail {
    .overview-section {
        max-height: unset;
        overflow: visible;
    }
}

.filter-data {
    &.current {
        .wow {
            visibility: visible !important;
        }
    }
}
section.editor-div {
    padding: 60px 0;
}
.professional-list {
    &.practice-area-team {
        [class*="col-"] {
            &:nth-child(2n + 1) {
                animation-delay: 0s !important;
                animation-duration: 1s !important;
            }
        }
    }
}