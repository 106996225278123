// Font Family
$font-regular: 'Heebo-Regular', sans-serif;
$font-medium: 'Heebo-Medium', sans-serif;
$font-bold: 'Heebo-Bold', sans-serif;

// Fluid Fonts
$min_width: 390px;
$max_width: 1600px;
$min_font: 18px;
$max_font: 18px;
@mixin body-font {
  font-family: $font-regular;
  font-weight: normal;
  line-height: 1.66;
  font-size: 18px;
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
  @media(min-width: 1919.98px) {
    font-size: 22px;
    line-height: 37px;
    letter-spacing: normal;
  }
}

// Font-size as h1-h6 headings
//h1
@mixin font-xl {
  font-family: $font-regular;
  font-weight: normal;
  line-height: 1.192;
  margin-bottom: 40px;
  letter-spacing: -0.26px;
  @include fluid-type($min_width, $max_width, 36px, 52px);
  @media(min-width: 1919.98px) {
    font-size: 66px;
    line-height: 80px;
    letter-spacing: -0.33px;
  }
  @include media-breakpoint-down(sm) {
    line-height: 1.277;
    margin-bottom: 30px;
    letter-spacing: -0.18px;
  }
}

//h2
@mixin font-lg { 
  font-family: $font-regular;
  font-weight: normal;
  line-height: 1.25;
  margin-bottom: 32px;
  letter-spacing: -0.15px;
  @include fluid-type($min_width, $max_width, 30px, 40px);
  @media(min-width: 1919.98px) {
    font-size: 50px;
    line-height: 60px;
    letter-spacing: -0.19px;
  }
  @include media-breakpoint-down(sm) {
    line-height: 1.33;
    letter-spacing: -0.11px;
    margin-bottom: 25px;
  }
}

//h3
@mixin font-md {
  font-family: $font-medium;
  font-weight: normal;
  line-height: 1.25;
  margin-bottom: 20px;
  letter-spacing: -0.1px;
  @include fluid-type($min_width, $max_width, 26px, 32px);
  @media(min-width: 1919.98px) {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.13px;
  }
  @include media-breakpoint-down(sm) {
    line-height: 1.307;
    letter-spacing: -0.08px;
  }
}

//h4
@mixin font-sm {
  font-family: $font-medium;
  font-weight: normal;
  line-height: 1.363;
  margin-bottom: 20px;
  letter-spacing: normal;
  @include fluid-type($min_width, $max_width, 19px, 22px);
  @media(min-width: 1919.98px) {
    font-size: 27px;
    line-height: 38px;
    letter-spacing: normal;
  }
  @include media-breakpoint-down(sm) {
    line-height: 1.473;
  }
}

//h5
@mixin font-xs {
  font-family: $font-regular;
  font-weight: normal;
  line-height: 1.454;
  letter-spacing: normal;
  margin-bottom: 20px;
  @include fluid-type($min_width, $max_width, 19px, 22px);
  @media(min-width: 1919.98px) {
    font-size: 27px;
    line-height: 38px;
    letter-spacing: normal;
  }
  @include media-breakpoint-down(sm) {
    line-height: 1.473;
  }
}

//h6
@mixin font-xxs {
  font-family: $font-bold;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  letter-spacing: normal;
  margin-bottom: 22px;
  @media(min-width: 1919.98px) {
    font-size: 17px;
    line-height: 23px;
    letter-spacing: normal;
  }
}

@mixin text-18 {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 30px;
  @media(min-width: 1919.98px) {
    font-size: 22px;
    line-height: 37px;
    letter-spacing: normal;
  }
  @include media-breakpoint-down(sm) {
    font-size: 16px;
    line-height: 26px;
  }
}

@mixin link-18 {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 26px;
  font-family: $font-medium;
  @media(min-width: 1919.98px) {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: normal;
  }
  @include media-breakpoint-down(sm) {
    font-size: 16px;
    line-height: 24px;
  }
}

@mixin text-16 {
  font-family: $font-regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 26px;
  @media(min-width: 1919.98px) {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: normal;
  }
  @include media-breakpoint-down(sm) {
    font-size: 14px;
    line-height: 24px;
  }
}
@mixin link-16 {
  font-family: $font-regular;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: normal;
  font-family: $font-medium;
  line-height: 24px;
  @media(min-width: 1919.98px) {
    font-size: 19px;
    line-height: 26px;
    letter-spacing: normal;
  }
  @include media-breakpoint-down(sm) {
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin text-14 {
  font-family: $font-regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 20px;
  @media(min-width: 1919.98px) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: normal;
  }
}
@mixin link-14 {
  font-family: $font-regular;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 20px;
  font-family: $font-medium;
  @media(min-width: 1919.98px) {
    font-size: 17px;
    line-height: 23px;
    letter-spacing: normal;
  }
}

// Other Mixing
@mixin border-radius($radius) { -webkit-border-radius: $radius; -moz-border-radius: $radius; -o-border-radius: $radius;	border-radius: $radius; }

@mixin opacity($opacity) { -webkit-opacity:$opacity; -moz-opacity:$opacity; -o-opacity:$opacity; -m-opacity:$opacity; opacity:$opacity; }

@mixin transition($seconds) { -webkit-transition: all $seconds ease; -moz-transition: all $seconds ease; -ms-transition: all $seconds ease; -o-transition: all $seconds ease; transition: all $seconds ease; }

@mixin trans-anim-none { -webkit-transition:none; -moz-transition:none; -o-transition:none; transition:none; }

@mixin space-none { margin:0; padding:0; }

@mixin app-none { -webkit-appearance: none; -moz-appearance: none; -o-appearance: none; -ms-appearance: none; appearance: none;}

@mixin point-none { -webkit-pointer-events:none; -moz-pointer-events:none; -o-pointer-events:none; -ms-pointer-events:none; pointer-events:none; }

// Background properties
@mixin bg-cmn { background-position:top center; background-repeat:no-repeat; background-size: cover; }
@mixin bg-parallax { background-attachment:fixed; @include bg-cmn; }
